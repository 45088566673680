import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TM_HAS_PERMISSION_CAN_ACTIVATE_FN } from 'src/app/guards/truckmanager-authorization.guard';
import { DocumentsOverviewComponent } from './components/documents-overview.component';

const ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'overview' },
  {
    path: 'overview',
    component: DocumentsOverviewComponent,
    data: { permissions: ['vehicle-part-documents-overview-consult'] },
    canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class DocumentsRoutingModule {}

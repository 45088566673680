import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { skipDefaultErrorHandling } from '@fluxys/common';
import { FlxValidatedResult, withSpecificContext } from '@fluxys/gsmart/rule-validation';
import { withLoadingSpinner } from '@fluxys/primeng';
import { RegisterCommandResultViewModel } from '@shared/models';
import { CommandResultViewModel } from '@shared/models/command-result.viewmodel';
import { Observable, of } from 'rxjs';

import {
  ConfigureAffiliationCommand,
  ConfigureApprovalCommand,
  ConfigureDocumentCommand,
  ConfigureDriverCommand,
  ConfigureTrainingCommand,
  DeleteAffiliationCommand,
  DeleteDocumentCommand,
  DeleteTrainingCommand,
  RegisterAffiliationCommand,
  RegisterDocumentCommand,
  RegisterDriverCommand,
  RegisterTrainingCommand,
} from '../models/commands';
import { DriverConfigureFormViewModel, DriverRegisterFormViewModel } from '../models/view-models';

@Injectable()
export class DriverService {
  private readonly _baseUrl = '/api/driver';

  constructor(private readonly _http: HttpClient) {}

  /**
   * GET /api/driver/configure-dialog-data/{id}
   * @param id driver identifier
   * @param context spinner context
   * @returns view model for consult/configure driver dialog
   */
  getConfigureDriverFormData(id: number, context: string): Observable<DriverConfigureFormViewModel> {
    return this._http.get<DriverConfigureFormViewModel>(`${this._baseUrl}/configure-dialog-data/${id}`, {
      context: withLoadingSpinner(context),
    });
  }

  /**
   * GET /api/driver/register-dialog-data
   * @param context spinner context
   * @returns view model for register driver dialog
   */
  getRegisterDriverFormData(context: string): Observable<DriverRegisterFormViewModel> {
    return this._http.get<DriverRegisterFormViewModel>(`${this._baseUrl}/register-dialog-data`, {
      context: withLoadingSpinner(context),
    });
  }

  /**
   * POST /api/driver/register
   * @param command the register driver command
   * @param context spinner context
   * @returns validated result
   */
  register(command: RegisterDriverCommand, context: string): Observable<RegisterCommandResultViewModel> {
    return this._http.post<RegisterCommandResultViewModel>(`${this._baseUrl}/register`, command, {
      context: withLoadingSpinner(context, skipDefaultErrorHandling(true, withSpecificContext(context))),
    });
  }

  /**
   * POST /api/driver/configure
   * @param command the configure driver command
   * @param context spinner context
   * @returns validated result
   */
  configure(command: ConfigureDriverCommand, context: string): Observable<FlxValidatedResult> {
    return this._http.post(`${this._baseUrl}/configure`, command, {
      context: withLoadingSpinner(context),
    });
  }

  /**
   * POST /api/driver/affiliation/register
   * @param command the register affiliation command
   * @param context spinner context
   * @returns validated result
   */
  registerAffiliation(command: RegisterAffiliationCommand, context: string): Observable<RegisterCommandResultViewModel> {
    return this._http.post<RegisterCommandResultViewModel>(`${this._baseUrl}/affiliation/register`, command, {
      context: withLoadingSpinner(context),
    });
  }

  /**
   * POST /api/driver/affiliation/configure
   * @param command the configure affiliation command
   * @param context spinner context
   * @returns validated result
   */
  configureAffiliation(command: ConfigureAffiliationCommand, context: string): Observable<FlxValidatedResult> {
    return this._http.post(`${this._baseUrl}/affiliation/configure`, command, {
      context: withLoadingSpinner(context),
    });
  }

  /**
   * POST /api/driver/affiliation/delete
   * @param command the delete affiliation command
   * @param context spinner context
   * @returns validated result
   */
  deleteAffiliation(command: DeleteAffiliationCommand, context: string): Observable<FlxValidatedResult> {
    return this._http.post(`${this._baseUrl}/affiliation/delete`, command, {
      context: withLoadingSpinner(context),
    });
  }

  /**
   * POST /api/driver/approval/configure
   * @param command the configure approval command
   * @param context spinner context
   * @returns validated result
   */
  configureApproval(command: ConfigureApprovalCommand, context: string): Observable<FlxValidatedResult> {
    return this._http.post(`${this._baseUrl}/approval/configure`, command, {
      context: withLoadingSpinner(context),
    });
  }

  /**
   * POST /api/driver/training/register
   * @param command the register training command
   * @param context spinner context
   * @returns validated result
   */
  registerTraining(command: RegisterTrainingCommand, context: string): Observable<RegisterCommandResultViewModel> {
    return this._http.post<RegisterCommandResultViewModel>(`${this._baseUrl}/training/register`, command, {
      context: withLoadingSpinner(context),
    });
  }

  /**
   * POST /api/driver/training/configure
   * @param command the configure training command
   * @param context spinner context
   * @returns validated result
   */
  configureTraining(command: ConfigureTrainingCommand, context: string): Observable<FlxValidatedResult> {
    return this._http.post(`${this._baseUrl}/training/configure`, command, {
      context: withLoadingSpinner(context),
    });
  }

  /**
   * POST /api/driver/training/delete
   * @param command the delete training command
   * @param context spinner context
   * @returns validated result
   */
  deleteTraining(command: DeleteTrainingCommand, context: string): Observable<FlxValidatedResult> {
    return this._http.post(`${this._baseUrl}/training/delete`, command, {
      context: withLoadingSpinner(context),
    });
  }

  /**
   * POST /api/driver/document/register
   * @param command the register document command
   * @param context spinner context
   * @returns validated result
   */
  registerDocument(command: RegisterDocumentCommand, context: string): Observable<FlxValidatedResult> {
    return this._http.post(`${this._baseUrl}/document/register`, command, {
      context: withLoadingSpinner(context),
    });
  }

  /**
   * POST /api/driver/document/configure
   * @param command the configure document command
   * @param context spinner context
   * @returns validated result
   */
  configureDocument(command: ConfigureDocumentCommand, context: string): Observable<FlxValidatedResult> {
    return this._http.post(`${this._baseUrl}/document/configure`, command, {
      context: withLoadingSpinner(context),
    });
  }

  /**
   * POST /api/driver/document/delete
   * @param command the delete document command
   * @param context spinner context
   * @returns validated result
   */
  deleteDocument(command: DeleteDocumentCommand, context: string): Observable<FlxValidatedResult> {
    return this._http.post(`${this._baseUrl}/document/delete`, command, {
      context: withLoadingSpinner(context),
    });
  }
}

import { toDate } from '@fluxys/common';

export class VehicleDocument {
  id: number = 0;
  vehiclePartId?: number;
  documentType: 'ADRDocument' | 'DocumentOfConformity' = 'ADRDocument';
  validFrom: Date | null;
  validTo: Date | null;
  validated?: boolean;
  documentName?: string;
  document?: File;
  owner?: string;
  vehiclePartIdentification?: string;

  constructor(doc: Partial<VehicleDocument>) {
    Object.assign(this, doc);
    this.validFrom = doc.validFrom ? toDate(doc.validFrom) : null;
    this.validTo = doc.validTo ? toDate(doc.validTo) : null;
  }
}

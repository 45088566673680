<div class="section-content ml-2" [formGroup]="form">
  <div class="d-flex">
    <div class="p-2">
      <label for="countryId" psm5FormLabel transloco="app.modules.vehicles.components.vehicle-dialog.nationality-code"></label>
      <select id="countryId" formControlName="countryId">
        <option value=""></option>
        <option *ngFor="let country of countries" [ngValue]="country.value">{{ country.label }}</option>
      </select>
    </div>
    <div class="p-2">
      <label for="vehicleIdentification" psm5FormLabel transloco="app.modules.vehicles.components.vehicle-dialog.license-plate"></label>
      <input type="text" id="vehicleIdentification" formControlName="vehicleIdentification" />
      <div
        *ngIf="form.hasError('required', 'vehicleIdentification') && form.get('vehicleIdentification')?.touched"
        class="u-validation-message mt-1"
      >
        <span transloco="app.modules.vehicles.components.vehicle-dialog.validation.required-license-plate"></span>
      </div>
      <div
        *ngIf="form.hasError('maxLicensePlateCharactersLength', 'vehicleIdentification') && form.get('vehicleIdentification')?.touched"
        class="u-validation-message mt-1"
      >
        <span transloco="app.modules.vehicles.components.vehicle-dialog.validation.license-plate-too-long"></span>
      </div>
    </div>
  </div>
</div>

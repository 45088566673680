import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, tap } from 'rxjs';

import { handleRequestError } from './error.actions';

@Injectable()
export class ErrorEffects {
  handleRequestError$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(handleRequestError),
        tap(({ error, errorTranslationKey }) => this.errorHandlerService.handleHttpErrorResponse(error, errorTranslationKey))
      ),
    { dispatch: false }
  );

  constructor(private readonly actions$: Actions, private readonly errorHandlerService: ErrorHandlerService) {}
}

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { hasErrors } from '@fluxys/gsmart/rule-validation';
import { FlxMessageService } from '@fluxys/primeng';
import { TranslatableMessage } from '@shared/models';
import { showToastOnError } from '@shared/rxjs/operators';
import { SelectItem } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ObservableSignal } from 'psm5-web';
import { map, Observable, share, switchMap } from 'rxjs';

import { RegisterDriverCommand } from '../../models/commands';
import { DriverRegisterFormViewModel } from '../../models/view-models';
import { DriverService } from '../../services';
import { DriverDetailsFormComponent } from '../driver-details-form/driver-details-form.component';

@Component({
  selector: 'trkmgr-create-driver-dialog',
  templateUrl: './create-driver-dialog.component.html',
})
export class CreateDriverDialogComponent {
  @ViewChild(DriverDetailsFormComponent, { static: false }) detailsForm: DriverDetailsFormComponent | undefined;

  readonly dialogRef: DynamicDialogRef;

  readonly context: string = 'create-driver-dialog';
  readonly form: UntypedFormGroup;
  readonly acceptedLanguages$: Observable<SelectItem[]>;

  private readonly reloadRequested: ObservableSignal = new ObservableSignal();

  private readonly driverService: DriverService = inject(DriverService);
  private readonly messageService: FlxMessageService = inject(FlxMessageService);

  constructor(fb: UntypedFormBuilder, readonly dialogReference: DynamicDialogRef) {
    this.dialogRef = dialogReference;
    this.form = fb.group({
      firstName: fb.control(null, Validators.required),
      lastName: fb.control(null, Validators.required),
      fullName: fb.control({ value: null, disabled: true }),
      spokenLanguages: fb.control([]),
      phoneNumber: fb.control(null),
    });

    this.acceptedLanguages$ = this.reloadRequested.observable.pipe(
      switchMap(() =>
        this.driverService.getRegisterDriverFormData(this.context).pipe(
          showToastOnError<DriverRegisterFormViewModel | null>(
            this.messageService,
            'app.modules.drivers.http-errors.load-spoken-languages-error',
            () => null
          ),
          map((vm) => vm?.acceptedLanguages ?? []),
          map((codes) => codes.map((value) => ({ label: value, value }))),
          share()
        )
      )
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onCreateButtonClicked(): void {
    // get formValue from child form component
    const formRawValue = this.detailsForm?.getFormValue();
    const { fullName, ...command } = formRawValue;

    this.driverService.register(command as RegisterDriverCommand, this.context).subscribe({
      next: (result) => {
        if (!hasErrors(result)) {
          this.dialogRef.close(result.registeredEntityId);
        }
      },
      error: () => this.messageService.showErrorToast(new TranslatableMessage('app.modules.drivers.http-errors.register-driver-error')),
    });
  }
}

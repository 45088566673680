import { Component, Input } from '@angular/core';
import { AppointmentAssignmentDetailsViewModel } from 'src/app/assignments/models/assignment-request-details/appointment-assignment-details.viewmodel';

@Component({
  selector: 'trkmgr-assignment-request-details-appointment',
  templateUrl: './assignment-request-details-appointment.component.html',
})
export class AssignmentRequestDetailsAppointmentComponent {
  @Input() vm: AppointmentAssignmentDetailsViewModel | undefined;
}

<p-table
  [value]="assignments ?? []"
  [columns]="columns"
  sortField="name"
  [reorderableColumns]="true"
  tableStyleClass="flx-table"
  [rowHover]="true"
  *transloco="let t; read: 'app.modules.assignments.components.shared'"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field">
        <div class="d-flex align-items-center justify-content-between">
          {{ t(col.translation) }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
          <p-columnFilter *ngIf="col.filter" type="text" [field]="col.field" display="menu" class="ml-auto"></p-columnFilter>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-assignment let-columns="columns">
    <tr class="u-cursor-pointer" (click)="onViewDetailsLinkClicked(assignment.id)">
      <td class="max-width-150">{{ assignment.reference }}</td>
      <td>
        <p-tag
          [value]="assignment.type"
          [styleClass]="'flx-tag-secondary ' + (assignment.type === 'Slot' ? 'flx-tag-aux-teal' : 'flx-tag-aux-purple')"
        ></p-tag>
      </td>
      <td>{{ t('enums.assignment-type.' + assignment.assignmentType) }}</td>
      <td>{{ assignment.assignorName }}</td>
      <td>{{ assignment.assigneeName }}</td>
      <td class="min-width-200">
        {{ (assignment.operationalPeriodStartDate | toDate | date: 'dd.MM.yyyy') + " - " + (assignment.operationalPeriodEndDate | toDate |
        date: 'dd.MM.yyyy')}}
      </td>
      <td class="min-width-300">{{ (assignment.appointmentDatetime | toDate | date: 'dd.MM.yyyy - HH:mm') ?? '-' }}</td>
      <td>{{ assignment.quantity }}</td>
      <td>{{ assignment.status }}</td>
      <td>{{ (assignment.signatureDate | toDate | date: 'dd.MM.yyyy') ?? '' }}</td>
    </tr>
  </ng-template>
</p-table>

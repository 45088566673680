/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-lines */ // TODO too much effects in this class, should remove some of them or split in smaller classes
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapToPayload } from '@core/models/http-error';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { notNullOrUndefined } from '@shared/rxjs/operators';
import { DialogService } from 'primeng/dynamicdialog';
import { BlobService } from 'psm5-web';
import { catchError, first, map, mapTo, merge, mergeAll, mergeMap, of, switchMap, tap } from 'rxjs';

import { VehiclePartDialogComponent } from '../components/vehicle-view-dialog/vehicle-part-dialog.component';
import { BusinessPartyService, CountryService, VehiclePartService } from '../services';
import { VehiclesServicesModule } from '../vehicles-services.module';

import { ACTIONS } from './vehicles.actions';

@Injectable({ providedIn: VehiclesServicesModule })
export class VehiclePartsEffects {
  deleteVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.vehicleParts.delete),
      mergeMap((action) =>
        this.vehiclesService.deleteVehiclePart(action.id, action.validationContext).pipe(
          map((result) => of(ACTIONS.vehicleParts.deleted({ result }), ACTIONS.vehicleParts.overview.load())),
          mergeAll(),
          catchError((err: HttpErrorResponse) => of(ACTIONS.vehicleParts.deleteError(mapToPayload(err))))
        )
      )
    )
  );

  openDialogNewData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.vehicleParts.overview.create),
      switchMap((action) =>
        this.dialogService
          .open(VehiclePartDialogComponent, {
            header: `${this.translateService.translate('generic.new')} ${this.translateService.translate(
              `enums.vehicle-part-type.${action.vehiclePartType}`
            )}`,
          })
          .onClose.pipe(
            first(),
            notNullOrUndefined(),
            map(() => ACTIONS.vehicleParts.overview.load())
          )
      )
    )
  );

  openDialogExistingData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.vehicleParts.overview.edit, ACTIONS.vehicleParts.overview.view),
      mergeMap((action) =>
        merge(
          // dispatch action of loading the vehicle
          of(ACTIONS.vehicleParts.load({ id: action.id })),
          of(ACTIONS.vehicleParts.loadVehicleDocuments({ id: action.id })),
          // opens the vehicle dialog component + handles the close event
          this.dialogService
            .open(VehiclePartDialogComponent, {
              header: `${this.translateService.translate(`enums.vehicle-part-type.${action.vehiclePartType}`)} - ${action.identification}`,
            })
            .onClose.pipe(
              // This is for the case where the user clicks on 'edit' button of the dialog: the dialog then closes and reopen in edit mode
              // don't have time to change this flow - god forgive me
              first(),
              notNullOrUndefined(),
              map((_) =>
                ACTIONS.vehicleParts.overview.edit({
                  id: action.id,
                  vehiclePartType: action.vehiclePartType,
                  identification: action.identification,
                })
              )
            )
        )
      )
    )
  );

  // naming it with dispatch at the begin makes it clear this actions could and should be dispatched from outside
  dispatchLoadBusinessParties$ = createEffect(() =>
    this.actions$.pipe(ofType(ACTIONS.vehicleParts.overview.create), mapTo(ACTIONS.businessParties.load()))
  );

  dispatchLoadCountries$ = createEffect(() =>
    this.actions$.pipe(ofType(ACTIONS.vehicleParts.overview.create), mapTo(ACTIONS.countries.load()))
  );

  deleteVehicleError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACTIONS.vehicleParts.deleteError),
        tap((error) => this.errorHandlerService.handleHttpErrorResponse(error, 'app.modules.vehicles.http-errors.delete-vehicle-error'))
      ),
    { dispatch: false }
  );

  loadOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.vehicleParts.overview.load),
      switchMap(() =>
        this.vehiclesService.getVehiclePartList().pipe(
          map((vehicles) => ACTIONS.vehicleParts.overview.loaded({ vehicles })),
          catchError((err: HttpErrorResponse) => of(ACTIONS.vehicleParts.overview.loadError(mapToPayload(err))))
        )
      )
    )
  );

  loadOverviewError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACTIONS.vehicleParts.overview.loadError),
        tap((error) =>
          this.errorHandlerService.handleHttpErrorResponse(error, 'app.modules.vehicles.http-errors.load-vehicles-overview-data-error')
        )
      ),
    { dispatch: false }
  );

  loadTransporters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.businessParties.load),
      switchMap((action) =>
        this.businessPartyService.getBusinessParties().pipe(
          map((transporters) => ACTIONS.businessParties.loaded({ businessParties: transporters })),
          catchError((err: HttpErrorResponse) => of(ACTIONS.businessParties.loadError(mapToPayload(err))))
        )
      )
    )
  );

  loadTransportersError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACTIONS.countries.loadError),
        tap((error) =>
          this.errorHandlerService.handleHttpErrorResponse(error, 'app.modules.vehicles.http-errors.load-transporters-data-error')
        )
      ),
    { dispatch: false }
  );

  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.countries.load),
      switchMap((action) =>
        this.countryService.getCountryItems().pipe(
          map((countries) => ACTIONS.countries.loaded({ countries })),
          catchError((err: HttpErrorResponse) => of(ACTIONS.countries.loadError(mapToPayload(err))))
        )
      )
    )
  );

  loadCountriesError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACTIONS.countries.loadError),
        tap((error) =>
          this.errorHandlerService.handleHttpErrorResponse(error, 'app.modules.vehicles.http-errors.load-countries-data-error')
        )
      ),
    { dispatch: false }
  );

  loadVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.vehicleParts.load),
      switchMap((action) =>
        merge(
          of(ACTIONS.businessParties.load(), ACTIONS.countries.load()),
          this.vehiclesService.getVehiclePart(action.id).pipe(
            map((vehicle) => ACTIONS.vehicleParts.loaded({ vehicle })),
            catchError((err: HttpErrorResponse) => of(ACTIONS.vehicleParts.loadError(mapToPayload(err))))
          )
        )
      )
    )
  );

  loadVehicleError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACTIONS.vehicleParts.overview.loadError),
        tap((error) =>
          this.errorHandlerService.handleHttpErrorResponse(error, 'app.modules.vehicles.http-errors.load-vehicles-overview-data-error')
        )
      ),
    { dispatch: false }
  );

  loadVehicleDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.vehicleParts.loadVehicleDocuments),
      switchMap((action) =>
        this.vehiclesService.getVehicleDocuments(action.id).pipe(
          map((vehicleDocuments) => ACTIONS.vehicleParts.loadedVehicleDocuments({ vehicleDocuments })),
          catchError((err: HttpErrorResponse) => of(ACTIONS.vehicleParts.loadVehicleDocumentsError(mapToPayload(err))))
        )
      )
    )
  );

  loadVehicleDocumentsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACTIONS.vehicleParts.loadVehicleDocumentsError),
        tap((error) =>
          this.errorHandlerService.handleHttpErrorResponse(error, 'app.modules.vehicles.http-errors.load-vehicle-documents-error')
        )
      ),
    { dispatch: false }
  );

  exportVehicleParts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.vehicleParts.overview.export),
      switchMap(() =>
        this.vehiclesService.exportVehicleParts().pipe(
          tap((response) => this.blobService.open(response)),
          map(() => ACTIONS.vehicleParts.overview.exportSuccess()),
          catchError((error: HttpErrorResponse) => of(ACTIONS.vehicleParts.overview.exportError(mapToPayload(error))))
        )
      )
    )
  );

  handleExportVehiclePartsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACTIONS.vehicleParts.overview.exportError),
        tap((error) =>
          this.errorHandlerService.handleHttpErrorResponse(error, 'app.modules.vehicles.http-errors.export-vehicle-parts-error')
        )
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly businessPartyService: BusinessPartyService,
    private readonly countryService: CountryService,
    private readonly vehiclesService: VehiclePartService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly dialogService: DialogService,
    private readonly blobService: BlobService,
    private readonly translateService: TranslocoService
  ) {}
}

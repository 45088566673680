<div class="d-flex" [formGroup]="form">
  <div class="section-title align-self-start p-2" [transloco]="dialogLink + 'vehicle-information-title'"></div>

  <div class="section-content ml-2">
    <div class="p-2">
      <label for="owner" psm5FormLabel [transloco]="dialogLink + 'owner'"></label>
      <p-dropdown
        id="owner"
        formControlName="owner"
        [options]="transporters ?? []"
        optionLabel="label"
        optionValue="value"
        scrollHeight="300px"
        [filter]="true"
        [showClear]="true"
        styleClass="w-100"
        placeholder="{{(dialogLink + 'owner-placeholder') | transloco}}"
      ></p-dropdown>
    </div>

    <div class="p-2">
      <label for="dateAdded" psm5FormLabel [transloco]="dialogLink + 'date-added'"></label>
      <flx-date-picker
        formControlName="dateAdded"
        [showEot]="true"
        dataType="date"
        [fluidWidth]="true"
        inputStyleClass="w-165"
      ></flx-date-picker>
    </div>

    <div class="p-2">
      <label for="rfidTag" psm5FormLabel [transloco]="dialogLink + 'rfid-tag'"></label>
      <input type="text" id="rfidTag" formControlName="rfidTag" />
      <flx-validation-messages for="rfidTag"></flx-validation-messages>
    </div>

    <div class="d-flex">
      <div class="p-2">
        <label for="statusId" psm5FormLabel [transloco]="dialogLink + 'vehicle-status'"></label>
        <select id="statusId" formControlName="statusId">
          <option value=""></option>
          <option *ngFor="let status of statuses" [ngValue]="status.value">{{ status.label }}</option>
        </select>
        <div *ngIf="form.hasError('required', 'statusId') && form.get('statusId')?.touched" class="u-validation-message mt-1">
          <span [transloco]="dialogLink + 'validation.required-status'"></span>
        </div>
      </div>

      <div class="p-2" *ngIf="displayExpiryDate">
        <label for="dateExpired" psm5FormLabel [transloco]="dialogLink + 'date-validated'"></label>
        <flx-date-picker
          formControlName="dateExpired"
          [showEot]="true"
          [minDate]="currentDate"
          dataType="date"
          [fluidWidth]="true"
          inputStyleClass="w-165"
        ></flx-date-picker>
      </div>
    </div>

    <div class="p-2">
      <label for="weight" psm5FormLabel [transloco]="dialogLink + 'weight'"></label>
      <input type="text" id="weight" formControlName="weight" />
      <span class="ml-2 u-grey-label" transloco="generic.units.kg"></span>
      <div *ngIf="form.hasError('required', 'weight') && form.get('weight')?.touched" class="u-validation-message mt-1">
        <span [transloco]="dialogLink + 'validation.required-weight'"></span>
      </div>
      <div *ngIf="form.hasError('invalidQuantity', 'weight')" class="u-validation-message mt-1">
        <span [transloco]="dialogLink + 'validation.invalid-quantity'"></span>
      </div>
      <div *ngIf="form.hasError('quantityTooSmall', 'weight')" class="u-validation-message mt-1">
        <span
          [transloco]="dialogLink + 'validation.weight-too-small'"
          [translocoParams]="{ minWeight: vehiclePartParams?.minWeight }"
        ></span>
      </div>
      <div *ngIf="form.hasError('quantityTooLarge', 'weight')" class="u-validation-message mt-1">
        <span
          [transloco]="dialogLink + 'validation.weight-too-large'"
          [translocoParams]="{ maxWeight: vehiclePartParams?.maxWeight }"
        ></span>
      </div>
    </div>
    <div class="p-2">
      <p-checkbox
        name="isDefect"
        formControlName="isDefect"
        [binary]="true"
        [label]="'app.modules.vehicles.components.vehicle-dialog.isDefect' | transloco"
      ></p-checkbox>
    </div>
    <div class="p-2">
      <label for="comment" psm5FormLabel [transloco]="dialogLink + 'comment'"></label>
      <textarea id="comment" formControlName="comment"></textarea>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutomationDispatcher } from '@core/automation';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { tap } from 'rxjs';

import { navigate } from './router.actions';

@Injectable()
export class RouterEffects {
  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigate),
        tap(({ commands, extras }) => {
          void this.router.navigate(commands, extras);
        })
      ),
    { dispatch: false }
  );

  scanAutomation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        tap(() => this.automationDispatcher.scan(this.activatedRoute))
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly automationDispatcher: AutomationDispatcher,
    private readonly activatedRoute: ActivatedRoute
  ) {}
}

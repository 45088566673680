import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OverviewTableFilter } from '@shared/components';
import { VehiclePartType } from '@shared/models';
import { enumKeysToStrings, toUniqueSelectItems } from '@shared/utils';
import { MenuItem, SelectItem } from 'primeng/api';
import { BehaviorSubject, combineLatest, map, Observable, of, Subject } from 'rxjs';

import { VehiclePartListViewModel, VehiclePartOverviewViewModel, VehiclePartStatusViewModel } from '../../models';

/** This component could use ComponentStore now that it has some inner observables to reflect the local state */
@UntilDestroy()
@Component({
  selector: 'trkmgr-vehicles-table',
  templateUrl: './vehicles-table.component.html',
  styleUrls: ['./vehicles-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehiclesTableComponent implements OnInit, OnDestroy {
  @Output() readonly view = new EventEmitter<{ id: number; vehiclePartType: VehiclePartType; identification: string }>();
  @Output() readonly delete = new EventEmitter<number>();
  @Output() readonly edit = new EventEmitter<{ id: number; vehiclePartType: VehiclePartType; identification: string }>();
  @Output() readonly create = new EventEmitter<VehiclePartType>();
  @Output() readonly export = new EventEmitter<void>();

  readonly commands: MenuItem[];
  readonly tableColumns: { key: string; header: string }[];
  readonly menuItems: MenuItem[];
  readonly loading$: Observable<boolean> = of(true);
  readonly inlineFilter$: Observable<OverviewTableFilter | null>;
  readonly dropdownFilters$: Observable<(OverviewTableFilter | null)[]>;

  vehicles: VehiclePartOverviewViewModel[] | null = null;
  canCreate = false;

  private readonly inlineFilterSubject: Subject<OverviewTableFilter | null>;
  private readonly fromOwnerFilterSubject: Subject<OverviewTableFilter | null>;
  private readonly statusFilterSubject: Subject<OverviewTableFilter | null>;
  private readonly vehiclePartTypeFilterOptions: SelectItem[];

  constructor() {
    this.inlineFilterSubject = new BehaviorSubject<OverviewTableFilter | null>(null);
    this.inlineFilter$ = this.inlineFilterSubject.asObservable();

    this.fromOwnerFilterSubject = new BehaviorSubject<OverviewTableFilter | null>(null);
    this.statusFilterSubject = new BehaviorSubject<OverviewTableFilter | null>(null);
    this.dropdownFilters$ = combineLatest([this.fromOwnerFilterSubject, this.statusFilterSubject]).pipe(
      map(([fromOwnerFilter, statusFilter]) => [fromOwnerFilter, statusFilter])
    );

    this.commands = [
      this.createCreateCommand('tractor', VehiclePartType.Tractor, 'fa fa-plus'),
      this.createCreateCommand('road-tanker', VehiclePartType.RoadTanker),
      this.createCreateCommand('lng-container', VehiclePartType.LNGContainer),
      this.createCreateCommand('chassis', VehiclePartType.Chassis),
    ];
    this.vehiclePartTypeFilterOptions = this.createVehiclePartTypeFilterOptions();
    this.tableColumns = [
      { key: 'vehiclePartType', header: 'type' },
      { key: 'owner', header: 'owner' },
      { key: 'rfidTag', header: 'rfid-tag' },
      { key: 'status.statusClass', header: 'status' },
      { key: 'status.description', header: 'description' },
      { key: 'dateExpired', header: 'expiry-date' },
    ];
    this.menuItems = [
      {
        label: 'app.shared.buttons.edit',
        icon: 'fas fa-pencil-alt',
        command: (vehicle: VehiclePartOverviewViewModel) =>
          this.edit.emit({ id: vehicle.vehiclePartId, vehiclePartType: vehicle.vehiclePartType, identification: vehicle.identification }),
      },
      {
        label: 'app.shared.buttons.delete',
        icon: 'fas fa-trash',
        command: (vehicle: VehiclePartOverviewViewModel) => this.delete.emit(vehicle.vehiclePartId),
      },
    ];
  }

  @Input() set statuses(value: VehiclePartStatusViewModel[] | null | undefined) {
    const statusFilterOptions = value ? toUniqueSelectItems(value, ({ description }) => description) : null;
    this.setStatusFilter(statusFilterOptions);
  }
  @Input() set viewModel(value: VehiclePartListViewModel | null | undefined) {
    this.vehicles = value?.vehicles ? [...value.vehicles] : []; // copy array to have a mutable array for p-table;
    this.canCreate = value?.canCreate ?? false;
    this.setFromOwnerFilter();
  }

  ngOnInit(): void {
    this.setInlineFilter();
  }

  ngOnDestroy(): void {
    const subjects = [this.inlineFilterSubject, this.fromOwnerFilterSubject, this.statusFilterSubject];
    subjects.forEach((subject) => subject.complete());
  }

  getVehicleIdentification(vehicle: VehiclePartOverviewViewModel): string {
    const containerCodeTypes = [VehiclePartType.RoadTanker, VehiclePartType.LNGContainer];
    return containerCodeTypes.includes(vehicle.vehiclePartType) ? vehicle.containerCode : vehicle.identification;
  }

  onTableLineClicked(vehicle: VehiclePartOverviewViewModel): void {
    this.view.emit({
      id: vehicle.vehiclePartId,
      vehiclePartType: vehicle.vehiclePartType,
      identification: vehicle.identification,
    });
  }

  private createCreateCommand(label: string, vehiclePartType: VehiclePartType, icon?: string): MenuItem {
    return { label, command: () => this.create.emit(vehiclePartType), icon, visible: true };
  }

  private createVehiclePartTypeFilterOptions(): SelectItem[] {
    const items: SelectItem<string | null>[] = [{ label: 'generic.all', value: null }];
    for (const value of enumKeysToStrings(VehiclePartType) ?? []) {
      items.push({ label: `enums.vehicle-part-type.${value}`, value });
    }
    return items;
  }

  private setInlineFilter(): void {
    this.inlineFilterSubject.next({
      field: 'vehiclePartType',
      label: 'app.modules.vehicles.pages.overview.filters.type',
      options: this.vehiclePartTypeFilterOptions,
    });
  }

  private setFromOwnerFilter(): void {
    this.fromOwnerFilterSubject.next({
      field: 'owner',
      label: 'app.modules.vehicles.pages.overview.filters.owner',
      options: this.vehicles ? toUniqueSelectItems(this.vehicles, ({ owner }) => owner).filter((item) => item.value) : null,
      defaultLabel: 'app.modules.vehicles.pages.overview.filter.choose',
      selectedItemsLabel: 'app.modules.vehicles.pages.overview.filter.multiple-items-selected',
    });
  }

  private setStatusFilter(statusFilterOptions: SelectItem<string>[] | null): void {
    this.statusFilterSubject.next({
      field: 'status.description',
      label: 'app.modules.vehicles.pages.overview.filters.status',
      options: statusFilterOptions,
      defaultLabel: 'app.modules.vehicles.pages.overview.filter.choose',
      selectedItemsLabel: 'app.modules.vehicles.pages.overview.filter.multiple-items-selected',
    });
  }
}

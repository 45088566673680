import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MenuItem, SortMeta } from 'primeng/api';
import { Observable, of } from 'rxjs';

import { AcrViewModel } from '../../models/acr.models';
import { ConfigureAcrEvent } from '../../models/configure-acr.event';

enum SortOrder {
  ASCENDING = 1,
  DESCENDING = -1,
}

@UntilDestroy()
@Component({
  selector: 'trkmgr-acr-table',
  templateUrl: './acr-table-component.html',
  styleUrls: ['./acr-table-component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcrTableComponent {
  @Output() readonly create = new EventEmitter<void>();
  @Output() readonly configure = new EventEmitter<ConfigureAcrEvent>();
  @Input() canCreate = false;
  @Input() canEdit = false;
  @Input() areShippersVisible = false;

  readonly tableColumns: { header: string; key: string }[];
  readonly loading$: Observable<boolean> = of(true);
  readonly sortMeta: SortMeta[];

  searchTextFilter: string | undefined;
  updatedEndDate = false;

  private _rules: AcrViewModel[] | undefined;
  private _mutableRules: AcrViewModel[] | undefined;

  constructor() {
    this.tableColumns = [
      { header: 'confirmationGrantingShipperName', key: 'confirmationGrantingShipperName' },
      { header: 'confirmationReceivingShipperName', key: 'confirmationReceivingShipperName' },
      { header: 'validityPeriodBegin', key: 'validityPeriodBegin' },
      { header: 'validityPeriodEnd', key: 'validityPeriodEnd' },
    ];

    this.sortMeta = [
      { field: 'confirmationReceivingShipperName', order: SortOrder.ASCENDING },
      { field: 'validityPeriodBegin', order: SortOrder.ASCENDING },
    ];
  }

  get commands(): MenuItem[] {
    if (this.canCreate) {
      return [
        {
          label: 'app.modules.autoconfirmation.components.table.create',
          command: () => this.create.emit(),
        },
      ];
    }

    return [];
  }

  get rules(): AcrViewModel[] | undefined {
    return this._mutableRules;
  }
  @Input() set rules(value: AcrViewModel[] | null | undefined) {
    this._rules = value ?? undefined;
    this.refreshRules();
  }

  /**
   * Reinitializes the rules with the cached values and makes mutable objects
   */
  refreshRules(): void {
    this._mutableRules = this._rules?.map((rule) => ({ ...rule }));
  }

  onConfirmEdit(rule: AcrViewModel): void {
    this.updatedEndDate = false;
    this.configure.emit({ id: rule.id, endDate: rule.validityPeriodEnd });
  }

  onCancelEdit(rule: AcrViewModel): void {
    this.updatedEndDate = false;
    const cachedRule = this._rules?.find((r) => r.id === rule.id);
    if (cachedRule?.validityPeriodEnd) {
      rule.validityPeriodEnd = cachedRule.validityPeriodEnd;
    }
  }
}

import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { VehiclePartParametersViewModel } from 'src/app/vehicles/models';
import { UnitCode } from 'unit-codes';

@Component({
  selector: 'trkmgr-road-tanker-section',
  templateUrl: './road-tanker-section.component.html',
  styleUrls: ['../../vehicle-part-dialog.component.scss'],
})
export class RoadTankerSectionComponent {
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() countries: SelectItem[] | null = [];
  @Input() vehiclePartParams: VehiclePartParametersViewModel | null = null;
  @Input() maxLoadingVolumePct: number | null = 0;
  @Input() loadingSideOptions: SelectItem[] = [];
  readonly units = UnitCode;
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HAS_PERMISSION_CAN_LOAD_FN } from '@fluxys/security';
import {
  TM_HAS_PERMISSION_CAN_ACTIVATE_CHILD_FN,
  TM_HAS_PERMISSION_CAN_ACTIVATE_FN,
} from 'src/app/guards/truckmanager-authorization.guard';

import { ASS_PAGE_NAMES } from './assignments-routes';
import { AssignmentDetailsComponent } from './pages/assignment-request-details/assignment-details.component';
import { AssignmentRequestTypeSelectionComponent } from './pages/assignment-request-type-selection/assignment-request-type-selection.component';
import { AssignmentsOverviewComponent } from './pages/assignments-overview/assignments-overview.component';
import { AssignmentsRegisterSlotsComponent } from './pages/assignments-register-slots/assignments-register-slots.component';
import { AssignmentsRegisterTruckloadingAppointmentComponent } from './pages/assignments-register-truckloading-appointment/assignments-register-truckloading-appointment.component';

const ROUTES: Routes = [
  { path: ASS_PAGE_NAMES.initial, pathMatch: 'full', redirectTo: 'overview' },
  {
    path: ASS_PAGE_NAMES.overview,
    pathMatch: 'full',
    component: AssignmentsOverviewComponent,
    data: {
      permissions: ['assignment-consult'],
      noBreadcrumb: true,
    },
    canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN],
  },
  {
    path: ASS_PAGE_NAMES.assignmentCreate,
    data: {
      permissions: ['assignment-create'],
      noBreadCrumb: true,
    },
    canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN],
    canActivateChild: [TM_HAS_PERMISSION_CAN_ACTIVATE_CHILD_FN],
    children: [
      {
        path: ASS_PAGE_NAMES.selection,
        pathMatch: 'full',
        component: AssignmentRequestTypeSelectionComponent,
        data: {
          permissions: ['assignment-create'],
          noBreadCrumb: true,
        },
        canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN],
      },
      {
        path: ASS_PAGE_NAMES.truckloadingSlots,
        pathMatch: 'full',
        component: AssignmentsRegisterSlotsComponent,
        data: {
          permissions: ['assignment-create'],
          noBreadcrumb: true,
        },
        canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN],
      },
      {
        path: ASS_PAGE_NAMES.truckloadingAppointment,
        pathMatch: 'full',
        component: AssignmentsRegisterTruckloadingAppointmentComponent,
        data: {
          permissions: ['assignment-create'],
          noBreadcrumb: true,
        },
        canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN],
      },
    ],
  },
  {
    path: `${ASS_PAGE_NAMES.assignmentDetails}/:id`,
    pathMatch: 'full',
    component: AssignmentDetailsComponent,
    data: {
      permissions: ['assignment-consult'],
      noBreadcrumb: true,
    },
    canLoad: [HAS_PERMISSION_CAN_LOAD_FN],
    canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class AssignmentsRoutingModule {}

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AssignmentsRoutes } from '../../assignments-routes';
import { AssignmentRequestViewModel } from '../../models/assignment-request-overview/assignment-request.viewmodel';

@Component({
  selector: 'trkmgr-assignment-requests-table',
  templateUrl: './assignment-requests-table.component.html',
  styleUrls: ['./assignment-requests-table.component.scss'],
})
export class AssignmentRequestsTableComponent {
  @Input() assignments: AssignmentRequestViewModel[] | null = [];
  columns = [
    { translation: 'headers.reference', field: 'reference', filter: true },
    { translation: 'headers.type', field: 'type', filter: true },
    { translation: 'headers.assignment-type', field: 'assignmentType', filter: true },
    { translation: 'headers.assignor', field: 'assignorName', filter: true },
    { translation: 'headers.assignee', field: 'assigneeName', filter: true },
    { translation: 'headers.operational-period', field: 'operationalPeriod', filter: false },
    { translation: 'headers.date-time', field: 'appointmentDatetime', filter: true },
    { translation: 'headers.quantity', field: 'quantity', filter: true },
    { translation: 'headers.creation-date', field: 'creationDate', filter: true },
  ];

  constructor(private readonly router: Router) {}

  onViewDetailsLinkClicked(assignmentRequestId: string): void {
    void this.router.navigate([`${AssignmentsRoutes.assignmentDetailsRoute}/${assignmentRequestId}`]);
  }
}

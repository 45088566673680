import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';

import { VehiclePartFormData } from '../models';

import { VehiclesModuleState } from './vehicles.state';

const SELECT_FEATURE_STATE = createFeatureSelector<VehiclesModuleState>('vehicles');
const SELECT_VEHICLE_PARTS = createSelector(SELECT_FEATURE_STATE, (state: VehiclesModuleState) => state.vehicleParts);
const SELECT_VEHICLE_PARTS_FORM_DATA_RAW = createSelector(SELECT_VEHICLE_PARTS, (state) => state.formData);
const SELECT_VEHICLE_PARTS_FORM_DATA = createSelector(SELECT_VEHICLE_PARTS_FORM_DATA_RAW, (formData) => {
  if (formData) {
    const { vehicleInformation, ...formDataCopy } = formData;
    const { dateAdded, dateExpired, ...vehicleInformationCopy } = vehicleInformation;
    const result: VehiclePartFormData<Date> = {
      ...formDataCopy,
      vehicleInformation: vehicleInformationCopy,
    };
    if (dateAdded) result.vehicleInformation.dateAdded = DateTime.fromFormat(dateAdded, 'yyyyMMdd').toJSDate();
    if (dateExpired) result.vehicleInformation.dateExpired = DateTime.fromFormat(dateExpired, 'yyyyMMdd').toJSDate();
    return result;
  }

  return undefined;
});
const SELECT_VEHICLE_PARTS_FORM_MODE = createSelector(SELECT_VEHICLE_PARTS, (state) => state.formMode);
const SELECT_VEHICLE_PARTS_FORM_STATE = createSelector(SELECT_VEHICLE_PARTS, (state) => state.formState);
const SELECT_VEHICLE_PARTS_FORM_STATE_DISPLAY_EXPIRATION_DATE = createSelector(
  SELECT_VEHICLE_PARTS_FORM_STATE,
  (state) => state?.displayExpirationDate
);

export const SELECTORS = {
  root: SELECT_FEATURE_STATE,
  initialized: createSelector(SELECT_FEATURE_STATE, (state) => state.initialized),
  transporters: createSelector(SELECT_FEATURE_STATE, (state) => state.transporters),
  countries: createSelector(SELECT_FEATURE_STATE, (state) => state.countries),
  vehicleParts: {
    overview: createSelector(SELECT_VEHICLE_PARTS, (state) => state.overview),
    selected: createSelector(SELECT_VEHICLE_PARTS, (state) => state.selected),
    formCanDelete: createSelector(SELECT_VEHICLE_PARTS, (state) => state.selected?.canDelete === true),
    formCanEdit: createSelector(SELECT_VEHICLE_PARTS, (state) => state.selected?.canEdit === true),
    formCanEditDocumentsOnly: createSelector(SELECT_VEHICLE_PARTS, (state) => state.selected?.canEditDocumentsOnly === true),
    formDataRaw: SELECT_VEHICLE_PARTS_FORM_DATA_RAW,
    formData: SELECT_VEHICLE_PARTS_FORM_DATA,
    formVehiclePartType: createSelector(SELECT_VEHICLE_PARTS, (state) => state.formVehiclePartType),
    formMode: SELECT_VEHICLE_PARTS_FORM_MODE,
    formId: createSelector(SELECT_VEHICLE_PARTS_FORM_DATA_RAW, (formData) => formData?.vehiclePartId),
    formReady: createSelector(
      SELECT_FEATURE_STATE,
      SELECT_VEHICLE_PARTS_FORM_DATA,
      SELECT_VEHICLE_PARTS_FORM_MODE,
      (feature, formData, formMode) => (formMode === 'create' || !!formData) && !!feature.transporters && !!feature.countries
    ),
    statuses: createSelector(SELECT_VEHICLE_PARTS, (state) => state.vehiclePartsStatuses),
    formState: SELECT_VEHICLE_PARTS_FORM_STATE,
    displayExpirationDate: SELECT_VEHICLE_PARTS_FORM_STATE_DISPLAY_EXPIRATION_DATE,
    vehicleDocuments: createSelector(SELECT_VEHICLE_PARTS, (state) => state.vehicleDocuments),
  },
};

<flx-loading-spinner [context]="['vehicle-parts-table', 'vehicle-part-overview', 'vehicle-part-dialog']"></flx-loading-spinner>

<flx-rule-validation context="vehicle-part-overview" displayMode="dialog"></flx-rule-validation>

<trkmgr-vehicles-table
  [viewModel]="viewModel$ | async"
  [statuses]="statuses$ | async"
  (view)="viewVehiclePart($event)"
  (delete)="deleteVehiclePart($event)"
  (edit)="editVehiclePart($event)"
  (create)="createVehiclePart($event)"
  (export)="exportVehicleParts()"
></trkmgr-vehicles-table>

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FlxLoggerService } from '@fluxys/core';
import { SelectItem } from 'primeng/api';
import { SimpleChanges } from 'psm5-web/types';

import { ConfigureDriverCommand, RegisterDriverCommand } from '../../models/commands';
import { DriverViewModel } from '../../models/view-models';

interface DriverDetailsForm {
  firstName: string;
  lastName: string;
  fullName?: string;
  spokenLanguages: string[];
  phoneNumber: string;
}

@Component({
  selector: 'trkmgr-driver-details-form',
  templateUrl: './driver-details-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./driver-details-form.component.scss'],
})
export class DriverDetailsFormComponent implements OnInit, OnChanges {
  @Input() acceptedLanguages: SelectItem[] | null = null;
  @Input() driver: DriverViewModel | null = null;
  @Input() readonly = false;
  @Input() canEdit: boolean | null = false;
  @Input() mode: 'configure' | 'register' = 'register';
  @Input() context = '';
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});

  @Output() readonly registerRequested = new EventEmitter<RegisterDriverCommand>();
  @Output() readonly configureRequested = new EventEmitter<ConfigureDriverCommand>();

  formValueSnapshot: DriverDetailsForm | null = null;
  readonly translateLanguagesPath = 'app.modules.drivers.components.driver-details-form.languages';

  private readonly _loggerService: FlxLoggerService = inject(FlxLoggerService);

  ngOnInit(): void {
    if (this.readonly) {
      this.form.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges<DriverDetailsFormComponent>): void {
    if (changes.driver !== undefined) {
      if (changes.driver.currentValue !== null) {
        const { firstName, lastName, fullName, spokenLanguages, phoneNumber } = changes.driver.currentValue;
        const formValue: Required<DriverDetailsForm> = {
          firstName,
          lastName,
          fullName,
          spokenLanguages,
          phoneNumber,
        };
        this.formValueSnapshot = formValue;
        this.form.patchValue(formValue);
      }
    }
  }

  toggleReadonly(): void {
    this.readonly = !this.readonly;
    if (this.readonly) {
      if (this.formValueSnapshot !== null) {
        this.form.patchValue(this.formValueSnapshot);
      }
      this.form.disable();
    } else {
      this.form.enable();
      this.form.get('fullName')?.disable();
    }
  }

  save(): void {
    const formValue: unknown = this.form.getRawValue();
    if (!this.isValidFormStructure(formValue)) {
      this._loggerService.error('The form has not the expected structure', formValue);
      return;
    }

    switch (this.mode) {
      case 'register': {
        const { fullName, ...command } = formValue;
        this.registerRequested.emit(command);
        break;
      }
      case 'configure': {
        if (this.driver) {
          const { driverId, tcn } = this.driver;
          const { fullName, ...command } = { ...formValue, driverId, tcn };
          this.configureRequested.emit(command);
          this.formValueSnapshot = formValue;
          this.toggleReadonly();
        }
        break;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFormValue(): any {
    const formValue: unknown = this.form.getRawValue();
    if (!this.isValidFormStructure(formValue)) {
      this._loggerService.error('The form has not the expected structure', formValue);
      return;
    }

    return formValue;
  }

  // just there to ensure the form value typed 'any' has the right fields
  // TODO: should be removed by strongly typing the form
  private isValidFormStructure(formValue: unknown): formValue is DriverDetailsForm {
    const { firstName, lastName, fullName, spokenLanguages, phoneNumber } = formValue as {
      [key in keyof DriverDetailsForm]?: DriverDetailsForm[key];
    };
    return firstName !== undefined && lastName !== undefined && fullName !== undefined && spokenLanguages !== undefined;
  }
}

import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer, RuntimeChecks } from '@ngrx/store';

import * as routerActions from './router/router.actions';
import { AppState } from './state';
import * as userActions from './user/user.actions';
import { reducer as userReducer } from './user/user.reducer';

export { CustomRouterStateSerializer } from './router/custom-router-state-serializer';
export { RouterEffects } from './router/router.effects';
export { selectors } from './selectors';
export { AppState } from './state';
export { UserEffects } from './user/user.effects';

const ACTIONS = {
  router: routerActions,
  user: userActions,
};

const REDUCERS: ActionReducerMap<AppState> = {
  router: routerReducer,
  user: userReducer,
};

const META_REDUCERS: MetaReducer<AppState>[] = [];
const RUNTIME_CHECKS: RuntimeChecks = {
  strictActionImmutability: true,
  strictActionSerializability: true,
  strictStateImmutability: true,
  strictStateSerializability: true,
  strictActionWithinNgZone: true,
};

export { ACTIONS as actions, REDUCERS as reducers, META_REDUCERS as metaReducers, RUNTIME_CHECKS as runtimeChecks };

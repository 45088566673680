import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapToPayload } from '@core/models/http-error';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, mergeMap, Observable, of } from 'rxjs';

import { VehiclePartService } from '../services/vehicle-part.service';
import { VehiclesServicesModule } from '../vehicles-services.module';

import { ACTIONS } from './vehicles.actions';

@Injectable({ providedIn: VehiclesServicesModule })
export class VehicleEffects {
  initializeData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.initializeModule),
      mergeMap(() =>
        this.vehiclePartService.getStatuses().pipe(
          map(
            (statusCodes) => ACTIONS.initializedModule({ vehiclePartsStatuses: statusCodes }),
            catchError((error: HttpErrorResponse) => of(ACTIONS.initializeError(mapToPayload(error))))
          )
        )
      )
    )
  );

  constructor(private readonly actions$: Actions, private readonly vehiclePartService: VehiclePartService) {}
}

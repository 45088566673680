/**
 * Gets all the keys of an enum.
 * Enums are represented by objects at runtime.
 *
 * @param enumValue enum's object
 * @returns array of string keys, or null if `enumValue` is null or undefined
 */
export function enumKeysToStrings(enumValue?: object | null): string[] | null {
  if (!enumValue) return null;
  return Object.keys(enumValue).filter((key) => isNaN(+key));
}

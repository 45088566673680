export const ASS_PAGE_NAMES = {
  base: 'assignments',
  initial: '',
  overview: 'overview',
  assignmentDetails: 'assignment-detail',
  assignmentCreate: 'create',
  selection: 'assignment-type-selection',
  truckloadingSlots: 'truckloading-slots',
  truckloadingAppointment: 'truckloading-appointment',
};

/* eslint-disable @typescript-eslint/no-unsafe-return */
export class AssignmentsRoutes {
  static readonly baseRoute = ASS_PAGE_NAMES.base;

  static readonly initialRoute = `${ASS_PAGE_NAMES.base}/${ASS_PAGE_NAMES.initial}`;

  static readonly overviewRoute = `${ASS_PAGE_NAMES.base}/${ASS_PAGE_NAMES.overview}`;

  static readonly assignmentDetailsRoute = `${ASS_PAGE_NAMES.base}/${ASS_PAGE_NAMES.assignmentDetails}/`;

  static readonly selectionRoute = `${ASS_PAGE_NAMES.base}/${ASS_PAGE_NAMES.assignmentCreate}/${ASS_PAGE_NAMES.selection}`;

  static readonly createTruckloadingAppointmentRoute = `${ASS_PAGE_NAMES.base}/${ASS_PAGE_NAMES.assignmentCreate}/${ASS_PAGE_NAMES.truckloadingAppointment}`;

  static readonly createTruckloadingSlotsRoute = `${ASS_PAGE_NAMES.base}/${ASS_PAGE_NAMES.assignmentCreate}/${ASS_PAGE_NAMES.truckloadingSlots}`;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { skipDefaultErrorHandling } from '@fluxys/common';
import { FlxValidatedResult, hasErrors } from '@fluxys/gsmart/rule-validation';
import { FlxMessageService, withLoadingSpinner } from '@fluxys/primeng';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { toUniqueSelectItems } from '@shared/utils';
import { BlobService } from 'psm5-web';
import { catchError, EMPTY, filter, MonoTypeOperatorFunction, noop, Observable, switchMap, tap } from 'rxjs';

import { DeleteDriverCommand, LinkBadgeToDriverCommand, ReturnBadgeCommand } from '../models/commands';
import { DriverOverviewDriverDto, DriverOverviewViewModel } from '../models/view-models';

interface DriversOverviewState extends DriverOverviewViewModel {
  loading: boolean;
}

const INITIAL_STATE: DriversOverviewState = {
  loading: false,
  drivers: new Array<DriverOverviewDriverDto>(),
  canCreateDriver: false,
  canConsultDriver: false,
  canEditDriver: false,
  canDeleteDriver: false,
  canLinkBadge: false,
  canReturnBadge: false,
  showBadgeNumber: false,
  badges: [],
};

@Injectable()
export class DriversManagerService extends ComponentStore<DriversOverviewState> {
  readonly loading$ = this.select(({ loading }) => loading);
  readonly drivers$ = this.select(({ drivers }) => drivers);
  readonly flags$ = this.select((state) => ({
    canCreateDriver: state.canCreateDriver,
    canConsultDriver: state.canConsultDriver,
    canEditDriver: state.canEditDriver,
    canDeleteDriver: state.canDeleteDriver,
    canLinkBadge: state.canLinkBadge,
    canReturnBadge: state.canReturnBadge,
    showBadgeNumber: state.showBadgeNumber,
  }));
  readonly badges$ = this.select(({ badges }) => badges);
  readonly nameFilters$ = this.select(({ drivers }) => toUniqueSelectItems(drivers, ({ name }) => name));
  readonly statusFilters$ = this.select(({ drivers }) => toUniqueSelectItems(drivers, ({ accreditationStatus }) => accreditationStatus));

  readonly loadDrivers = this.effect((source$) =>
    source$.pipe(
      tap(() => this._updateLoading(true)),
      switchMap(() =>
        this.findDriversCall('drivers-overview').pipe(tapResponse(this._updateDrivers.bind(this), noop, () => this._updateLoading(false)))
      )
    )
  );
  readonly deleteDriver = this.effect<number>((source$) =>
    source$.pipe(
      switchMap((driverId) =>
        this.deleteDriverCall({ driverId }, 'drivers-overview').pipe(
          this.reloadOnSuccessOrHandleError('app.modules.drivers.http-errors.delete-driver-error')
        )
      )
    )
  );

  readonly linkBadge = this.effect<LinkBadgeToDriverCommand>((source$) =>
    source$.pipe(
      switchMap((command) =>
        this.linkBadgeCall(command, 'drivers-overview').pipe(
          this.reloadOnSuccessOrHandleError('app.modules.drivers.http-errors.link-badge-error')
        )
      )
    )
  );

  readonly returnBadge = this.effect<ReturnBadgeCommand>((source$) =>
    source$.pipe(
      switchMap((command) =>
        this.returnBadgeCall(command, 'drivers-overview').pipe(
          this.reloadOnSuccessOrHandleError('app.modules.drivers.http-errors.return-badge-error')
        )
      )
    )
  );

  private readonly _updateLoading = this.updater<boolean>((state, loading) => ({ ...state, loading }));
  private readonly _updateDrivers = this.updater<DriverOverviewViewModel>((state, driversDto) => ({
    ...state,
    ...driversDto,
  }));
  private readonly _baseUrl = '/api/drivers-overview' as const;

  constructor(
    private readonly _messageService: FlxMessageService,
    private readonly _http: HttpClient,
    private readonly blobService: BlobService
  ) {
    super(INITIAL_STATE);
  }

  exportDrivers(context: string): Observable<unknown> {
    return this._http
      .get(`${this._baseUrl}/export`, {
        context: withLoadingSpinner(context, skipDefaultErrorHandling()),
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(tap((response) => this.blobService.open(response)));
  }

  private reloadOnSuccessOrHandleError(errorKey: string): MonoTypeOperatorFunction<FlxValidatedResult> {
    return (source$) =>
      source$.pipe(
        filter((result) => !hasErrors(result)),
        tap(() => this.loadDrivers()),
        catchError(() => {
          this._messageService.showErrorToast({ key: errorKey });
          return EMPTY;
        })
      );
  }

  /**
   * GET /api/drivers-overview/find
   * @param context spinner context
   * @returns all the drivers + permission flags
   */
  private findDriversCall(context: string): Observable<DriverOverviewViewModel> {
    return this._http.get<DriverOverviewViewModel>(`${this._baseUrl}/find`, {
      context: withLoadingSpinner(context),
    });
  }

  /**
   * POST /api/drivers-overview/delete
   * @param command command with driverId property
   * @param context spinner context
   * @returns validated result
   */
  private deleteDriverCall(command: DeleteDriverCommand, context: string): Observable<FlxValidatedResult> {
    return this._http.post(`${this._baseUrl}/delete`, command, {
      context: withLoadingSpinner(context, skipDefaultErrorHandling()),
    });
  }

  /**
   * POST /api/drivers-overview/link-badge
   * @param command command with badgeId & driverId properties
   * @param context spinner context
   * @returns validated result
   */
  private linkBadgeCall(command: LinkBadgeToDriverCommand, context: string): Observable<FlxValidatedResult> {
    return this._http.post(`${this._baseUrl}/link-badge`, command, {
      context: withLoadingSpinner(context, skipDefaultErrorHandling()),
    });
  }

  /**
   * POST /api/drivers-overview/return-badge
   * @param command command with badgeId & driverId properties
   * @param context spinner context
   * @returns validated result
   */
  private returnBadgeCall(command: ReturnBadgeCommand, context: string): Observable<FlxValidatedResult> {
    return this._http.post(`${this._baseUrl}/return-badge`, command, {
      context: withLoadingSpinner(context, skipDefaultErrorHandling()),
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlxDatePickerModule } from '@fluxys/gsmart/date-picker';
import { FlxRuleValidationModule } from '@fluxys/gsmart/rule-validation';
import { FlxWarningDialogModule, FlxWarningDialogService } from '@fluxys/gsmart/warning-dialog';
import { FlxPrimengModule } from '@fluxys/primeng';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { Psm5CommonModule } from 'psm5-web';

import { SharedModule } from '../shared/shared.module';

import { ChassisSectionComponent } from './components/vehicle-view-dialog/sections/chassis-section/chassis-section.component';
import { AddVehicleDocumentsComponent } from './components/vehicle-view-dialog/sections/documents-section/add-vehicle-document/add-vehicle-document.component';
import { VehicleDocumentsSectionComponent } from './components/vehicle-view-dialog/sections/documents-section/vehicle-documents-section.component';
import { LngContainerSectionComponent } from './components/vehicle-view-dialog/sections/lng-container-section/lng-container-section.component';
import { RoadTankerSectionComponent } from './components/vehicle-view-dialog/sections/road-tanker-section/road-tanker-section.component';
import { TractorSectionComponent } from './components/vehicle-view-dialog/sections/tractor-section/tractor-section.component';
import { VehicleInformationSectionComponent } from './components/vehicle-view-dialog/sections/vehicle-information-section/vehicle-information-section.component';
import { VehiclePartDialogComponent } from './components/vehicle-view-dialog/vehicle-part-dialog.component';
import { VehiclePartDialogService } from './components/vehicle-view-dialog/vehicle-part-dialog.service';
import { VehiclesTableComponent } from './components/vehicles-table/vehicles-table.component';
import { VehiclesOverviewComponent } from './pages/overview/vehicles-overview.component';
import { VehiclePartParametersService } from './services';
import { VehiclePartsEffects } from './store/vehicle-parts.effects';
import { VehicleEffects } from './store/vehicles.effects';
import { META_REDUCERS, REDUCERS } from './store/vehicles.reducers';
import { VehiclesRoutingModule } from './vehicles-routing.module';
import { VehiclesServicesModule } from './vehicles-services.module';

const PRIME_NG_MODULES = [ButtonModule, CheckboxModule, DialogModule, FileUploadModule, SplitButtonModule, TableModule];
@NgModule({
  declarations: [
    AddVehicleDocumentsComponent,
    ChassisSectionComponent,
    LngContainerSectionComponent,
    RoadTankerSectionComponent,
    TractorSectionComponent,
    VehicleDocumentsSectionComponent,
    VehicleInformationSectionComponent,
    VehiclePartDialogComponent,
    VehiclesOverviewComponent,
    VehiclesTableComponent,
  ],
  imports: [
    CommonModule,
    FlxDatePickerModule,
    FlxPrimengModule,
    FlxRuleValidationModule,
    FlxWarningDialogModule,
    FormsModule,
    StoreModule.forFeature('vehicles', REDUCERS, { metaReducers: META_REDUCERS }),
    EffectsModule.forFeature([VehicleEffects, VehiclePartsEffects]),
    PRIME_NG_MODULES,
    Psm5CommonModule,
    ReactiveFormsModule,
    SharedModule,
    VehiclesRoutingModule,
    VehiclesServicesModule,
  ],
  providers: [FlxWarningDialogService, VehiclePartDialogService, VehiclePartParametersService],
})
export class VehiclesModule {}

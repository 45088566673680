import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'trkmgr-driver-table-controls',
  templateUrl: './driver-table-controls.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverTableControlsComponent {
  @Input() edit = false;
  @Input() canEdit = false;
  @Input() canDelete = false;

  @Output() readonly editClicked = new EventEmitter();
  @Output() readonly deleteClicked = new EventEmitter();
  @Output() readonly validate = new EventEmitter();
  @Output() readonly cancel = new EventEmitter();
}

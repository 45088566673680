<flx-layout
  [showLanguageSelector]="false"
  [showScreenMaximizationButton]="true"
  [hideMenuBarsWhenScreenIsMaximized]="true"
  [navigateOnContextSwitch]="false"
>
  <ng-template flxLayout="2ndTopBarContent">
    <div class="tenant-indicator">{{ tenantLabel$ | async | transloco }}</div>
  </ng-template>

  <flx-loading-spinner context="route-resolvers"></flx-loading-spinner>

  <flx-breadcrumb *ngIf="(noBreadcrumb$ | async) !== true" [updateOnNavigation]="true"></flx-breadcrumb>

  <router-outlet></router-outlet>
</flx-layout>

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppState } from '@core/store';
import { Store } from '@ngrx/store';
import { Observable, first, tap } from 'rxjs';

import { ACTIONS } from '../store/vehicles.actions';
import { SELECTORS } from '../store/vehicles.selectors';

@Injectable()
export class InitializeVehiclesGuard implements CanActivate {
  constructor(private readonly store: Store<AppState>) {}

  canActivate(): Observable<boolean> {
    return this.store.select(SELECTORS.initialized).pipe(
      tap((initialized) => {
        if (!initialized) this.store.dispatch(ACTIONS.initializeModule());
      }),
      first((initialized) => initialized)
    );
  }
}

import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, map, Observable, startWith } from 'rxjs';

import { AssignmentsRoutes } from '../../assignments-routes';
import { AssignmentRequestViewModel } from '../../models/assignment-request-overview/assignment-request.viewmodel';
import { AssignmentViewModel } from '../../models/assignment-request-overview/assignment.viewmodel';
import { AssignmentsOverviewViewModel } from '../../models/assignment-request-overview/assignments-overview.viewmodel';
import { AssignmentOverviewService as AssignmentOverviewService } from '../../services/assignment-overview.service';

@UntilDestroy()
@Component({
  selector: 'trkmgr-assignments-overview',
  templateUrl: './assignments-overview.component.html',
  styleUrls: ['./assignments-overview.component.scss'],
})
export class AssignmentsOverviewComponent {
  model$: Observable<AssignmentsOverviewViewModel>;
  filteredModel$: Observable<AssignmentsOverviewViewModel>;
  yearsFilter: UntypedFormControl;

  constructor(private readonly assignmentService: AssignmentOverviewService, private readonly router: Router) {
    const currentYear = new Date().getFullYear();
    this.model$ = this.assignmentService.getAssignmentOverviewModel(null);
    this.yearsFilter = new UntypedFormControl(currentYear);

    this.filteredModel$ = combineLatest(this.model$, this.yearsFilter.valueChanges.pipe(startWith(currentYear))).pipe(
      map(([model, selectedYear]: [AssignmentsOverviewViewModel, number]) => ({
        ...model,
        pendingAssignments: selectedYear
          ? model.pendingAssignments.filter((ass: AssignmentRequestViewModel) => ass.operationalYears.some((y) => y === selectedYear))
          : model.pendingAssignments,
        completedAssignments: selectedYear
          ? model.completedAssignments.filter((ass: AssignmentViewModel) => ass.operationalYears.some((y) => y === selectedYear))
          : model.completedAssignments,
      }))
    );
  }

  onNewAssignmentButtonClicked(): void {
    void this.router.navigate([AssignmentsRoutes.selectionRoute]);
  }
}

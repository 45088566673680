import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureFlagsService } from '@core/services/feature-flags.service';
import { FlxValidatedResult } from '@fluxys/gsmart/rule-validation';
import { withLoadingSpinner } from '@fluxys/primeng';
import { RegisterCommandResultViewModel } from '@shared/models';
import { DateTime } from 'luxon';
import { Observable, of } from 'rxjs';

import { VehicleDocument } from '../../shared/models/vehicle-document';
import {
  VehiclePartConfigureCommandViewModel,
  VehiclePartListViewModel,
  VehiclePartOverviewViewModel,
  VehiclePartRegisterCommandViewModel,
} from '../models';
import { VehiclePartStatusViewModel } from '../models/vehicle-part-status.viewmodel';
import { VehiclesServicesModule } from '../vehicles-services.module';

@Injectable({ providedIn: VehiclesServicesModule })
export class VehiclePartService {
  private readonly _basePath = '/api/vehicles';
  private hasDocumentsSection = false;

  constructor(private readonly _http: HttpClient, featureFlags: FeatureFlagsService) {
    featureFlags.flags$.subscribe((f) => (this.hasDocumentsSection = f.vehiclePartDocumentUploadEnabled));
  }

  configureVehiclePart(command: VehiclePartConfigureCommandViewModel, validationContext?: string): Observable<FlxValidatedResult> {
    return this._http.post(`${this._basePath}/parts/configure`, command, {
      context: withLoadingSpinner(validationContext),
    });
  }

  deleteVehiclePart(id: number, validationContext?: string): Observable<FlxValidatedResult> {
    return this._http.post<FlxValidatedResult>(
      `${this._basePath}/parts/delete`,
      { vehiclePartId: id },
      { context: withLoadingSpinner(validationContext) }
    );
  }

  registerVehiclePart(
    command: VehiclePartRegisterCommandViewModel,
    validationContext?: string
  ): Observable<RegisterCommandResultViewModel> {
    return this._http.post<RegisterCommandResultViewModel>(`${this._basePath}/parts/register`, command, {
      context: withLoadingSpinner(validationContext),
    });
  }

  getVehiclePartList(): Observable<VehiclePartListViewModel> {
    return this._http.get<VehiclePartListViewModel>(`${this._basePath}/parts/overview`, {
      context: withLoadingSpinner('vehicle-parts-table'),
    });
  }

  getVehiclePart(id: number): Observable<VehiclePartOverviewViewModel> {
    return this._http.get<VehiclePartOverviewViewModel>(`/api/vehicles/parts/${id}`);
  }

  getVehicleDocuments(id: number): Observable<VehicleDocument[]> {
    if (!this.hasDocumentsSection) {
      return of();
    }
    return this._http.get<VehicleDocument[]>(`/api/vehiclepartdocuments/${id}`);
  }

  saveVehicleDocuments(documents: VehicleDocument[], validationContext?: string): Observable<FlxValidatedResult> {
    const formData = new FormData();
    documents.forEach((doc, index) => {
      if (doc.document) {
        formData.append(`model[${index}].document`, doc.document, doc.document.name);
      }
      formData.append(`model[${index}].id`, doc.id.toString());
      formData.append(`model[${index}].vehiclePartId`, doc.vehiclePartId ? doc.vehiclePartId.toString() : '');
      formData.append(`model[${index}].documentType`, doc.documentType.toString());
      formData.append(`model[${index}].validFrom`, doc.validFrom ? DateTime.fromJSDate(doc.validFrom).toFormat('yyyyMMdd') : '');
      formData.append(`model[${index}].validTo`, doc.validTo ? DateTime.fromJSDate(doc.validTo).toFormat('yyyyMMdd') : '');
      formData.append(`model[${index}].validated`, (doc.validated ?? false).toString());
      formData.append(`model[${index}].documentName`, doc.documentName ?? '');
    });

    return this._http.post(`/api/vehiclepartdocuments/savedocuments`, formData, {
      context: withLoadingSpinner(validationContext),
    });
  }

  getStatuses(): Observable<VehiclePartStatusViewModel[]> {
    return this._http.get<VehiclePartStatusViewModel[]>(`/api/vehicles/parts/statuses`);
  }

  updateStatus(vehiclePartId: number): Observable<FlxValidatedResult> {
    return this._http.post<FlxValidatedResult>(`/api/vehicles/parts/status`, { vehiclePartId });
  }

  exportVehicleParts(): Observable<HttpResponse<Blob>> {
    return this._http.get(`${this._basePath}/parts/export`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  openVehicleDocument(id: number): Observable<Blob> {
    return this._http.get(`/api/vehiclepartdocuments/open-document/${id}`, { responseType: 'blob' });
  }
}

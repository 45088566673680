import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { hasErrors } from '@fluxys/gsmart/rule-validation';
import { FlxValidationMessagesServices, FlxValidators } from '@fluxys/gsmart/validation';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { notNullOrUndefined } from '@shared/rxjs/operators';
import { TruckValidators } from '@shared/validators/truck.validators';
import { DateTime } from 'luxon';
import { SelectItem } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { valueChanges } from 'psm5-web';
import { filter, map, Observable, shareReplay } from 'rxjs';

import { AcrDialogViewModel, AutoConfirmationRuleRegisterCommandViewModel } from '../../models/acr.models';
import { AcrService } from '../../services/acr.service';
import { actions } from '../../store/acr.actions';

@UntilDestroy()
@Component({
  selector: 'trkmgr-acr-dialog',
  templateUrl: './acr-dialog.component.html',
  styleUrls: ['./acr-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcrDialogComponent implements OnInit {
  readonly validationContext: string = 'register-acr';
  readonly currentYear: number;
  readonly form: UntypedFormGroup;

  shippers$!: Observable<SelectItem<number>[] | undefined>;
  customers$!: Observable<SelectItem[] | undefined>;
  enabled: boolean;

  private readonly dialogInfo$: Observable<AcrDialogViewModel>;
  private readonly minDate: Date;
  private maxDate: Date | null = null;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly dialogRef: DynamicDialogRef,
    private readonly acrService: AcrService,
    private readonly validationMessagesService: FlxValidationMessagesServices,
    private readonly errorService: ErrorHandlerService
  ) {
    // get dialog info
    this.dialogInfo$ = this.acrService.dialog().pipe(shareReplay());
    this.mapSelectItmems();
    this.form = this.buildForm();
    this.enabled = true;
    this.currentYear = new Date().getFullYear();
    this.minDate = new Date(this.currentYear, 0, 1);
    this.validationMessagesService.registerCustomValidationMessages({
      dateBeforeRange: 'app.modules.autoconfirmation.components.dialog.validation.date-before-range',
      dateAfterRange: 'app.modules.autoconfirmation.components.dialog.validation.date-after-range',
    });
  }

  private get validityPeriodControl(): UntypedFormGroup {
    return this.form.get('validityPeriod') as UntypedFormGroup;
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.shippers$
      .pipe(
        notNullOrUndefined(),
        filter((shippers) => shippers.length === 1),
        untilDestroyed(this)
      )
      .subscribe((shippers) => {
        this.form.patchValue({ grantedbyshipper: shippers[0].value });
        this.form.get('grantedbyshipper')?.disable();
      });

    valueChanges<boolean>(this.validityPeriodControl, 'untilendofcurrentyear', true)
      .pipe(untilDestroyed(this))
      // eslint-disable-next-line import/no-deprecated
      .subscribe((untilEndOfCurrentYear) => {
        const fromControl = this.validityPeriodControl.get('from');
        const validators = [Validators.required];

        // set max constraint on "from" date if checkbox is checked
        if (untilEndOfCurrentYear) {
          this.maxDate = new Date(this.currentYear, 11, 31);
          this.validityPeriodControl.get('until')?.setValue(this.maxDate);
          validators.push(TruckValidators.inDateRange(this.minDate, this.maxDate));
        } else {
          this.maxDate = null;
        }

        fromControl?.setValidators(validators);
        fromControl?.updateValueAndValidity();
      });
  }

  register(): void {
    if (this.form.valid) {
      interface FormModel {
        grantedbyshipper: number;
        fortruckloadingcustomer: number;
        validityPeriod: {
          from: Date;
          until: Date;
        };
      }
      const formValue = this.form.getRawValue() as FormModel;

      const commandVm: AutoConfirmationRuleRegisterCommandViewModel = {
        confirmationGrantingShipperId: formValue.grantedbyshipper,
        confirmationReceivingTruckLoadingCustomerId: formValue.fortruckloadingcustomer,
        validityPeriodBegin: DateTime.fromJSDate(formValue.validityPeriod.from).toFormat('yyyyMMdd'),
        validityPeriodEnd: DateTime.fromJSDate(formValue.validityPeriod.until).toFormat('yyyyMMdd'),
      };

      this.disableSelf();

      this.errorService.makeRequest(
        this.acrService.register(commandVm, 'register-acr'),
        (response) => {
          this.enableSelf();

          if (!hasErrors(response)) {
            this.dialogRef.close();
            return actions.acr.overview.registered();
          }
        },
        () => this.enableSelf(),
        'app.modules.autoconfirmation.http-errors.register-acr-error'
      );
    }
  }

  private mapSelectItmems(): void {
    this.shippers$ = this.dialogInfo$.pipe(
      map((vm) =>
        vm.shippers.map((shipper) => ({
          label: shipper.shortName,
          value: shipper.businessPartyRoleId,
        }))
      )
    );
    this.customers$ = this.dialogInfo$.pipe(
      map((vm) =>
        vm.customers.map((customer) => ({
          label: customer.shortName,
          value: customer.businessPartyRoleId,
        }))
      )
    );
  }

  private buildForm(): UntypedFormGroup {
    const now = new Date();
    const from = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const until = new Date(now.getFullYear(), 11, 31);

    return this.fb.group({
      grantedbyshipper: this.fb.control(undefined, Validators.required),
      fortruckloadingcustomer: this.fb.control(undefined, Validators.required),
      validityPeriod: this.fb.group(
        {
          from: this.fb.control(from, Validators.required),
          until: this.fb.control(until, Validators.required),
          untilendofcurrentyear: this.fb.control(true),
        },
        { validators: FlxValidators.startDateBeforeEndDate('from', 'until') }
      ),
    });
  }

  private disableSelf(): void {
    this.form.disable();
    this.enabled = false;
  }

  private enableSelf(): void {
    this.form.enable();
    this.enabled = true;
  }
}

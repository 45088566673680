<div class="container" *transloco="let t; read: 'app.modules.assignments.pages.assignment-selection'">
  <div class="row">
    <div class="col">
      <h1 class="flx-header--title">{{ t('title') }}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <p-card styleClass="form">
        <section>
          <div class="section-title">{{ t('slot-assignment-section.title') }}</div>
          <div class="section-content">
            <p>
              {{ t('slot-assignment-section.summary') }}
              <br />
              <br />
              {{ t('slot-assignment-section.description') }}
            </p>
            <div class="d-flex justify-content-end">
              <button
                pButton
                [label]="t('slot-assignment-section.button-label')"
                icon="pi pi-plus"
                (click)="onNewSlotAssignmentButtonClicked()"
              ></button>
            </div>
          </div>
        </section>
      </p-card>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <p-card class="form">
        <section>
          <div class="section-title">{{ t('appointment-assignment-section.title') }}</div>
          <div class="section-content">
            <p>
              {{ t('appointment-assignment-section.summary') }}
              <br />
              <br />
              {{ t('appointment-assignment-section.description') }}
            </p>
            <div class="d-flex justify-content-end">
              <button
                pButton
                [label]="t('appointment-assignment-section.button-label')"
                icon="pi pi-plus"
                (click)="onNewAppointmentAssignmentButtonClicked()"
              ></button>
            </div>
          </div>
        </section>
      </p-card>
    </div>
  </div>
</div>

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { VehiclePartTypeLiteral } from '@shared/models';
import { TruckValidators } from '@shared/validators/truck.validators';
import { DateTime } from 'luxon';

import { VehiclePartConfigureCommandViewModel, VehiclePartFormData, VehiclePartRegisterCommandViewModel } from '../../models';
import { VehicleFormMode } from '../../store';

/**
 * Service that provides helpers for component VehiclePartDialogComponent
 * Not the most elegant, but we should otherwise use the ngrx store with gazillions effects & actions
 */
export class VehiclePartDialogService {
  mapToRegister(formData: VehiclePartFormData<Date>): VehiclePartRegisterCommandViewModel {
    const formGroupName = formData.vehiclePartType?.toLowerCase() as `${Lowercase<NonNullable<typeof formData.vehiclePartType>>}`;

    const command: VehiclePartRegisterCommandViewModel = {
      transporterId: formData.vehicleInformation.owner,
      vehiclePartType: formData.vehiclePartType,
      comment: formData.vehicleInformation.comment,
      weight: formData.vehicleInformation.weight,
      statusId: formData.vehicleInformation.statusId,
      rfidTag: formData.vehicleInformation.rfidTag,
      isDefect: formData.vehicleInformation.isDefect,
    };

    // map container code
    switch (formGroupName) {
      case 'roadtanker':
      case 'lngcontainer':
        command.containerCode = formData[formGroupName]?.containerCode;
        break;
      default:
        break;
    }

    // map vehicle identification
    switch (formGroupName) {
      case 'chassis':
      case 'tractor':
      case 'roadtanker':
        command.countryId = formData[formGroupName]?.countryId;
        command.vehicleIdentification = formData[formGroupName]?.vehicleIdentification;
        break;
      default:
        break;
    }

    switch (formGroupName) {
      // map container stuff
      case 'roadtanker':
      case 'lngcontainer':
        command.maxFillingPercentage = formData[formGroupName]?.maxFillingPercentage;
        command.totalLoadingVolume = formData[formGroupName]?.totalLoadingVolume;
        command.maxOperatingPressure = formData[formGroupName]?.maxOperatingPressure;
        command.loadingSide = formData[formGroupName]?.loadingSide;
        break;
      // map tractor stuff
      case 'tractor':
        command.lngFueled = formData.tractor?.lngFueled;
        break;
      default:
        break;
    }

    return command;
  }

  mapToConfigure(formData: VehiclePartFormData<Date>): VehiclePartConfigureCommandViewModel {
    const formGroupName = formData.vehiclePartType?.toLowerCase() as `${Lowercase<NonNullable<typeof formData.vehiclePartType>>}`;

    const command: VehiclePartConfigureCommandViewModel = {
      vehiclePartId: formData.vehiclePartId,
      transporterId: formData.vehicleInformation.owner,
      vehiclePartType: formData.vehiclePartType,
      comment: formData.vehicleInformation.comment,
      weight: formData.vehicleInformation.weight,
      statusId: formData.vehicleInformation.statusId,
      expiryDate: formData.vehicleInformation.dateExpired
        ? DateTime.fromJSDate(formData.vehicleInformation.dateExpired).toFormat('yyyyMMdd')
        : undefined,
      rfidTag: formData.vehicleInformation.rfidTag,
      isDefect: formData.vehicleInformation.isDefect,
    };

    // map container code
    switch (formGroupName) {
      case 'roadtanker':
      case 'lngcontainer':
        command.containerCode = formData[formGroupName]?.containerCode;
        break;
      default:
        break;
    }

    // map vehicle identification
    switch (formGroupName) {
      case 'chassis':
      case 'tractor':
      case 'roadtanker':
        command.countryId = formData[formGroupName]?.countryId;
        command.vehicleIdentification = formData[formGroupName]?.vehicleIdentification;
        break;
      default:
        break;
    }

    switch (formGroupName) {
      // map container stuff
      case 'roadtanker':
      case 'lngcontainer':
        command.maxFillingPercentage = formData[formGroupName]?.maxFillingPercentage;
        command.totalLoadingVolume = formData[formGroupName]?.totalLoadingVolume;
        command.maxOperatingPressure = formData[formGroupName]?.maxOperatingPressure;
        command.loadingSide = formData[formGroupName]?.loadingSide;
        break;
      // map tractor stuff
      case 'tractor':
        command.lngFueled = formData.tractor?.lngFueled;
        break;
      default:
        break;
    }

    return command;
  }

  createForm(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({
      vehiclePartId: fb.control(undefined),
      vehiclePartType: fb.control(undefined, Validators.required),
      tcn: fb.control(undefined),
      vehicleInformation: fb.group({
        owner: fb.control(undefined),
        dateAdded: fb.control(undefined),
        dateExpired: fb.control(null, (control) => {
          if (control.value instanceof Date) {
            if (DateTime.fromJSDate(control.value) < DateTime.now().startOf('day')) {
              return { foei: 'foei' };
            }
          }
          return null;
        }),
        rfidTag: fb.control(null, Validators.maxLength(30)),
        statusId: fb.control(undefined, Validators.required),
        weight: fb.control(undefined),
        isDefect: fb.control(false),
        comment: fb.control(undefined),
      }),
      tractor: fb.group({
        vehicleIdentification: fb.control(
          undefined,
          Validators.compose([Validators.required, TruckValidators.maxLicensePlateCharactersLength])
        ),
        countryId: fb.control(undefined),
        lngFueled: fb.control(false),
      }),
      lngcontainer: fb.group({
        containerCode: fb.control(undefined, Validators.compose([Validators.required, TruckValidators.maxReferenceCodeCharactersLength])),
        maxFillingPercentage: fb.control(undefined),
        totalLoadingVolume: fb.control(undefined),
        maxOperatingPressure: fb.control(undefined),
        maxLoadingVolume: fb.control(undefined),
        loadingSide: fb.control(undefined),
      }),
      roadtanker: fb.group({
        containerCode: fb.control(undefined, Validators.compose([Validators.required, TruckValidators.maxReferenceCodeCharactersLength])),
        vehicleIdentification: fb.control(
          undefined,
          Validators.compose([Validators.required, TruckValidators.maxLicensePlateCharactersLength])
        ),
        countryId: fb.control(undefined),
        maxFillingPercentage: fb.control(undefined),
        totalLoadingVolume: fb.control(undefined),
        maxOperatingPressure: fb.control(undefined),
        maxLoadingVolume: fb.control(undefined),
        loadingSide: fb.control(undefined),
      }),
      chassis: fb.group({
        vehicleIdentification: fb.control(
          undefined,
          Validators.compose([Validators.required, TruckValidators.maxLicensePlateCharactersLength])
        ),
        countryId: fb.control(undefined),
      }),
      validDocumentsSection: fb.control(true, Validators.required), // we create a new form for documents section. Set this to true if valid and to null if not, so the save is blocked
    });
  }

  configureForm(form: UntypedFormGroup, vehiclePartType: VehiclePartTypeLiteral | null | undefined, mode: VehicleFormMode): void {
    form.enable();

    form.get('roadtanker')?.disable();
    form.get('lngcontainer')?.disable();
    form.get('tractor')?.disable();
    form.get('chassis')?.disable();
    form.get('vehicleInformation.dateAdded')?.disable();

    const section = form.get(vehiclePartType?.toLowerCase() ?? 'UNREACHABLE');
    section?.enable();

    switch (mode) {
      case 'create':
        form.get('vehicleInformation.statusId')?.disable();
        form.get('vehicleInformation.dateExpired')?.disable();
        break;
      case 'edit':
        break;
      case 'editDocuments':
        form.get('vehicleInformation')?.disable();
        section?.disable();
        break;
      case 'view':
        form.disable();
        break;
    }
  }
}

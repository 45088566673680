import { Injectable } from '@angular/core';

import {
  ConfigureAffiliationCommand,
  ConfigureApprovalCommand,
  ConfigureDocumentCommand,
  ConfigureTrainingCommand,
  DeleteAffiliationCommand,
  DeleteDocumentCommand,
  DeleteTrainingCommand,
  RegisterAffiliationCommand,
  RegisterDocumentCommand,
  RegisterTrainingCommand,
} from '../models/commands';
import { AffiliationViewModel, ApprovalViewModel, DocumentViewModel, TrainingViewModel } from '../models/view-models';

/**
 * Helps mapping view-models, form-models, events to commands
 */
@Injectable()
export class CommandsBuilderService {
  buildRegisterAffiliationCommand(affiliation: AffiliationViewModel, driverId: number): RegisterAffiliationCommand {
    return {
      driverId,
      operationalPeriodStartDate: affiliation.operationalPeriodStartDate,
      operationalPeriodEndDate: affiliation.operationalPeriodEndDate,
      transporterBusinessPartyRoleId: affiliation.transporterBusinessPartyRoleId,
    };
  }

  buildConfigureAffiliationCommand(affiliation: AffiliationViewModel, driverId: number): ConfigureAffiliationCommand {
    return {
      affiliationId: affiliation.id,
      tcn: affiliation.tcn,
      driverId,
      operationalPeriodStartDate: affiliation.operationalPeriodStartDate,
      operationalPeriodEndDate: affiliation.operationalPeriodEndDate,
      transporterBusinessPartyRoleId: affiliation.transporterBusinessPartyRoleId,
    };
  }

  buildDeleteAffiliationCommand(affiliationId: number, driverId: number): DeleteAffiliationCommand {
    return {
      driverId,
      affiliationId,
    };
  }

  buildConfigureApprovalCommand(approval: ApprovalViewModel, driverId: number): ConfigureApprovalCommand {
    return {
      driverId,
      accreditationStatus: approval.status,
      validityPeriodStartDate: approval.validityPeriodStartDate,
      validityPeriodEndDate: approval.validityPeriodEndDate,
    };
  }

  buildRegisterDocumentCommand(model: DocumentViewModel, driverId: number): RegisterDocumentCommand {
    return {
      driverId,
      documentType: model.documentType,
      reference: model.reference,
      validityPeriodStartDate: model.validityPeriodStartDate,
      validityPeriodEndDate: model.validityPeriodEndDate,
    };
  }

  buildConfigureDocumentCommand(model: DocumentViewModel, driverId: number): ConfigureDocumentCommand {
    return {
      driverId,
      documentType: model.documentType,
      reference: model.reference,
      validityPeriodStartDate: model.validityPeriodStartDate,
      validityPeriodEndDate: model.validityPeriodEndDate,
      tcn: model.tcn,
      documentId: model.id,
    };
  }

  buildDeleteDocumentCommand(modelId: number, driverId: number): DeleteDocumentCommand {
    return {
      driverId,
      documentId: modelId,
    };
  }

  buildConfigureTrainingCommand(training: TrainingViewModel, driverId: number): ConfigureTrainingCommand {
    return {
      validatedTrainingId: training.id,
      driverId,
      tcn: training.tcn,
      trainingId: training.trainingTypeId,
      validityPeriodStartDate: training.validityPeriodStartDate,
      validityPeriodEndDate: training.validityPeriodEndDate,
    };
  }

  buildRegisterTrainingCommand(training: TrainingViewModel, driverId: number): RegisterTrainingCommand {
    return {
      trainingId: training.trainingTypeId,
      driverId,
      validityPeriodStartDate: training.validityPeriodStartDate,
      validityPeriodEndDate: training.validityPeriodEndDate,
    };
  }

  buildDeleteTrainingCommand(trainingId: number, driverId: number): DeleteTrainingCommand {
    return {
      driverId,
      validatedTrainingId: trainingId,
    };
  }
}

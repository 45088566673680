/* eslint-disable @typescript-eslint/naming-convention */ // TODO disable this rule for enums, or find a solution because we need the keys named as they are right now
export enum LoadingSide {
  LeftFront = 'LeftFront',
  LeftMiddle = 'LeftMiddle',
  LeftBack = 'LeftBack',
  RightFront = 'RightFront',
  RightMiddle = 'RightMiddle',
  RightBack = 'RightBack',
  Behind = 'Behind',
}

export type LoadingSideLiteral = keyof typeof LoadingSide;

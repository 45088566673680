<flx-rule-validation [context]="context" displayMode="dialog" (closed)="onValidationErrorDialogClosed()"></flx-rule-validation>
<flx-loading-spinner [context]="context"></flx-loading-spinner>
<div class="container" *transloco="let t; read: 'app.modules.assignments.pages.assignment-details'">
  <div *ngIf="vm$ | async as vm">
    <div class="row justify-content-between">
      <div class="col">
        <h1 class="flx-header--title">{{ vm.requestType ==='Appointment'? t('title-appointment') : t('title-slot') }}</h1>
      </div>
      <div class="col-auto">
        <button
          pButton
          [label]="'app.shared.buttons.cancel' | transloco"
          (click)="onCancelButtonClicked()"
          class="p-button-tertiary"
        ></button>
        <button
          pButton
          *ngIf="vm.canDecline"
          [label]="t('buttons.decline')"
          icon="pi pi-ban"
          (click)="onDeclineRequestButtonClicked()"
          class="p-button-secondary ml-3"
        ></button>
        <button
          pButton
          *ngIf="vm.canAccept"
          [label]="t('buttons.accept')"
          icon="pi pi-check"
          (click)="onAcceptRequestButtonClicked()"
          class="ml-3"
        ></button>
        <button
          pButton
          *ngIf="vm.canDelete"
          [label]="t('buttons.delete')"
          icon="pi pi-times"
          (click)="onDeleteRequestButtonClicked()"
          class="ml-3"
        ></button>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p-card #detailsCard styleClass="form">
          <trkmgr-assignment-request-details-general [vm]="vm"></trkmgr-assignment-request-details-general>
          <trkmgr-assignment-request-details-appointment
            *ngIf="vm.requestType === 'Appointment'"
            [vm]="vm.appointmentDetails"
          ></trkmgr-assignment-request-details-appointment>
          <trkmgr-assignment-request-details-slot
            *ngIf="vm.requestType === 'Slot'"
            [vm]="vm.slotDetails"
          ></trkmgr-assignment-request-details-slot>
        </p-card>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog #cd header="Confirmation Dialog">
  <p-footer>
    <button type="button" pButton label="Cancel" class="p-button-secondary" (click)="cd.reject()"></button>
    <button type="button" pButton label="Ok" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withLoadingSpinner } from '@fluxys/primeng';
import { HttpParamsFactory } from '@shared/utils';
import { Observable } from 'rxjs';

import { AssignmentsOverviewViewModel } from '../models/assignment-request-overview/assignments-overview.viewmodel';

@Injectable({
  providedIn: 'root',
})
export class AssignmentOverviewService {
  private readonly _basePath = '/api/assignment/assignments-overview';

  constructor(private readonly _http: HttpClient) {}

  getAssignmentOverviewModel(year: number | null): Observable<AssignmentsOverviewViewModel> {
    let params = HttpParamsFactory.create();

    if (year !== null) {
      params = params.set('year', year);
    }

    return this._http.get<AssignmentsOverviewViewModel>(`${this._basePath}/find`, {
      params,
      context: withLoadingSpinner('assignments-overview'),
    });
  }
}

import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'trkmgr-chassis-section',
  templateUrl: './chassis-section.component.html',
  styleUrls: ['../../vehicle-part-dialog.component.scss'],
})
export class ChassisSectionComponent {
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() countries: SelectItem[] | null = [];
}

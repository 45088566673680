import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'toStandardPrice',
})
export class ToStandardPricePipe implements PipeTransform {
  constructor(private readonly translationService: TranslocoService) {}

  transform(value: number | null | undefined): string {
    if (value === null || value === undefined) {
      return this.translationService.translate('app.modules.assignments.shared.pipes.standard-price');
    }

    return `${value} €`;
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

import { RouterStateUrl } from './router-state-url';

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const intermediateState: Pick<RouterStateUrl, Exclude<keyof RouterStateUrl, 'lastRouteConfigPath'>> = {
      url: routerState.url,
      params: this.mergeRouteInfo(routerState.root, (r) => r.params),
      queryParams: this.mergeRouteInfo(routerState.root, (r) => r.queryParams),
      data: this.mergeRouteInfo(routerState.root, (r) => r.data),
      routeConfigPath: this.mergeRouteConfigPath(routerState.root).filter((x) => x),
    };
    const state: RouterStateUrl = {
      ...intermediateState,
      lastRouteConfigPath: intermediateState.routeConfigPath[intermediateState.routeConfigPath.length - 1],
    };
    return state;
  }

  private mergeRouteInfo(route: ActivatedRouteSnapshot | null, getter: (r: ActivatedRouteSnapshot) => Data | Params): Data | Params {
    if (route === null) {
      return {};
    }

    const current = getter(route);
    const primaryChild = route.firstChild;
    return { ...current, ...this.mergeRouteInfo(primaryChild, getter) };
  }

  private mergeRouteConfigPath(route: ActivatedRouteSnapshot | null): string[] {
    if (route === null) {
      return [];
    }

    const routeConfig = route.routeConfig;
    if (routeConfig?.path !== undefined) {
      const path = routeConfig.path;
      return [path, ...this.mergeRouteConfigPath(route.firstChild)];
    } else {
      return this.mergeRouteConfigPath(route.firstChild);
    }
  }
}

<ng-container>
  <section *ngIf="vm">
    <span class="section-title" transloco="app.modules.assignments.pages.assignment-details.slot.title"></span>
    <div class="section-content">
      <div class="d-flex">
        <flx-form-field-wrapper label="app.modules.assignments.pages.assignment-details.slot.request-reference" ngClass="w-50 mr-2"
          >{{vm.assignmentRequestReference}}</flx-form-field-wrapper
        >
        <flx-form-field-wrapper label="app.modules.assignments.pages.assignment-details.slot.service-reference" ngClass="w-50 ml-2"
          >{{vm.assignmentReference ?? "-"}}</flx-form-field-wrapper
        >
      </div>

      <div class="d-flex mt-4">
        <flx-form-field-wrapper
          label="app.modules.assignments.pages.assignment-details.slot.assignor-contract-reference"
          ngClass="w-50 mr-2"
          >{{vm.assignorContractReference ?? "-"}}</flx-form-field-wrapper
        >
        <flx-form-field-wrapper
          label="app.modules.assignments.pages.assignment-details.slot.assignee-contract-reference"
          ngClass="w-50 ml-2"
          >{{vm.assigneeContractReference ?? "-"}}</flx-form-field-wrapper
        >
      </div>

      <div class="d-flex mt-4">
        <flx-form-field-wrapper label="app.modules.assignments.pages.assignment-details.slot.number-of-slots" ngClass="w-50 mr-2"
          >{{vm.numberOfSlots}}</flx-form-field-wrapper
        >
        <flx-form-field-wrapper
          *ngIf="vm.pricePerSlot.shouldDisplayPrice"
          label="app.modules.assignments.pages.assignment-details.slot.price-per-slot"
          ngClass="w-50 ml-2"
          >{{vm.pricePerSlot.price | toStandardPrice }}</flx-form-field-wrapper
        >
      </div>

      <div class="d-flex mt-4">
        <flx-form-field-wrapper label="app.modules.assignments.pages.assignment-details.slot.operational-period" ngClass="w-50 mr-2"
          >{{vm.operationalPeriodBeginDate | toDate |date:'dd.MM.yyyy'}} - {{vm.operationalPeriodEndDate | toDate
          |date:'dd.MM.yyyy'}}</flx-form-field-wrapper
        >

        <flx-form-field-wrapper label="app.modules.assignments.pages.assignment-details.slot.type-of-assignment" ngClass="w-50 ml-2">
          {{ ("app.modules.assignments.components.shared.enums.assignment-type." + vm.typeOfAssignment) |
          transloco}}</flx-form-field-wrapper
        >
      </div>
    </div>
  </section>
</ng-container>

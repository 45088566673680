import { Injectable } from '@angular/core';
import { actions, AppState } from '@core/store';
import { Store } from '@ngrx/store';
import { ImpersonationHandler } from 'psm5-web';

@Injectable()
export class CustomImpersonationHandler extends ImpersonationHandler {
  constructor(private readonly _store: Store<AppState>) {
    super();
  }

  onImpersonate(): void {
    this._store.dispatch(actions.user.loadUserPermissions());
  }
}

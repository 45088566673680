import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'trkmgr-table-row-menu',
  templateUrl: './table-row-menu.component.html',
  styleUrls: ['./table-row-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableRowMenuComponent {
  @Input() data: unknown;
  @Input() disabled?: boolean;

  private _menuItems: MenuItem[] = [];

  get menuItems(): MenuItem[] {
    return this._menuItems;
  }
  @Input() set menuItems(value: MenuItem[]) {
    this._menuItems = value.map((menuItem) => {
      const result = { ...menuItem };
      const originalCommand = result.command;
      if (originalCommand) {
        result.command = () => originalCommand(this.data);
      }
      return result;
    });
  }
}

import { SelectItem } from 'primeng/api';

import { VehiclePartType } from '../../shared/models';
import { VehicleDocument } from '../../shared/models/vehicle-document';
import { BusinessPartyViewModel, VehiclePartFormData, VehiclePartListViewModel, VehiclePartOverviewViewModel } from '../models';
import { VehiclePartFormState } from '../models/vehicle-part-form-state';
import { VehiclePartStatusViewModel } from '../models/vehicle-part-status.viewmodel';

export type VehicleFormMode = 'create' | 'edit' | 'editDocuments' | 'view';

// As a good practice, do not store view models in the store.
// Store the entities and compose the view models in the selectors.
// Extra note: We can't store classes with methods (take the Date class as example),
// only simple objects so the best is to use simple interfaces/types to define the stored data
export interface VehiclePartsState {
  overview?: VehiclePartListViewModel;
  selected?: VehiclePartOverviewViewModel;
  formData?: VehiclePartFormData;
  formMode?: VehicleFormMode;
  formVehiclePartType?: VehiclePartType;
  vehiclePartsStatuses: VehiclePartStatusViewModel[];
  formState?: VehiclePartFormState;
  vehicleDocuments?: VehicleDocument[];
}

export interface VehiclesModuleState {
  initialized: boolean;
  transporters?: BusinessPartyViewModel[];
  countries?: SelectItem[];
  vehicleParts: VehiclePartsState;
}

export const INITIAL_STATE: VehiclesModuleState = {
  initialized: false,
  vehicleParts: {
    vehiclePartsStatuses: [],
  },
};

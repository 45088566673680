import { FlxMessageService } from '@fluxys/primeng';
import { MonoTypeOperatorFunction, Observable, of, catchError } from 'rxjs';

export function showToastOnError<T>(
  messageService: FlxMessageService,
  messageTranslationKey: string,
  valueOnError: () => T
): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) =>
    source.pipe(
      catchError(() => {
        messageService.showErrorToast({ key: messageTranslationKey });
        return of(valueOnError());
      })
    );
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FlxValidators } from '@fluxys/gsmart/validation';
import { TranslocoService } from '@ngneat/transloco';
import { DateTime } from 'luxon';
import { SelectItem, SortEvent } from 'primeng/api';

import { customSort } from '../../../shared/utils/custom-sort';
import { TrainingViewModel } from '../../models/view-models';

interface FormTraining {
  id: number;
  trainingTypeId: number;
  trainingCode: string;
  validityPeriodStartDate: string;
  validityPeriodEndDate: string;
}

@Component({
  selector: 'trkmgr-trainings-section',
  templateUrl: './trainings-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingsSectionComponent implements OnChanges {
  @Input() currentTrainings: readonly TrainingViewModel[] | undefined;
  @Input() trainingTypes: readonly SelectItem<number>[] = [];
  @Input() canEdit = false;
  @Output() readonly added = new EventEmitter<TrainingViewModel>();
  @Output() readonly removed = new EventEmitter<number>();
  @Output() readonly changed = new EventEmitter<TrainingViewModel>();

  readonly columns: { field: string; translation: string }[] = [
    { field: 'trainingCode', translation: 'app.modules.drivers.components.trainings-section.columns.training-code' },
    { field: 'validityPeriodStartDate', translation: 'app.modules.drivers.components.trainings-section.columns.valid-from' },
    { field: 'validityPeriodEndDate', translation: 'app.modules.drivers.components.trainings-section.columns.valid-to' },
  ];
  trainingTypeSelectItems: SelectItem<number>[] = [];
  rows: UntypedFormGroup[] = [];
  trainings: TrainingViewModel[] = [];
  editableRow = -1;

  constructor(private readonly fb: UntypedFormBuilder, private readonly translateService: TranslocoService) {}

  ngOnChanges(): void {
    this.createSelectItems();
    this.trainings = this.currentTrainings ? this.currentTrainings.map((entity) => ({ ...entity })) : [];
    this.createRows();
  }

  customSort(event: SortEvent): number {
    return customSort(event);
  }

  editClicked(id: number): void {
    if (this.editableRow !== -1) {
      this.cancelClicked(this.editableRow);
    }
    this.editableRow = id;
  }

  deleteClicked(id: number): void {
    this.removed.emit(id);
  }

  validateClicked(row: FormTraining): void {
    const rowForm = this.rows.find((r) => (r.value as FormTraining).id === row.id);
    if (rowForm?.valid) {
      this.editableRow = -1;

      const training = this.trainings.find((d) => d.id === row.id);
      if (training) {
        training.trainingTypeId = row.trainingTypeId;
        training.validityPeriodStartDate = row.validityPeriodStartDate;
        training.validityPeriodEndDate = row.validityPeriodEndDate;

        if (training.id === 0) {
          this.added.emit(training);
        } else {
          this.changed.emit(training);
        }
      }
    }
  }

  cancelClicked(id: number): void {
    if (id === 0) {
      this.trainings = this.trainings.filter((x) => x.id !== 0);
    }
    this.editableRow = -1;
    this.createRows();
  }

  addEntity(): void {
    const newDoc: TrainingViewModel = {
      id: 0,
      tcn: 0,
      trainingCode: this.trainingTypes[0].label ?? 'None',
      trainingTypeId: this.trainingTypes[0].value,
      validityPeriodStartDate: DateTime.fromJSDate(new Date()).toFormat('yyyyMMdd'),
      validityPeriodEndDate: DateTime.fromJSDate(new Date()).toFormat('yyyyMMdd'),
    };
    this.trainings.unshift(newDoc);
    this.createRows();
    this.editableRow = 0;
  }

  private createRows(): void {
    this.rows = [];
    this.trainings.forEach((tr) => {
      const row = this.fb.group(
        {
          id: this.fb.control(tr.id),
          trainingCode: this.fb.control(tr.trainingCode),
          trainingTypeId: this.fb.control(tr.trainingTypeId),
          validityPeriodStartDate: this.fb.control(tr.validityPeriodStartDate, Validators.required),
          validityPeriodEndDate: this.fb.control(tr.validityPeriodEndDate, Validators.required),
        },
        { validators: FlxValidators.startDateBeforeEndDate('validityPeriodStartDate', 'validityPeriodEndDate') }
      );
      this.rows.push(row);
    });
  }

  private createSelectItems(): void {
    this.trainingTypeSelectItems = this.trainingTypes.map((tt) => ({
      value: tt.value,
      label: this.translateService.translate(`app.modules.drivers.enums.trainingType.${tt.label}`),
    }));
  }
}

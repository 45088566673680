import { Pipe, PipeTransform } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Pipe({ name: 'orderByLabel' })
export class OrderByLabelPipe implements PipeTransform {
  transform<T>(items: SelectItem<T>[] | null | undefined, asc = true): SelectItem<T>[] | null | undefined {
    return items === null
      ? null
      : items?.sort((a, b) => {
          if (a.label === undefined || b.label === undefined) return 0;
          if (a.label < b.label) return asc ? -1 : 1;
          if (a.label > b.label) return asc ? 1 : -1;
          return 0;
        });
  }
}

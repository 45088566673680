<flx-loading-spinner [context]="context"></flx-loading-spinner>
<flx-rule-validation [context]="context" displayMode="dialog"></flx-rule-validation>

<!-- <div class="content pr-5 mr-n3"> -->
<!-- Begin driver details form -->
<div class="dialog-body">
  <trkmgr-driver-details-form
    [form]="form"
    [driver]="store.driver$ | async"
    mode="configure"
    [canEdit]="store.canEditDriver$ | async"
    [readonly]="true"
    [acceptedLanguages]="store.acceptedLanguages$ | async"
    (configureRequested)="configureDriver($event)"
    [context]="context"
  ></trkmgr-driver-details-form>
  <!-- End driver details form -->

  <hr />

  <!-- Begin Affiliations table -->
  <section>
    <div class="section-title" transloco="app.modules.drivers.components.consult-driver-dialog.section-titles.affiliated-to"></div>
    <div class="section-content">
      <trkmgr-affiliations-section
        [currentAffiliations]="store.affiliations$ | async"
        [canEdit]="(store.canEditAffiliations$ | async) ?? false"
        [transporters]="(store.transporters$ | async) ?? []"
        (added)="onAddAffiliation($event)"
        (changed)="onChangeAffiliation($event)"
        (removed)="onRemoveAffiliation($event)"
      ></trkmgr-affiliations-section>
    </div>
  </section>
  <!-- End Affiliations table -->
  <!-- Begin Approval details table -->
  <section>
    <div class="section-title" transloco="app.modules.drivers.components.consult-driver-dialog.section-titles.approval-details"></div>
    <div class="section-content">
      <trkmgr-approvals-section
        [currentApprovals]="store.approvals$ | async"
        [canEdit]="(store.canEditApprovals$ | async) ?? false"
        [canAuthorizeApproval]="(store.canAuthorizeApproval$ | async) ?? false"
        [accreditationStatuses]="accreditationStatuses"
        (changed)="onChangeApproval($event)"
      ></trkmgr-approvals-section>
    </div>
  </section>
  <!-- End Approval details table -->
  <!-- Begin Documents table -->
  <section>
    <div class="section-title" transloco="app.modules.drivers.components.consult-driver-dialog.section-titles.documents"></div>
    <div class="section-content">
      <trkmgr-documents-section
        [currentDocuments]="store.documents$ | async"
        [documentTypes]="documentTypes"
        [canEdit]="(store.canEditDocuments$ | async) ?? false"
        (added)="onAddDocument($event)"
        (changed)="onChangeDocument($event)"
        (removed)="onRemoveDocument($event)"
      ></trkmgr-documents-section>
    </div>
  </section>
  <!-- End Documents table -->
  <!-- Begin Training details table -->
  <section>
    <div class="section-title" transloco="app.modules.drivers.components.consult-driver-dialog.section-titles.training-details"></div>
    <div class="section-content">
      <trkmgr-trainings-section
        [currentTrainings]="(store.trainings$ | async) ?? []"
        [trainingTypes]="(store.trainingTypes$ | async) ?? []"
        [canEdit]="(store.canEditTrainings$ | async) ?? false"
        (added)="onAddTraining($event)"
        (changed)="onChangeTraining($event)"
        (removed)="onRemoveTraining($event)"
      ></trkmgr-trainings-section>
    </div>
  </section>
  <!-- End Training details table -->
</div>
<!-- </ng-template> -->

<div class="section-footer section-footer-in-content">
  <div class="section-footer__commands">
    <button
      pButton
      type="button"
      class="p-button-tertiary"
      [label]="'app.shared.buttons.close' | transloco"
      (click)="dialogRef.close()"
    ></button>
  </div>
</div>

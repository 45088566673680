import { Component, inject, OnInit } from '@angular/core';
import { FlxRuleValidationService } from '@fluxys/gsmart/rule-validation';
import { FlxMessageService } from '@fluxys/primeng';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OverviewTableFilter } from '@shared/components/overview-table';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { combineLatest, map, Observable } from 'rxjs';

import { ConsultDriverDialogComponent, CreateDriverDialogComponent } from '../../components';
import { DriverService, DriversManagerService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'trkmgr-drivers-overview',
  templateUrl: './drivers-overview.component.html',
  providers: [DriversManagerService],
})
export class DriversOverviewComponent implements OnInit {
  readonly tableCommands$: Observable<MenuItem[] | undefined>;
  readonly dropdownFilters$: Observable<OverviewTableFilter[]>;
  context = 'drivers-overview';

  private readonly ruleValidationService = inject(FlxRuleValidationService);

  constructor(
    private readonly dialogService: DialogService,
    private readonly driverService: DriverService,
    private readonly messageService: FlxMessageService,
    readonly store: DriversManagerService,
    private readonly translocoService: TranslocoService
  ) {
    this.tableCommands$ = this.store.flags$.pipe(
      map(({ canCreateDriver }) => {
        const commands = new Array<MenuItem>();
        if (canCreateDriver) {
          commands.push({
            label: 'app.shared.buttons.add',
            icon: 'fas fa-plus',
            command: () => this.onCreateDialogButtonClicked(),
          });
        }
        return commands;
      })
    );

    this.dropdownFilters$ = combineLatest([this.store.nameFilters$, this.store.statusFilters$]).pipe(
      map(([namefilters, statusFilters]) => [
        {
          field: 'name',
          label: 'app.modules.drivers.components.drivers-table-filters.from',
          options: namefilters,
          defaultLabel: 'app.modules.drivers.components.drivers-table-filters.choose',
        },
        {
          field: 'accreditationStatus',
          label: 'app.modules.drivers.components.drivers-table-filters.status',
          options: statusFilters,
          defaultLabel: 'app.modules.drivers.components.drivers-table-filters.choose',
        },
      ])
    );
  }

  ngOnInit(): void {
    this.store.loadDrivers();
  }

  onEditDriverRequest(id: number): void {
    const dialogRef = this.dialogService.open(ConsultDriverDialogComponent, {
      data: {
        driverId: id,
      },
      style: {
        minWidth: '1150px',
        maxWidth: '1600px',
        width: '1175px',
      },
      header: this.translocoService.translate('app.modules.drivers.components.consult-driver-dialog.title'),
    });
  }

  onExportDriversRequest(): void {
    this.store.exportDrivers(this.context).subscribe();
  }

  private onCreateDialogButtonClicked(): void {
    const dialogRef = this.dialogService.open(CreateDriverDialogComponent, {
      header: this.translocoService.translate('app.modules.drivers.components.create-driver-dialog.title'),
    });

    dialogRef.onClose.pipe(untilDestroyed(this)).subscribe((driverId?: number) => {
      if (driverId) {
        this.store.loadDrivers();
        this.onEditDriverRequest(driverId);
      }
    });
  }
}

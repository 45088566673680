import { createReducer, MetaReducer, on } from '@ngrx/store';

import { actions } from './acr.actions';
import { AcrModuleState, INITIAL_ACR_MODULE_STATE } from './acr.state';

const REDUCERS = createReducer<AcrModuleState>(
  INITIAL_ACR_MODULE_STATE,
  on(actions.acr.overview.dataload, () => INITIAL_ACR_MODULE_STATE),
  on(actions.acr.overview.loaded, (state, { type, ...loadedData }) => ({
    ...state,
    ...loadedData,
  }))
);

const META_REDUCERS: MetaReducer<AcrModuleState>[] = [];

export { REDUCERS as reducers, META_REDUCERS as metaReducers };

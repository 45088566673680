import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { mapToPayload } from '@core/models/http-error';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { FlxLoggerService } from '@fluxys/core';
import { hasErrors, isValidatedResult } from '@fluxys/gsmart/rule-validation';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationService } from 'primeng/api';
import { Observable, take } from 'rxjs';

import { AssignmentsRoutes } from '../../assignments-routes';
import { AcceptAssignmentRequestCommand } from '../../models/assignment-request-details/accept-assignment-request.command';
import { AssignmentDetailsViewModel } from '../../models/assignment-request-details/assignment-details.viewmodel';
import { DeclineAssignmentRequestCommand } from '../../models/assignment-request-details/decline-assignment-request.command';
import { DeleteAssignmentRequestCommand } from '../../models/assignment-request-details/delete-assignment-request.command';
import { AssignmentDetailsService } from '../../services/assignment-details.service';

@Component({
  selector: 'trkmgr-assignment-details',
  templateUrl: './assignment-details.component.html',
  styleUrls: ['./assignment-details.component.scss'],
})
export class AssignmentDetailsComponent {
  context = 'assignment-details';
  vm$: Observable<AssignmentDetailsViewModel>;

  assignmentRequestId: string;

  readonly _route: ActivatedRoute = inject(ActivatedRoute);
  readonly _router: Router = inject(Router);
  private readonly _errorHandlerService: ErrorHandlerService = inject(ErrorHandlerService);
  private readonly _confirmationService: ConfirmationService = inject(ConfirmationService);
  private readonly _translationService: TranslocoService = inject(TranslocoService);
  private readonly _assignmentDetailsService: AssignmentDetailsService = inject(AssignmentDetailsService);
  private readonly _loggerService: FlxLoggerService = inject(FlxLoggerService);

  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    this.assignmentRequestId = this._route.snapshot.params.id;
    this.vm$ = this._assignmentDetailsService.getAssignmentDetails(this.assignmentRequestId, this.context).pipe();
  }
  onAcceptRequestButtonClicked(): void {
    const command: AcceptAssignmentRequestCommand = {
      assignmentRequestId: this.assignmentRequestId,
    };

    this.confirm(
      'app.modules.assignments.components.assignmentrequest-details.confirmations.accept-header',
      'app.modules.assignments.components.assignmentrequest-details.confirmations.accept-message',
      'app.modules.assignments.shared.buttons.cancel',
      'app.shared.buttons.cancel',
      this.createAcceptCallbackWrapper(
        this._assignmentDetailsService.acceptAssignmentRequest(command, this.context),
        (error: HttpErrorResponse) => this.handleError(error, 'app.modules.assignments.shared.http-errors.accept-assignment-request'),
        (success) => {
          if (success) void this._router.navigate([AssignmentsRoutes.overviewRoute]);
        }
      )
    );
  }

  onDeclineRequestButtonClicked(): void {
    const command: DeclineAssignmentRequestCommand = {
      assignmentRequestId: this.assignmentRequestId,
    };

    this.confirm(
      'app.modules.assignments.components.assignmentrequest-details.confirmations.decline-header',
      'app.modules.assignments.components.assignmentrequest-details.confirmations.decline-message',
      'app.modules.assignments.shared.buttons.cancel',
      'app.shared.buttons.cancel',
      this.createAcceptCallbackWrapper(
        this._assignmentDetailsService.declineAssignmentRequest(command, this.context),
        (error: HttpErrorResponse) => this.handleError(error, 'app.modules.assignments.shared.http-errors.decline-assignment-request'),
        (success) => {
          if (success) void this._router.navigate([AssignmentsRoutes.overviewRoute]);
        }
      )
    );
  }

  onDeleteRequestButtonClicked(): void {
    const command: DeleteAssignmentRequestCommand = {
      assignmentRequestId: this.assignmentRequestId,
    };

    this.confirm(
      'app.modules.assignments.components.assignmentrequest-details.confirmations.delete-header',
      'app.modules.assignments.components.assignmentrequest-details.confirmations.delete-message',
      'app.modules.assignments.shared.buttons.cancel',
      'app.shared.buttons.cancel',
      this.createAcceptCallbackWrapper(
        this._assignmentDetailsService.deleteAssignmentRequest(command, this.context),
        (error: HttpErrorResponse) => this.handleError(error, 'app.modules.assignments.shared.http-errors.delete-assignment-request'),
        (success) => {
          if (success) void this._router.navigate([AssignmentsRoutes.overviewRoute]);
        }
      )
    );
  }

  onCancelButtonClicked(): void {
    void this._router.navigate([AssignmentsRoutes.overviewRoute]);
  }

  onValidationErrorDialogClosed(): void {
    void this._router.navigate([AssignmentsRoutes.overviewRoute]);
  }

  private confirm<T>(
    header: string,
    message: string,
    acceptLabel: string,
    rejectLabel: string,
    accept?: (value: T) => void,
    messageParams?: object
  ): void {
    this._confirmationService.confirm({
      header: this._translationService.translate(header),
      message: this._translationService.translate(message, messageParams),
      acceptLabel: this._translationService.translate(acceptLabel),
      rejectLabel: this._translationService.translate(rejectLabel),
      accept,
    });
  }

  private createAcceptCallbackWrapper(
    onConfirmCall$: Observable<unknown>,
    httpErrorCallback: (err: HttpErrorResponse) => void,
    resultCallback?: (success: boolean) => void
  ): () => void {
    return () => {
      onConfirmCall$.pipe(take(1)).subscribe({
        next: (result) => resultCallback?.(!isValidatedResult(result) || !hasErrors(result)),
        error: (error: unknown) => {
          if (error instanceof HttpErrorResponse) httpErrorCallback(error);
          else this._loggerService.error('unknown error', error);
          resultCallback?.(false);
        },
      });
    };
  }

  private handleError(error: HttpErrorResponse, errorMsgPath: string): void {
    this._errorHandlerService.handleHttpErrorResponse(mapToPayload(error), errorMsgPath);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TM_HAS_PERMISSION_CAN_ACTIVATE_FN } from 'src/app/guards/truckmanager-authorization.guard';

import { AcrOverviewComponent } from './pages';

const ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'overview' },
  {
    path: 'overview',
    component: AcrOverviewComponent,
    data: {
      permissions: ['auto-confirmation-consult'],
    },
    canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class AcrRoutingModule {}

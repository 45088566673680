import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TM_HAS_PERMISSION_CAN_ACTIVATE_FN } from 'src/app/guards/truckmanager-authorization.guard';

import { VehiclesOverviewComponent } from './pages/overview/vehicles-overview.component';
import { InitializeVehiclesGuard } from './services/init-vehicles.guard';

const ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'overview' },
  {
    path: 'overview',
    component: VehiclesOverviewComponent,
    data: {
      permissions: ['vehicle-consult'],
    },
    canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN, InitializeVehiclesGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  providers: [InitializeVehiclesGuard],
  exports: [RouterModule],
})
export class VehiclesRoutingModule {}

<p-card #detailscontent *transloco="let t; read: baseTranslationPath">
  <flx-loading-spinner [context]="context" [target]="detailscontent"></flx-loading-spinner>

  <form [formGroup]="form" novalidate autocomplete="off" class="pr-3 form">
    <section>
      <!-- hidden fields so the user can't easily edit them -->
      <input type="hidden" id="assignorBusinessPartyId" formControlName="assignorBusinessPartyId" />
      <input type="hidden" id="assignorSourceAgreementId" formControlName="assignorSourceAgreementId" />

      <div class="section-title">{{ t('header.title') }}</div>
      <div class="section-content row no-gutters">
        <div class="col-6">
          <flx-form-field-wrapper [label]="baseTranslationPath + '.header.assignor-label'" class="mb-3">
            <span class="d-form-control">{{ model?.assignorBusinessPartyName }}</span>
          </flx-form-field-wrapper>
          <flx-form-field-wrapper [label]="baseTranslationPath + '.header.source-agreement-label'">
            <span class="d-form-control" *ngIf="this.model"
              >{{ getBpDefaultAgreement(model.assignorBusinessPartyId, model.sourceAgreements).label }}</span
            >
          </flx-form-field-wrapper>
        </div>
        <div class="col-6">
          <flx-form-field-wrapper [label]="baseTranslationPath + '.header.assignee-label'" class="mb-3" [required]="true">
            <p-dropdown
              [options]="model?.assigneeBusinessParties ?? []"
              formControlName="assigneeBusinessPartyId"
              [filter]="true"
              class="w-100"
            ></p-dropdown>
          </flx-form-field-wrapper>
          <flx-form-field-wrapper [label]="baseTranslationPath + '.header.target-agreement-label'">
            <p-dropdown
              [options]="assigneeTargetAgreements"
              formControlName="assigneeTargetAgreementId"
              class="w-100"
              [disabled]="true"
            ></p-dropdown>
          </flx-form-field-wrapper>
        </div>
      </div>
    </section>
    <hr />
    <section>
      <div class="section-title">{{ t('body.title') }}</div>
      <div class="section-content row no-gutters">
        <div class="col">
          <flx-form-field-wrapper [label]="t('body.date-label')" class="mb-4 w-50" [required]="true">
            <flx-date-picker formControlName="slotDate" [fluidWidth]="true"></flx-date-picker>
          </flx-form-field-wrapper>

          <p-table
            [value]="appointments"
            sortField="name"
            [reorderableColumns]="true"
            tableStyleClass="flx-table"
            *transloco="let t;
                  read: 'app.modules.assignments.components.shared'"
          >
            <ng-template pTemplate="header">
              <tr>
                <th></th>
                <th pReorderableColumn pSortableColumn="slotStart">
                  <div class="d-flex align-items-center justify-content-between">
                    {{ t('headers.date-time') }}
                    <p-sortIcon field="slotStart"></p-sortIcon>
                    <p-columnFilter type="text" field="slotStart" display="menu" class="ml-auto"></p-columnFilter>
                  </div>
                </th>
                <th pReorderableColumn pSortableColumn="reference">
                  <div class="d-flex align-items-center justify-content-between">
                    {{ t('headers.reference') }}
                    <p-sortIcon field="reference"></p-sortIcon>
                    <p-columnFilter type="text" field="reference" display="menu" class="ml-auto"></p-columnFilter>
                  </div>
                </th>
                <th pReorderableColumn pSortableColumn="pricePerSlot">
                  <div class="d-flex align-items-center justify-content-between">
                    {{ t('headers.price') }}
                    <p-sortIcon field="pricePerSlot"></p-sortIcon>
                    <p-columnFilter type="text" field="pricePerSlot" display="menu" class="ml-auto"></p-columnFilter>
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-appointment>
              <tr class="u-cursor-pointer">
                <td>
                  <p-radioButton name="appointmentId" [value]="appointment.appointmentId" formControlName="appointmentId"></p-radioButton>
                </td>
                <td>{{ (appointment.slotStart | toDate | date: 'dd.MM.yyyy - HH:mm') ?? '-' }}</td>
                <td>{{ appointment.reference }}</td>
                <td>{{ appointment.pricePerSlot ?? 'Standard Price' }}</td>
              </tr>
            </ng-template>
          </p-table>

          <flx-form-field-wrapper [label]="t('body.assignment-type-label')" class="w-50 mt-4" [required]="true">
            <p-dropdown
              [options]="(model?.assignmentTypeEnumValues ?? []) | enumValueToTranslatedItem: 'app.modules.assignments.components.shared.enums.assignment-type'"
              formControlName="assignmentType"
              [autoDisplayFirst]="false"
              [placeholder]="t('body.assignment-type-placeholder')"
              class="w-100"
            ></p-dropdown>
          </flx-form-field-wrapper>
        </div>
      </div>
    </section>
  </form>
</p-card>

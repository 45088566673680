<ng-container *transloco="let t; read: 'app.modules.assignments.pages.assignment-details'">
  <section class="form" *ngIf="vm">
    <span class="section-title"> {{ t('appointment.title') }} </span>
    <div class="section-content">
      <div class="d-flex">
        <flx-form-field-wrapper [label]="t('appointment.request-reference')" ngClass="w-50 mr-2"
          >{{vm.assignmentRequestReference}}</flx-form-field-wrapper
        >

        <flx-form-field-wrapper [label]="t('appointment.service-reference')" ngClass="w-50 ml-2"
          >{{vm.assignmentReference ?? "-"}}</flx-form-field-wrapper
        >
      </div>

      <div class="d-flex mt-4">
        <flx-form-field-wrapper
          label="app.modules.assignments.pages.assignment-details.slot.assignor-contract-reference"
          ngClass="w-50 mr-2"
          >{{vm.assignorContractReference ?? "-"}}</flx-form-field-wrapper
        >
        <flx-form-field-wrapper
          label="app.modules.assignments.pages.assignment-details.slot.assignee-contract-reference"
          ngClass="w-50 ml-2"
          >{{vm.assigneeContractReference ?? "-"}}</flx-form-field-wrapper
        >
      </div>

      <div class="d-flex mt-4">
        <flx-form-field-wrapper [label]="t('appointment.appointment-start')" ngClass="w-50 mr-2"
          >{{vm.appointmentStart | toDate | date: 'dd.MM.yyyy - HH:mm'}}</flx-form-field-wrapper
        >

        <flx-form-field-wrapper *ngIf="vm.pricePerSlot.shouldDisplayPrice" [label]="t('appointment.price-per-slot')" ngClass="w-50 ml-2"
          >{{vm.pricePerSlot.price | toStandardPrice }}</flx-form-field-wrapper
        >
      </div>

      <div class="d-flex mt-4">
        <flx-form-field-wrapper [label]="t('appointment.operational-period')" ngClass="w-50 mr-2"
          >{{vm.operationalPeriodBeginDate | toDate |date:'dd.MM.yyyy'}} - {{vm.operationalPeriodEndDate | toDate
          |date:'dd.MM.yyyy'}}</flx-form-field-wrapper
        >

        <flx-form-field-wrapper [label]="t('appointment.type-of-assignment')" ngClass="w-50 ml-2">
          {{ ("app.modules.assignments.components.shared.enums.assignment-type." + vm.typeOfAssignment) |
          transloco}}</flx-form-field-wrapper
        >
      </div>
    </div>
  </section></ng-container
>

import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { interval, of, catchError, filter, map, switchMap, withLatestFrom } from 'rxjs';

import { mapToPayload } from '../../models/http-error';
import { UserService } from '../../services/user.service';
import { selectors } from '../selectors';
import { AppState } from '../state';

import * as userActions from './user.actions';

const RETRY_REFRESH_DELAY = 10 * 1000; // 10 seconds
const REFRESH_DELAY = 5 * 60 * 1000; // 5 minutes

@Injectable()
export class UserEffects {
  /**
   * Normally a first `loadUserPermissions` action is dispatched from `CustomUserInfoService.getUserInfo()`
   * when we have a puma configuration, and this effect is supposed to retrieve the user permissions each 5
   * minutes after that.
   */
  readonly reloadPermissionsTimer$ = createEffect(() =>
    interval(RETRY_REFRESH_DELAY).pipe(
      withLatestFrom(this.store.select(selectors.user)),
      filter(([, { lastRefresh }]) => Date.now() - lastRefresh > REFRESH_DELAY),
      map(() => userActions.loadUserPermissions())
    )
  );

  readonly loadPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loadUserPermissions),
      switchMap(() =>
        this.userService.loadUserInfo().pipe(
          map((dto) => userActions.userPermissionsLoaded(dto)),
          catchError((error: HttpErrorResponse) => of(userActions.userPermissionsFailed(mapToPayload(error))))
        )
      )
    )
  );

  private readonly userService: UserService = inject(UserService);

  constructor(private readonly actions$: Actions, private readonly store: Store<AppState>) {}
}

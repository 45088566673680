import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlxDatePickerModule } from '@fluxys/gsmart/date-picker';
import { FlxFormFieldWrapperModule } from '@fluxys/gsmart/form-field-wrapper';
import { FlxRuleValidationModule } from '@fluxys/gsmart/rule-validation';
import { FlxValidationModule } from '@fluxys/gsmart/validation';
import { FlxPrimengModule } from '@fluxys/primeng';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { Psm5CommonModule } from 'psm5-web';

import { SharedModule } from '../shared/shared.module';

import { COMPONENTS, DIALOGS } from './components';
import { DriverRoutingModule } from './drivers.routes';
import { PAGES } from './pages';
import { SERVICES } from './services';

const NG_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];
const PRIME_NG_MODULES = [ButtonModule, DialogModule, DropdownModule, InputTextModule, MultiSelectModule, TableModule, DialogModule];
const FLX_MODULES = [FlxDatePickerModule, FlxFormFieldWrapperModule, FlxPrimengModule, FlxValidationModule, FlxRuleValidationModule];
const PSM5_MODULES = [Psm5CommonModule];
const TM_MODULES = [SharedModule, DriverRoutingModule];

@NgModule({
  declarations: [COMPONENTS, DIALOGS, PAGES],
  imports: [FLX_MODULES, NG_MODULES, PRIME_NG_MODULES, PSM5_MODULES, TM_MODULES],
  providers: [SERVICES],
})
export class DriversModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserInfoViewModel } from '../models/user-info.viewmodel';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private readonly _http: HttpClient) {}

  loadUserInfo(): Observable<UserInfoViewModel> {
    return this._http.get<UserInfoViewModel>('/api/user');
  }
}

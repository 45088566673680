<p-table
  [value]="rows"
  [sortField]="columns[0].field"
  [customSort]="true"
  dataKey="id"
  editMode="row"
  (sortFunction)="customSort($event)"
  styleClass="p-datatable-gridlines"
  tableStyleClass="table-column-options-narrow"
>
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let column of columns" [pSortableColumn]="column.field">
        {{ column.translation | transloco }}
        <p-sortIcon [field]="column.field"></p-sortIcon>
      </th>
      <th>&nbsp;</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-row>
    <tr [formGroup]="row" [pEditableRow]="row">
      <td>
        <p-dropdown
          *ngIf="editableRow === row.get('id').value"
          [options]="transporters"
          formControlName="transporterBusinessPartyRoleId"
          [filter]="true"
          [showClear]="true"
          appendTo="body"
          styleClass="w-100"
        ></p-dropdown>
        <div *ngIf="editableRow !== row.get('id').value">{{getTransporterName(row.get('transporterBusinessPartyRoleId')?.value)}}</div>
      </td>
      <td>
        <flx-date-picker
          *ngIf="editableRow === row.get('id').value"
          formControlName="operationalPeriodStartDate"
          [showEot]="true"
          [required]="true"
          dateType="string"
          [fluidWidth]="true"
        ></flx-date-picker>
        <flx-validation-messages for="operationalPeriodStartDate"></flx-validation-messages>
        <div *ngIf="editableRow !== row.get('id').value">
          {{row.get('operationalPeriodStartDate')?.value | toDate: 'yyyyMMdd' | date: 'dd.MM.yyyy'}}
        </div>
      </td>
      <td>
        <flx-date-picker
          *ngIf="editableRow === row.get('id').value"
          formControlName="operationalPeriodEndDate"
          inputId="operationalPeriodEndDate"
          [showEot]="true"
          [required]="true"
          dateType="string"
          [fluidWidth]="true"
        ></flx-date-picker>
        <flx-validation-messages for="operationalPeriodEndDate"></flx-validation-messages>
        <flx-validation-messages></flx-validation-messages>
        <div *ngIf="editableRow !== row.get('id').value">
          {{row.get('operationalPeriodEndDate')?.value | toDate: 'yyyyMMdd' | date: 'dd.MM.yyyy'}}
        </div>
      </td>
      <td style="width: 13%">
        <trkmgr-driver-table-controls
          [edit]="editableRow === row.get('id').value"
          [canEdit]="canEdit"
          [canDelete]="canEdit"
          (editClicked)="editClicked(row.get('id').value)"
          (deleteClicked)="deleteClicked(row.get('id').value)"
          (validate)="validateClicked(row.value)"
          (cancel)="cancelClicked(row.get('id').value)"
        ></trkmgr-driver-table-controls>
      </td>
    </tr>
  </ng-template>
</p-table>

<div *ngIf="canEdit" class="text-right pr-1 pt-2">
  <p-button (click)="addEntity()" styleClass="p-button-secondary" [label]="'app.shared.buttons.add' | transloco"></p-button>
</div>

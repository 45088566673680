<p-dialog [modal]="true" [visible]="true" (visibleChange)="cancelClicked.emit()">
  <p-header [transloco]="title"></p-header>

  <form [formGroup]="form">
    <flx-form-field-wrapper
      [label]="'app.modules.vehicles.components.vehicle-dialog.document-section.columns.type' | transloco"
      inputId="vehiclePartType"
      [required]="true"
      class="p-2"
    >
      <p-dropdown
        id="vehiclePartType"
        [options]="typeSelectItems"
        formControlName="type"
        [required]="true"
        appendTo="body"
        styleClass="w-100"
      ></p-dropdown>
      <flx-validation-messages for="type"></flx-validation-messages>
    </flx-form-field-wrapper>
    <flx-form-field-wrapper
      [label]="'app.modules.vehicles.components.vehicle-dialog.document-section.columns.valid-from' | transloco"
      inputId="validFrom"
      [required]="true"
      class="p-2"
    >
      <flx-date-picker
        id="validFrom"
        formControlName="validFrom"
        [showEot]="true"
        [required]="true"
        dateType="string"
        [fluidWidth]="true"
      ></flx-date-picker>
      <flx-validation-messages for="validFrom"></flx-validation-messages>
    </flx-form-field-wrapper>
    <flx-form-field-wrapper
      [label]="'app.modules.vehicles.components.vehicle-dialog.document-section.columns.valid-to' | transloco"
      inputId="validTo"
      [required]="true"
      class="p-2"
    >
      <flx-date-picker
        formControlName="validTo"
        id="validTo"
        [showEot]="true"
        [required]="true"
        dateType="string"
        [fluidWidth]="true"
      ></flx-date-picker>
      <flx-validation-messages for="validTo"></flx-validation-messages>
      <flx-validation-messages></flx-validation-messages>
    </flx-form-field-wrapper>

    <flx-form-field-wrapper
      [label]="'app.modules.vehicles.components.vehicle-dialog.document-section.columns.document' | transloco"
      inputId="document"
      [required]="true"
      class="p-2"
    >
      <div>{{form.get('documentName')?.value}}</div>
      <p-fileUpload
        mode="basic"
        [chooseLabel]="'app.modules.vehicles.components.vehicle-dialog.document-section.label-upload' | transloco"
        name="document"
        accept=".pdf"
        [maxFileSize]="10000000"
        (onSelect)="addFile($event.currentFiles[0])"
      ></p-fileUpload>
      <flx-validation-messages for="document"></flx-validation-messages>
    </flx-form-field-wrapper>
  </form>

  <p-footer>
    <button
      type="button"
      pButton
      class="p-button-secondary"
      [label]="'app.shared.buttons.cancel' | transloco"
      (click)="cancelClicked.emit()"
    ></button>
    <button type="button" pButton [label]="'app.shared.buttons.ok' | transloco" [disabled]="form.invalid" (click)="okClicked()"></button>
  </p-footer>
</p-dialog>

import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { VehiclePartParametersViewModel } from 'src/app/vehicles/models';

@Component({
  selector: 'trkmgr-vehicle-information-section',
  templateUrl: './vehicle-information-section.component.html',
  styleUrls: ['../../vehicle-part-dialog.component.scss'],
})
export class VehicleInformationSectionComponent {
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() transporters: SelectItem<number>[] | null = [];
  @Input() statuses: SelectItem[] | null = [];
  @Input() displayExpiryDate: boolean | null = false;
  @Input() vehiclePartParams: VehiclePartParametersViewModel | null = null;
  currentDate: Date = new Date();
  dialogLink = 'app.modules.vehicles.components.vehicle-dialog.';
}

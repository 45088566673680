import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AffiliatePartyViewModel, UserInfoViewModel } from '@core/models/user-info.viewmodel';
import { UserService } from '@core/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ImpersonationService } from 'psm5-web';
import { timer } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'trkmgr-impersonation',
  templateUrl: './impersonation.component.html',
  styleUrls: ['./impersonation.component.scss'],
})
export class ImpersonationComponent {
  userInfo: UserInfoViewModel | undefined;
  selectedAffiliate: AffiliatePartyViewModel | undefined;

  constructor(
    readonly userService: UserService,
    readonly router: Router,
    readonly impersonationService: ImpersonationService,
    readonly iodc: OidcSecurityService
  ) {
    userService
      .loadUserInfo()
      .pipe(untilDestroyed(this))
      .subscribe((p) => {
        this.userInfo = p;
        if (!this.userInfo.hasMoreThenOneAffiliate) {
          timer(15000)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
              this.iodc.logoff().pipe(untilDestroyed(this)).subscribe();
            });
        }
      });
  }

  display(name: string): string {
    return name.length > 50 ? `${name.substring(0, 47)}...` : name;
  }

  impersonate(): void {
    if (this.selectedAffiliate) {
      this.impersonationService.impersonate(this.selectedAffiliate.id, this.selectedAffiliate.name);
      void this.router.navigate(['calendar/slots']);
    }
  }
}

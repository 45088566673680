<!-- eslint-disable @angular-eslint/template/accessibility-label-has-associated-control -->
<body class="c-impersonation-container" *transloco="let t">
  <div class="c-impersonation-truckmanager">
    <div class="c-impersonation-title">
      <img src="/assets/icon/logo-flbe.svg" alt="Logo" height="50px" />
      <div class="c-impersonation-welcome">
        {{t('impersonation.welcome')}}
        <div class="c-impersonation-doublebars">
          <svg width="80" height="25" viewBox="0 0 80 25" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="80" height="10" rx="5" fill="currentColor"></rect>
            <rect x="0" y="15" width="80" height="10" rx="5" fill="currentColor"></rect>
          </svg>
        </div>
      </div>
    </div>
  </div>

  <div class="c-impersonation-login">
    <div class="c-impersonation-login-form" *ngIf="userInfo && userInfo.hasMoreThenOneAffiliate">
      <img src="/assets/img/fluxys-logo-black.png" alt="Logo" class="c-impersonation-login-logo" />
      <label for="affiliatedParties">{{t('impersonation.selectBusinessParty') }}</label>

      <p-dropdown
        *ngIf="userInfo.affiliatedParties"
        [lazy]="true"
        id="affiliatedParties"
        [(ngModel)]="selectedAffiliate"
        placeholder="{{t('impersonation.selectBusinessParty')}}"
        styleClass="w-100"
        [options]="userInfo.affiliatedParties"
        optionLabel="name"
      >
        <ng-template let-item pTemplate="item"><div [title]="item.name">{{ display(item.name) }}</div></ng-template>
      </p-dropdown>
      <button
        [disabled]="selectedAffiliate===undefined"
        [ngClass]="{'c-impersonation-continue-disabled':selectedAffiliate ===undefined}"
        class="c-impersonation-login-form-continue"
        type="submit"
        name="provider"
        value="SHIPPERS"
        (click)="impersonate()"
      >
        <span>{{t('impersonation.continue')}}</span>
      </button>
    </div>

    <div class="c-impersonation-login-form" *ngIf="!userInfo || (userInfo && !userInfo.hasMoreThenOneAffiliate)">
      <label class="c-impersonation-login-form-no-permission">{{t('impersonation.noPermissions') }}</label>
    </div>
  </div>
</body>

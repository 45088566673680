import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withLoadingSpinner } from '@fluxys/primeng';
import { Observable } from 'rxjs';

import { VehiclePartParametersViewModel } from '../models';

@Injectable()
export class VehiclePartParametersService {
  constructor(private readonly _http: HttpClient) {}

  getVehiclePartBusinessParameters(): Observable<VehiclePartParametersViewModel> {
    return this._http.get<VehiclePartParametersViewModel>(`/api/vehicles/vehiclepartparams`, {
      context: withLoadingSpinner('vehicle-part-dialog'),
    });
  }
}

<div>
  <div *ngIf="!edit">
    <p-button *ngIf="canEdit" (click)="editClicked.emit();" styleClass="p-button-tertiary" icon="fas fa-pencil-alt"></p-button>
    <p-button *ngIf="canDelete" (click)="deleteClicked.emit()" styleClass="p-button-tertiary" icon="fas fa-trash"></p-button>
  </div>
  <div *ngIf="edit">
    <p-button pSaveEditableRow (click)="validate.emit();" styleClass="p-button-tertiary" icon="fas fa-check text-success"></p-button>
    <p-button pCancelEditableRow (click)="cancel.emit();" styleClass="p-button-tertiary" icon="fas fa-times text-danger"></p-button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { FlxLoggerService } from '@fluxys/core';
import { FlxMessageService } from '@fluxys/primeng';

import { AutomationHandler } from './automation.handler';

@Injectable({ providedIn: 'root' })
export class AutomationDispatcher {
  private readonly registeredHandlers: Partial<Record<string, AutomationHandler>> = {};

  constructor(private readonly messageService: FlxMessageService, private readonly logger: FlxLoggerService) {}

  scan(route: ActivatedRoute): void {
    const { queryParams } = route.snapshot;
    const { mode, action } = queryParams;

    if (mode === 'automation' && typeof action === 'string') {
      this.logger.info('automation mode detected.');

      const automationHandler = this.registeredHandlers[action.toLowerCase()];

      if (automationHandler) {
        const currentRoute = this.getRouteParts(route.snapshot).join('/');
        if (!automationHandler.activationRoutes.includes(currentRoute)) {
          this.logger.warn(`automation handler ${automationHandler.actionName} found. but wrong route.`);
        }

        this.logger.info(`automation handler ${automationHandler.actionName} found. executing handdler.`);

        automationHandler.execute(queryParams);
        return;
      }

      this.messageService.showWarningToast({
        key: `no automation action with action name ${action} could be found.`,
        isTranslated: true,
      });
    }
  }

  registerHandler(automationHandler: AutomationHandler): void {
    this.registeredHandlers[automationHandler.actionName.toLowerCase()] = automationHandler;
  }

  private getRouteParts(routeSnapshot: ActivatedRouteSnapshot): string[] {
    const result = new Array<string>();

    if (routeSnapshot.url.length > 0) {
      const urlSegment = routeSnapshot.url[0];
      if (urlSegment.path) {
        result.push(urlSegment.path);
      }
    }

    if (routeSnapshot.children.length > 0) {
      return [...result, ...this.getRouteParts(routeSnapshot.children[0])];
    }

    return result;
  }
}

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { FeatureFlagsService } from '@core/services/feature-flags.service';
import { FlxLoadingSpinnerComponent } from '@fluxys/primeng';
import { TranslocoService } from '@ngneat/transloco';
import { PrimeNGConfig } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';

import { AppInitializerHelper } from './app-initializer.helper';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AssignmentsModule } from './assignments/assignments.module';
import { AutoConfirmationModule } from './autoconfirmation/autoconfirmation.module';
import { DriversModule } from './drivers/drivers.module';
import { ImpersonationComponent } from './impersonation/impersonation.component';
import { VehiclesModule } from './vehicles/vehicles.module';
import { DocumentsModule } from './vehicle-documents/documents.module';

@NgModule({
  declarations: [AppComponent, ImpersonationComponent],
  imports: [
    AppRoutingModule,
    AssignmentsModule,
    AutoConfirmationModule,
    BrowserAnimationsModule,
    CalendarModule,
    CoreModule,
    DriversModule,
    DropdownModule,
    FlxLoadingSpinnerComponent,
    FormsModule,
    ReactiveFormsModule,
    VehiclesModule,
    DocumentsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerHelper.createFactory,
      deps: [FeatureFlagsService, PrimeNGConfig, TranslocoService],
      multi: true,
    },
  ],
})
export class AppModule {}

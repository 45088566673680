import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class TruckValidators {
  static requiredNonZero(control: AbstractControl): ValidationErrors | null {
    if (control instanceof UntypedFormControl) {
      if (control.value === null || control.value === undefined) return null;

      if (+control.value === 0) {
        return { requiredNonZero: true };
      }
    }

    return null;
  }

  static validQuantity(minIncl: number, maxIncl: number): (control: AbstractControl) => ValidationErrors | null {
    return (control) => {
      if (control instanceof UntypedFormControl) {
        if (typeof control.value !== 'string' || control.value.toString().trim() === '') {
          return null;
        }

        if (!/^\d+(\.\d+)?$/.test(control.value) || isNaN(+control.value)) {
          return { invalidQuantity: true };
        }

        const numValue = +control.value;
        if (numValue < minIncl) {
          return { quantityTooSmall: true };
        } else if (numValue > maxIncl) {
          return { quantityTooLarge: true };
        }
      }

      return null;
    };
  }

  static maxLicensePlateCharactersLength(control: AbstractControl): ValidationErrors | null {
    if (control instanceof UntypedFormControl && typeof control.value === 'string') {
      const value = control.value.replace(/[^0-9a-z]/gi, '');

      if (value.length > 10) {
        return { maxLicensePlateCharactersLength: true };
      }
    }

    return null;
  }

  static maxReferenceCodeCharactersLength(control: AbstractControl): ValidationErrors | null {
    if (control instanceof UntypedFormControl && typeof control.value === 'string') {
      const value = control.value.replace(/[^0-9a-z]/gi, '');

      if (value.length > 20) {
        return { maxReferenceCodeCharactersLength: true };
      }
    }

    return null;
  }

  static inDateRange(minDate: Date, maxDate: Date): (control: AbstractControl) => ValidationErrors | null {
    return (control) => {
      if (!(control instanceof UntypedFormControl) || control.value === null || control.value === undefined) return null;
      if (control.value < minDate) return { dateBeforeRange: true };
      if (control.value > maxDate) return { dateAfterRange: true };
      return null;
    };
  }

  static atLeastOne(validator: ValidatorFn): ValidatorFn {
    return (control) => {
      const hasAtLeastOne =
        control instanceof UntypedFormGroup && Object.keys(control.controls).some((k) => !validator(control.controls[k]));
      return hasAtLeastOne ? null : { atLeastOne: true };
    };
  }

  // TODO not sure but there is maybe another validator in @fluxys/angular that does the same
  // probably check 'startDateBeforeEndDate' validator
  static startDateBeforeOrEqualEndDateValidator(
    startString: string,
    endString: string
  ): (control: AbstractControl) => ValidationErrors | null {
    return (control) => {
      if (control.value === null || control.value === undefined || !(control instanceof UntypedFormGroup)) return null;

      const start = control.get(startString);
      if (!start || !(start instanceof UntypedFormControl) || !start.value) return null;

      const end = control.get(endString);
      if (!end || !(end instanceof UntypedFormControl) || !end.value) {
        return null;
      }

      const startingValue = start.value as Date;
      const endingValue = end.value as Date;

      if (endingValue < startingValue) {
        return { startAfterEnd: true };
      }

      return null;
    };
  }
}

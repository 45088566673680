import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FlxValidators } from '@fluxys/gsmart/validation';
import { TranslocoService } from '@ngneat/transloco';
import { customSort } from '@shared/utils/custom-sort';
import { DateTime } from 'luxon';
import { SelectItem, SortEvent } from 'primeng/api';

import { DocumentType, DocumentViewModel } from '../../models/view-models';

interface FormDocument {
  id: number;
  documentType: string;
  reference: string;
  validityPeriodStartDate: string;
  validityPeriodEndDate: string;
}

@Component({
  selector: 'trkmgr-documents-section',
  templateUrl: './documents-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsSectionComponent implements OnChanges {
  @Input() currentDocuments: readonly DocumentViewModel[] | null = null;
  @Input() canEdit = false;
  @Input() documentTypes: SelectItem<DocumentType>[] = [];

  @Output() readonly changed = new EventEmitter<DocumentViewModel>();
  @Output() readonly removed = new EventEmitter<number>();
  @Output() readonly added = new EventEmitter<DocumentViewModel>();

  readonly columns: { field: string; translation: string }[] = [
    { field: 'documentType', translation: 'app.modules.drivers.components.document-section.columns.documentType' },
    { field: 'reference', translation: 'app.modules.drivers.components.document-section.columns.reference' },
    { field: 'validityPeriodStartDate', translation: 'app.modules.drivers.components.document-section.columns.valid-from' },
    { field: 'validityPeriodEndDate', translation: 'app.modules.drivers.components.document-section.columns.valid-to' },
  ];
  documentTypeSelectItems: SelectItem<string>[] = [];
  rows: UntypedFormGroup[] = [];
  documents: DocumentViewModel[] = [];
  editableRow = -1;

  constructor(private readonly fb: UntypedFormBuilder, private readonly translateService: TranslocoService) {}

  ngOnChanges(): void {
    this.createSelectItems();
    this.documents = this.currentDocuments ? this.currentDocuments.map((entity) => ({ ...entity })) : [];
    this.createRows();
  }

  customSort(event: SortEvent): number {
    return customSort(event);
  }

  editClicked(id: number): void {
    if (this.editableRow !== -1) {
      this.cancelClicked(this.editableRow);
    }
    this.editableRow = id;
  }

  deleteClicked(id: number): void {
    this.removed.emit(id);
  }

  validateClicked(row: FormDocument): void {
    const rowForm = this.rows.find((r) => (r.value as FormDocument).id === row.id);
    if (rowForm?.valid) {
      this.editableRow = -1;

      const document = this.documents.find((d) => d.id === row.id);
      if (document) {
        document.documentType = row.documentType;
        document.reference = row.reference;
        document.validityPeriodStartDate = row.validityPeriodStartDate;
        document.validityPeriodEndDate = row.validityPeriodEndDate;

        if (document.id === 0) {
          this.added.emit(document);
        } else {
          this.changed.emit(document);
        }
      }
    }
  }

  cancelClicked(id: number): void {
    if (id === 0) {
      this.documents = this.documents.filter((x) => x.id !== 0);
    }
    this.editableRow = -1;
    this.createRows();
  }

  addEntity(): void {
    const newDoc: DocumentViewModel = {
      id: 0,
      tcn: 0,
      documentType: this.documentTypeSelectItems[0].value,
      reference: '',
      validityPeriodStartDate: DateTime.fromJSDate(new Date()).toFormat('yyyyMMdd'),
      validityPeriodEndDate: DateTime.fromJSDate(new Date()).toFormat('yyyyMMdd'),
    };
    this.documents.unshift(newDoc);
    this.createRows();
    this.editableRow = 0;
  }

  private createRows(): void {
    this.rows = [];
    this.documents.forEach((doc) => {
      const row = this.fb.group(
        {
          id: this.fb.control(doc.id),
          documentType: this.fb.control(doc.documentType),
          reference: this.fb.control(doc.reference),
          validityPeriodStartDate: this.fb.control(doc.validityPeriodStartDate, Validators.required),
          validityPeriodEndDate: this.fb.control(doc.validityPeriodEndDate, Validators.required),
        },
        { validators: FlxValidators.startDateBeforeEndDate('validityPeriodStartDate', 'validityPeriodEndDate') }
      );
      this.rows.push(row);
    });
  }

  private createSelectItems(): void {
    this.documentTypeSelectItems = this.documentTypes.map((dt) => ({
      value: dt.value,
      label: this.translateService.translate(`app.modules.drivers.enums.documentType.${dt.value}`),
    }));
  }
}

export interface UserState {
  lastRefresh: number;
  name: string;
  roles: string[];
  permissions: string[];
  effectivePermissions: string[];
  businessPartyId: number | null;
  businessPartyName: string | null;
  canImpersonate: boolean;
  loaded: boolean;
  success: boolean;
}

export const INITIAL_STATE: UserState = {
  lastRefresh: 0,
  name: 'Anonymous',
  roles: [],
  permissions: [],
  effectivePermissions: [],
  businessPartyId: null,
  businessPartyName: null,
  canImpersonate: false,
  loaded: false,
  success: false,
};

<flx-loading-spinner [context]="'assignments-overview'"></flx-loading-spinner>

<div class="container-fluid" *transloco="let t; read: 'app.modules.assignments.pages.overview'">
  <div *ngIf="filteredModel$ | async; let filteredModel">
    <div class="row justify-content-between">
      <div class="col">
        <h1 class="flx-header--title">{{ t('title') }}</h1>
      </div>
      <div class="col-auto">
        <label for="yearsFilter" class="mr-3">{{ t('filter-by-year-label') }}</label>
        <p-dropdown
          id="yearsFilter"
          [options]="filteredModel.years"
          placeholder="{{
          'app.modules.about.components.status.business-parties-dropdown-placeholder' | transloco
        }}"
          [showClear]="true"
          styleClass="w-200p"
          [formControl]="yearsFilter"
        ></p-dropdown>
      </div>
      <div class="col-auto" *ngIf="filteredModel.canCreateAssignmentRequest">
        <button pButton [label]="t('buttons.new-assignment')" icon="pi pi-plus" (click)="onNewAssignmentButtonClicked()"></button>
      </div>
    </div>
    <p-accordion [multiple]="true" class="p-accordion-custom">
      <p-accordionTab
        [header]="t('requests-table-header', {quantity : filteredModel?.pendingAssignments | lengthOrZero })"
        [selected]="true"
      >
        <div class="row mb-5">
          <div class="col">
            <trkmgr-assignment-requests-table [assignments]="filteredModel?.pendingAssignments ?? []"></trkmgr-assignment-requests-table>
          </div>
        </div>
      </p-accordionTab>

      <p-accordionTab
        [header]="t('assignments-table-header', {quantity : filteredModel?.completedAssignments | lengthOrZero })"
        [selected]="true"
      >
        <div class="row">
          <div class="col">
            <trkmgr-assignments-table [assignments]="filteredModel?.completedAssignments ?? []"></trkmgr-assignments-table>
          </div>
        </div> </p-accordionTab
    ></p-accordion>
  </div>
</div>

<flx-rule-validation [context]="context" displayMode="dialog"></flx-rule-validation>

<div class="flx-header--title mb-3" transloco="app.modules.drivers.pages.overview.title"></div>
<flx-loading-spinner [context]="context"></flx-loading-spinner>

<trkmgr-drivers-table
  [drivers]="store.drivers$ | async"
  [canConsultDriver]="(store.flags$ | async)?.canConsultDriver ?? false"
  [canEditDriver]="(store.flags$ | async)?.canEditDriver ?? false"
  [canDeleteDriver]="(store.flags$ | async)?.canDeleteDriver ?? false"
  [canLinkBadge]="(store.flags$ | async)?.canLinkBadge ?? false"
  [canReturnBadge]="(store.flags$ | async)?.canReturnBadge ?? false"
  [showBadgeNumber]="(store.flags$ | async)?.showBadgeNumber ?? false"
  [badges]="store.badges$ | async"
  [commands]="tableCommands$ | async"
  [dropdownFilters]="dropdownFilters$ | async"
  (clickedEdit)="onEditDriverRequest($event)"
  (clickedDelete)="store.deleteDriver($event)"
  (clickedReturnBadge)="store.returnBadge($event)"
  (clickedLinkBadge)="store.linkBadge($event)"
  (export)="onExportDriversRequest()"
></trkmgr-drivers-table>

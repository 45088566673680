import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FlxValidators } from '@fluxys/gsmart/validation';
import { DateTime } from 'luxon';
import { SelectItem, SortEvent } from 'primeng/api';

import { customSort } from '../../../shared/utils/custom-sort';
import { AffiliationViewModel } from '../../models/view-models';

interface FormAffiliation {
  id: number;
  transporterName: string;
  transporterBusinessPartyRoleId: number;
  operationalPeriodStartDate: string;
  operationalPeriodEndDate: string;
}

@Component({
  selector: 'trkmgr-affiliations-section',
  templateUrl: './affiliations-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AffiliationsSectionComponent implements OnChanges {
  @Input() currentAffiliations: readonly AffiliationViewModel[] | null = null;
  @Input() canEdit = false;
  @Input() transporters: SelectItem<number>[] = [];
  @Output() readonly added = new EventEmitter<AffiliationViewModel>();
  @Output() readonly changed = new EventEmitter<AffiliationViewModel>();
  @Output() readonly removed = new EventEmitter<number>();

  readonly columns: { field: string; translation: string }[] = [
    { field: 'transporterName', translation: 'app.modules.drivers.components.affiliations-section.columns.transporter' },
    { field: 'operationalPeriodStartDate', translation: 'app.modules.drivers.components.affiliations-section.columns.valid-from' },
    { field: 'operationalPeriodEndDate', translation: 'app.modules.drivers.components.affiliations-section.columns.valid-to' },
  ];
  rows: UntypedFormGroup[] = [];
  affiliations: AffiliationViewModel[] = [];
  editableRow = -1;

  constructor(private readonly fb: UntypedFormBuilder) {}

  ngOnChanges(): void {
    this.affiliations = this.currentAffiliations ? this.currentAffiliations.map((entity) => ({ ...entity })) : [];
    this.createRows();
  }

  getTransporterName(id: number): string {
    return this.transporters.find((t) => t.value === id)?.label ?? '';
  }

  customSort(event: SortEvent): number {
    return customSort(event);
  }

  editClicked(id: number): void {
    if (this.editableRow !== -1) {
      this.cancelClicked(this.editableRow);
    }
    this.editableRow = id;
  }

  deleteClicked(id: number): void {
    this.removed.emit(id);
  }

  validateClicked(row: FormAffiliation): void {
    const rowForm = this.rows.find((r) => (r.value as FormAffiliation).id === row.id);
    if (rowForm?.valid) {
      this.editableRow = -1;

      const affiliation = this.affiliations.find((a) => a.id === row.id);
      if (affiliation) {
        affiliation.transporterBusinessPartyRoleId = row.transporterBusinessPartyRoleId;
        affiliation.operationalPeriodStartDate = row.operationalPeriodStartDate;
        affiliation.operationalPeriodEndDate = row.operationalPeriodEndDate;

        if (affiliation.id === 0) {
          this.added.emit(affiliation);
        } else {
          this.changed.emit(affiliation);
        }
      }
    }
  }

  cancelClicked(id: number): void {
    if (id === 0) {
      this.affiliations = this.affiliations.filter((x) => x.id !== 0);
    }
    this.editableRow = -1;
    this.createRows();
  }

  addEntity(): void {
    const newAf: AffiliationViewModel = {
      id: 0,
      tcn: 0,
      transporterName: this.transporters[0].label ?? 'NONE',
      transporterBusinessPartyRoleId: this.transporters[0].value,
      operationalPeriodStartDate: DateTime.fromJSDate(new Date()).toFormat('yyyyMMdd'),
      operationalPeriodEndDate: DateTime.fromJSDate(new Date()).toFormat('yyyyMMdd'),
    };
    this.affiliations.unshift(newAf);
    this.createRows();
    this.editableRow = 0;
  }

  private createRows(): void {
    this.rows = [];
    this.affiliations.forEach((af) => {
      const row = this.fb.group(
        {
          id: this.fb.control(af.id),
          transporterName: this.fb.control(af.transporterName),
          transporterBusinessPartyRoleId: this.fb.control(af.transporterBusinessPartyRoleId),
          operationalPeriodStartDate: this.fb.control(af.operationalPeriodStartDate, Validators.required),
          operationalPeriodEndDate: this.fb.control(af.operationalPeriodEndDate, Validators.required),
        },
        { validators: FlxValidators.startDateBeforeEndDate('operationalPeriodStartDate', 'operationalPeriodEndDate') }
      );
      this.rows.push(row);
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withLoadingSpinner } from '@fluxys/primeng';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';

import { VehiclesServicesModule } from '../vehicles-services.module';

@Injectable({ providedIn: VehiclesServicesModule })
export class CountryService {
  constructor(private readonly _http: HttpClient) {}

  getCountryItems(): Observable<SelectItem[]> {
    return this._http.get<SelectItem[]>('/api/vehicles/all-countries', {
      context: withLoadingSpinner('vehicle-part-dialog'),
    });
  }
}

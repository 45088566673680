import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AssignmentsRoutes } from '../../assignments-routes';

@Component({
  selector: 'trkmgr-assignment-request-type-selection',
  templateUrl: './assignment-request-type-selection.component.html',
})
export class AssignmentRequestTypeSelectionComponent {
  /**
   *
   */
  constructor(private readonly router: Router) {}

  onNewSlotAssignmentButtonClicked(): void {
    void this.router.navigate([AssignmentsRoutes.createTruckloadingSlotsRoute]);
  }

  onNewAppointmentAssignmentButtonClicked(): void {
    void this.router.navigate([AssignmentsRoutes.createTruckloadingAppointmentRoute]);
  }
}

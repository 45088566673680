import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { catchError, map, Observable, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { mapToPayload } from '../../core/models/http-error';
import { AutoConfirmationServicesModule } from '../autoconfirmation.service.module';
import { AcrDialogComponent } from '../components';
import { AcrService } from '../services/acr.service';

import { actions } from './acr.actions';
@Injectable({ providedIn: AutoConfirmationServicesModule })
export class AcrEffects {
  readonly loadOverviewData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.acr.overview.dataload),
      switchMap((_) =>
        this.acrService.find().pipe(
          map((data) => actions.acr.overview.loaded(data)),
          catchError((err: HttpErrorResponse) => of(actions.acr.overview.loadError(mapToPayload(err))))
        )
      )
    )
  );

  readonly openCreateDialog$: Observable<[Action, string]> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.acr.overview.openCreateDialog),
        withLatestFrom(this.translateService.selectTranslate<string>('app.modules.autoconfirmation.components.dialog.title').pipe(take(1))),
        tap(([, header]) => this.dialogService.open(AcrDialogComponent, { header }))
      ),
    { dispatch: false }
  );

  readonly acrRegistered$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.acr.overview.registered, actions.acr.overview.configured),
      map(() => actions.acr.overview.dataload())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly acrService: AcrService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslocoService
  ) {}
}

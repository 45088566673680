import { Pipe, PipeTransform } from '@angular/core';
import { HashMap, TranslocoService } from '@ngneat/transloco';
import { SelectItem } from 'primeng/api';

/**
 * This pipe is used to produce an array of SelectItem<string> with a value enum as
 * Parameter:
 *  - an array of strings (enum values)
 *  - the path of translations containing enum values as children
 * Output: an array of SelectItems<string> with the enum value as 'value' property
 * and translated strings as 'label' property
 */
@Pipe({ name: 'enumValueToTranslatedItem' })
export class EnumValueToTranslatedItemPipe implements PipeTransform {
  constructor(private readonly translate: TranslocoService) {}
  transform(items: string[], translationPath: string): SelectItem<string>[] {
    const translations = this.translate.translateObject<HashMap<string>>(translationPath);

    const result = items.map((i) => ({ value: i, label: translations[i] }));

    return result;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withLoadingSpinner } from '@fluxys/primeng';
import { CommandResultViewModel } from '@shared/models/command-result.viewmodel';
import { Observable } from 'rxjs';

import { AcceptAssignmentRequestCommand } from '../models/assignment-request-details/accept-assignment-request.command';
import { AssignmentDetailsViewModel } from '../models/assignment-request-details/assignment-details.viewmodel';
import { DeclineAssignmentRequestCommand } from '../models/assignment-request-details/decline-assignment-request.command';
import { DeleteAssignmentRequestCommand } from '../models/assignment-request-details/delete-assignment-request.command';

@Injectable({
  providedIn: 'root',
})
export class AssignmentDetailsService {
  private readonly basePath = '/api/assignment/assignment-detail';
  constructor(private readonly http: HttpClient) {}

  getAssignmentDetails(id: string, spinnerContext: string): Observable<AssignmentDetailsViewModel> {
    return this.http.get<AssignmentDetailsViewModel>(`${this.basePath}/${id}`, { context: withLoadingSpinner(spinnerContext) });
  }

  acceptAssignmentRequest(command: AcceptAssignmentRequestCommand, spinnerContext: string): Observable<CommandResultViewModel> {
    const url = `${this.basePath}/accept` as const;
    const context = withLoadingSpinner(spinnerContext);

    return this.http.post<CommandResultViewModel>(url, command, { context });
  }

  declineAssignmentRequest(command: DeclineAssignmentRequestCommand, spinnerContext: string): Observable<CommandResultViewModel> {
    const url = `${this.basePath}/decline` as const;
    const context = withLoadingSpinner(spinnerContext);

    return this.http.post<CommandResultViewModel>(url, command, { context });
  }

  deleteAssignmentRequest(command: DeleteAssignmentRequestCommand, spinnerContext: string): Observable<CommandResultViewModel> {
    const url = `${this.basePath}/delete` as const;
    const context = withLoadingSpinner(spinnerContext);

    return this.http.post<CommandResultViewModel>(url, command, { context });
  }
}

import { HttpParameterCodec, HttpParams, HttpParamsOptions } from '@angular/common/http';

/**
 * Use this factory if you encounter url encoding issues using the default `HttpParams` constructor
 */
export class HttpParamsFactory {
  /**
   * we need a basic encoder for iso date strings like "2021-09-02T15:00:00+02:00"
   * and not the default `HttpUrlEncodingCodec` that it uses
   */
  private static readonly _basicEncoder: HttpParameterCodec = {
    encodeKey: (key) => encodeURIComponent(key),
    encodeValue: (value) => encodeURIComponent(value),
    decodeKey: (key) => decodeURIComponent(key),
    decodeValue: (value) => decodeURIComponent(value),
  };

  /**
   * Creates a new `HttpParams` object
   * @param options optional http params options
   * @returns instance of `HttpParams`
   */
  static create(options: HttpParamsOptions = {}): HttpParams {
    return new HttpParams({ ...options, encoder: HttpParamsFactory._basicEncoder });
  }
}

import { Inject, InjectionToken, Injector, ModuleWithProviders, NgModule, Optional, Type } from '@angular/core';

import { AutomationDispatcher } from './automation.dispatcher';
import { AutomationHandler } from './automation.handler';

const AUTOMATION_HANDLERS = new InjectionToken<AutomationHandler[]>('AutomationHandlers');

@NgModule({})
export class AutomationModule {
  constructor(@Inject(AUTOMATION_HANDLERS) @Optional() handlers: AutomationHandler[] | null, dispatcher: AutomationDispatcher) {
    if (!handlers) throw new Error('import this module calling .forHandlers(...) to register custom handlers');
    handlers.forEach((h) => dispatcher.registerHandler(h));
  }

  static forHandlers(...handlerTypes: Type<AutomationHandler>[]): ModuleWithProviders<AutomationModule> {
    return {
      ngModule: AutomationModule,
      providers: [
        handlerTypes,
        {
          provide: AUTOMATION_HANDLERS,
          useFactory: (injector: Injector) => handlerTypes.map((type) => injector.get(type)),
          deps: [Injector],
        },
      ],
    };
  }
}

<trkmgr-overview-table [dropdownFilters]="dropdownFilters" [commands]="commands" (clickedExport)="export.emit()">
  <p-table
    [columns]="(tableColumns$ | async) ?? []"
    [value]="drivers ?? []"
    [rows]="25"
    [paginator]="true"
    sortField="name"
    [globalFilterFields]="(tableColumns$ | async) ?? []"
    [reorderableColumns]="true"
    tableStyleClass="flx-table"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let column of columns" [pSortableColumn]="column" pReorderableColumn>
          {{ getColumnTranslationKey(column) | transloco }}
          <p-sortIcon [field]="column"></p-sortIcon>
        </th>
        <th>&nbsp;</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-driver let-columns="columns">
      <tr class="u-cursor-pointer">
        <td (click)="clickedEdit.emit(driver.id)">{{ driver.name }}</td>
        <td (click)="clickedEdit.emit(driver.id)">{{ driver.accreditationStatus }}</td>
        <td (click)="clickedEdit.emit(driver.id)">{{ driver.adrCertificateNumber }}</td>
        <td (click)="clickedEdit.emit(driver.id)">{{ driver.transporters | joinStrings: ' ; ' }}</td>
        <td (click)="clickedEdit.emit(driver.id)">{{ driver.expiryAccreditation | toDate: 'yyyyMMdd' | date: 'dd.MM.yyyy' }}</td>
        <td [style]="isSlsProcessActive()" (click)="clickedEdit.emit(driver.id)">{{ driver.badgeNumber }}</td>
        <td>
          <trkmgr-table-row-menu *ngIf="canEditDriver" [menuItems]="getMenuItems(driver)" [data]="driver"></trkmgr-table-row-menu>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-dialog [(visible)]="showAddBadgeDialog">
    <p-header transloco="app.modules.drivers.components.drivers-table.add-badge-dialog.title"></p-header>

    <div class="flx-input-container p-2">
      <label for="driverName" class="flx-input-container__label"></label>
    </div>

    <form [formGroup]="linkBadgeForm">
      <flx-form-field-wrapper
        [label]="'app.modules.drivers.components.drivers-table.add-badge-dialog.driver-name' | transloco"
        inputId="driverName"
        [required]="true"
        ngClass="mb-2"
      >
        <input pInputText type="text" id="driverName" name="driverName" required formControlName="driverName" class="w-100" />
      </flx-form-field-wrapper>

      <flx-form-field-wrapper
        [label]="'app.modules.drivers.components.drivers-table.add-badge-dialog.badge-number' | transloco"
        inputId="badgeId"
        [required]="true"
      >
        <p-dropdown
          inputId="badgeId"
          name="badgeId"
          [options]="badges ?? []"
          [required]="true"
          appendTo="body"
          formControlName="badgeId"
          [placeholder]="
          'app.modules.drivers.components.drivers-table.add-badge-dialog.badge-picker-placeholder' | transloco
        "
          [showClear]="true"
          styleClass="w-100"
        ></p-dropdown>
      </flx-form-field-wrapper>
    </form>

    <p-footer>
      <button
        type="button"
        pButton
        [label]="'app.shared.buttons.save' | transloco"
        (click)="onSaveBadgeButtonClicked()"
        [disabled]="linkBadgeForm.invalid"
      ></button>
    </p-footer>
  </p-dialog>
</trkmgr-overview-table>

import { Component, OnInit } from '@angular/core';
import { AppState } from '@core/store';
import { Store } from '@ngrx/store';
import { VehiclePartType } from '@shared/models';
import { Observable } from 'rxjs';

import { VehiclePartListViewModel, VehiclePartStatusViewModel } from '../../models';
import { actions, selectors } from '../../store';

@Component({
  selector: 'trkmgr-vehicles-overview',
  templateUrl: './vehicles-overview.component.html',
})
export class VehiclesOverviewComponent implements OnInit {
  readonly viewModel$: Observable<VehiclePartListViewModel | undefined>;
  readonly statuses$: Observable<VehiclePartStatusViewModel[]>;

  constructor(private readonly _store: Store<AppState>) {
    this.viewModel$ = this._store.select(selectors.vehicleParts.overview);
    this.statuses$ = this._store.select(selectors.vehicleParts.statuses);
  }

  ngOnInit(): void {
    this._store.dispatch(actions.vehicleParts.overview.load());
  }

  createVehiclePart(type: VehiclePartType): void {
    this._store.dispatch(actions.vehicleParts.overview.create({ vehiclePartType: type }));
  }

  deleteVehiclePart(id: number): void {
    this._store.dispatch(actions.vehicleParts.delete({ id, validationContext: 'vehicle-part-overview' }));
  }

  editVehiclePart(event: { id: number; vehiclePartType: VehiclePartType; identification: string }): void {
    this._store.dispatch(actions.vehicleParts.overview.edit(event));
  }

  editVehiclePartDocuments(event: { id: number }): void {
    this._store.dispatch(actions.vehicleParts.overview.editDocuments(event));
  }

  viewVehiclePart(event: { id: number; vehiclePartType: VehiclePartType; identification: string }): void {
    this._store.dispatch(actions.vehicleParts.overview.view(event));
  }

  exportVehicleParts(): void {
    this._store.dispatch(actions.vehicleParts.overview.export());
  }
}

<ng-container>
  <section *ngIf="vm">
    <span class="section-title" transloco="app.modules.assignments.pages.assignment-details.general.title"></span>
    <div class="section-content">
      <div class="d-flex">
        <flx-form-field-wrapper label="app.modules.assignments.pages.assignment-details.general.assignor" ngClass="w-50 mr-2"
          >{{vm.assignor}}</flx-form-field-wrapper
        >

        <flx-form-field-wrapper label="app.modules.assignments.pages.assignment-details.general.assignee" ngClass="w-50 ml-2"
          >{{vm.assignee}}</flx-form-field-wrapper
        >
      </div>

      <div class="d-flex mt-4" *ngIf="vm.status">
        <flx-form-field-wrapper label="app.modules.assignments.pages.assignment-details.general.status" ngClass="w-50 mr-2"
          >{{vm.status}}</flx-form-field-wrapper
        >
      </div>

      <div class="d-flex mt-4">
        <flx-form-field-wrapper label="app.modules.assignments.pages.assignment-details.general.source-agreement" ngClass="w-50 mr-2"
          >{{vm.sourceAgreement}}</flx-form-field-wrapper
        >

        <flx-form-field-wrapper label="app.modules.assignments.pages.assignment-details.general.target-agreement" ngClass="w-50 ml-2"
          >{{vm.targetAgreement ?? '-'}}</flx-form-field-wrapper
        >
      </div>
    </div>
  </section>
  <hr class="mt-5" />
</ng-container>

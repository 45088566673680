/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ControlsOf, FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OverviewTableFilter } from '@shared/components/overview-table';
import { MenuItem, SelectItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { Table } from 'primeng/table';
import { SimpleChanges } from 'psm5-web/types';
import { Observable, of, ReplaySubject } from 'rxjs';

import { LinkBadgeToDriverCommand, ReturnBadgeCommand } from '../../models/commands';
import { DriverOverviewDriverDto } from '../../models/view-models';

type TableColumnName = Exclude<keyof DriverOverviewDriverDto, 'id'>; // this ensures we write valid table columns for the template

@UntilDestroy()
@Component({
  selector: 'trkmgr-drivers-table',
  templateUrl: './drivers-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriversTableComponent implements OnChanges {
  @Input() drivers: DriverOverviewDriverDto[] | null = null;
  @Input() canConsultDriver = false;
  @Input() canEditDriver = false;
  @Input() canDeleteDriver = false;
  @Input() canLinkBadge = false;
  @Input() canReturnBadge = false;
  @Input() showBadgeNumber = false;
  @Input() badges: SelectItem<number>[] | null = null;
  @Input() commands: MenuItem[] | null | undefined = [];
  @Input() dropdownFilters: (OverviewTableFilter | null | undefined)[] | null = [];

  @Output() readonly clickedEdit = new EventEmitter<number>();
  @Output() readonly clickedDelete = new EventEmitter<number>();
  @Output() readonly clickedReturnBadge = new EventEmitter<ReturnBadgeCommand>();
  @Output() readonly clickedLinkBadge = new EventEmitter<LinkBadgeToDriverCommand>();
  @Output() readonly export = new EventEmitter<void>();

  @ViewChild(Table, { static: true }) dataTable: Table | undefined;
  @ViewChild('menu') menu: Menu | undefined;

  readonly tableColumns$: Observable<TableColumnName[]>;
  menuItems: MenuItem[] = [];

  readonly loading$: Observable<boolean> = of(true);
  readonly linkBadgeForm: FormGroup<
    ControlsOf<{
      driverId: number | null;
      driverName: string | null;
      badgeId: number | null;
    }>
  >;

  private readonly columnTranslationKeys: Record<TableColumnName, string> = {
    name: 'name',
    accreditationStatus: 'accreditation-status',
    adrCertificateNumber: 'adr-certificate-number',
    transporters: 'transporters',
    expiryAccreditation: 'expiry-accreditation',
    badgeNumber: 'badge-number',
    badgeId: 'badge-id',
  };
  private readonly tableColumns = new ReplaySubject<TableColumnName[]>(1);

  private readonly _translateService: TranslocoService = inject(TranslocoService);
  private readonly fb: FormBuilder = inject(FormBuilder);

  constructor() {
    this.tableColumns$ = this.tableColumns.asObservable();

    // initialisation of the form of the dialog to link a badge to a driver
    this.linkBadgeForm = this.fb.group({
      driverId: this.fb.control(null, Validators.required),
      driverName: this.fb.control({ value: null, disabled: true }, Validators.required),
      badgeId: this.fb.control(null, Validators.required),
    });
  }

  get showAddBadgeDialog(): boolean {
    return this.linkBadgeForm.get(['driverId']).value !== null;
  }
  set showAddBadgeDialog(visible: boolean) {
    if (!visible) {
      this.linkBadgeForm.reset();
    }
  }

  ngOnChanges(changes: SimpleChanges<DriversTableComponent>): void {
    if (changes.showBadgeNumber) {
      this.tableColumns.next(this.createColumnNames(this.showBadgeNumber));
    }

    if (changes.canDeleteDriver || changes.canLinkBadge || changes.canReturnBadge) {
      this.menuItems = this.createMenuItems(this.canDeleteDriver, this.canLinkBadge, this.canReturnBadge);
    }
  }

  isSlsProcessActive(): string {
    if (this.showBadgeNumber) {
      return '';
    }
    return 'display:none;';
  }

  onSaveBadgeButtonClicked(): void {
    const { badgeId, driverId } = this.linkBadgeForm.getRawValue();
    if (badgeId && driverId) {
      this.clickedLinkBadge.emit({ badgeId, driverId });
      this.showAddBadgeDialog = false;
    }
  }

  getColumnTranslationKey(columnName: TableColumnName): string | undefined {
    return `app.modules.drivers.components.drivers-table.${this.columnTranslationKeys[columnName]}`;
  }

  getMenuItems(driver: DriverOverviewDriverDto): MenuItem[] {
    if (!driver.badgeId) {
      return this.menuItems.filter((x) => x.id !== 'ReturnBadgeItem');
    }

    return this.menuItems;
  }

  private createColumnNames(showBadgeNumber: boolean): TableColumnName[] {
    const names: TableColumnName[] = ['name', 'accreditationStatus', 'adrCertificateNumber', 'transporters', 'expiryAccreditation'];
    if (showBadgeNumber) names.push('badgeNumber');
    return names;
  }

  private createMenuItems(canDeleteDriver: boolean, canLinkBadge: boolean, canReturnBadge: boolean): MenuItem[] {
    const menuItems = [
      {
        id: 'EditItem',
        label: this._translateService.translate('app.shared.buttons.edit'),
        icon: 'fas fa-pencil-alt',
        command: (driver: DriverOverviewDriverDto) => this.clickedEdit.emit(driver.id),
      },
    ];

    if (canDeleteDriver) {
      menuItems.push({
        id: 'DeleteItem',
        label: this._translateService.translate('app.shared.buttons.delete'),
        icon: 'fas fa-trash',
        command: (driver: DriverOverviewDriverDto) => this.clickedDelete.emit(driver.id),
      });
    }

    if (canLinkBadge) {
      menuItems.push({
        id: 'LinkBadgeItem',
        label: this._translateService.translate('app.modules.drivers.components.drivers-table.link-badge'),
        icon: 'fad fa-id-badge',
        command: (driver: DriverOverviewDriverDto) => this.linkBadgeForm.patchValue({ driverId: driver.id, driverName: driver.name }),
      });
    }

    if (canReturnBadge) {
      menuItems.push({
        id: 'ReturnBadgeItem',
        label: this._translateService.translate('app.modules.drivers.components.drivers-table.return-badge'),
        icon: 'fad fa-id-badge',
        command: (dto: DriverOverviewDriverDto) => this.clickedReturnBadge.emit({ badgeId: dto.badgeId }),
      });
    }

    return menuItems;
  }
}

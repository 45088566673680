/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FlxValidationMessagesServices } from '@fluxys/gsmart/validation';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import { valueChanges } from 'psm5-web';
import { combineLatest, distinctUntilChanged, Observable, of, switchMap } from 'rxjs';

import {
  AgreementsPerBpViewModel,
  RegisterSlotsAssignmentRequestDataViewModel,
} from '../../models/register-slots-assignment-request/register-slots-assignment-request-data.viewmodel';
import { SubscribedServiceTableViewModel } from '../../models/register-slots-assignment-request/subscribed-service-table-viewmodel';
import { RegisterSlotsAssignmentRequestService } from '../../services/register-slots-assignment-request.service';

@UntilDestroy()
@Component({
  selector: 'trkmgr-register-slots-assignment-request',
  templateUrl: './register-slots-assignment-request.component.html',
})
export class RegisterSlotsAssignmentRequestComponent implements OnInit {
  @Input() model: RegisterSlotsAssignmentRequestDataViewModel | null = null;
  @Input() context = '';
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});

  assigneeTargetAgreements: SelectItem<string>[] = [];
  filteredSubscribedServices: SubscribedServiceTableViewModel[] = [];
  assignmentTypes: string[] = [];
  quantityDisabled = true;

  readonly baseTranslationPath = 'app.modules.assignments.components.register-slots-assignmentrequest';

  private allSubscribedServices: SubscribedServiceTableViewModel[] | null = null;

  constructor(
    private readonly formService: RegisterSlotsAssignmentRequestService,
    private readonly validationMessagesService: FlxValidationMessagesServices
  ) {
    this.validationMessagesService.registerCustomValidationMessages(
      {
        max: `${this.baseTranslationPath}.validation-messages.slots-quantity-max`,
      },
      true
    );
  }

  ngOnInit(): void {
    valueChanges<string>(this.form, 'assigneeBusinessPartyId')
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value) => {
        if (this.model !== null) {
          this.assigneeTargetAgreements = this.getBpAgreements(value, this.model.sourceAgreements);
        }
      });

    valueChanges<string>(this.form, 'operationalYear')
      .pipe(
        untilDestroyed(this),
        switchMap((value) => {
          let svc$: Observable<SubscribedServiceTableViewModel[] | null> = of([]);

          if (this.model !== null) {
            const assignorAgreementId = this.getBpDefaultAgreement(this.model.assignorBusinessPartyId, this.model.sourceAgreements)?.value;

            svc$ =
              assignorAgreementId && !this.allSubscribedServices
                ? this.formService.getSubscribedServicesPerYear(+value, assignorAgreementId, this.context)
                : of(this.allSubscribedServices);
          }

          return combineLatest(svc$, of(+value));
        })
      )
      .subscribe(([response, selecterYear]) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.allSubscribedServices = response;
        this.filteredSubscribedServices =
          this.allSubscribedServices?.filter((svc) => svc.operationalYears.some((year) => year === selecterYear)) ?? [];

        if (this.filteredSubscribedServices.length === 0) {
          this.form.patchValue({
            subscribedServiceId: undefined,
            requestedSlotsQuantity: undefined,
          });
          this.quantityDisabled = true;
        }
      });

    valueChanges<string>(this.form, 'subscribedServiceId')
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.quantityDisabled = false;
          const remainingSlots = this.getRemainingSlotsOfSubscribedService(value);
          const control = this.form.get('requestedSlotsQuantity');
          control?.clearValidators();
          control?.addValidators([Validators.required, Validators.min(0), Validators.max(remainingSlots)]);
          control?.updateValueAndValidity();
        }
      });
  }

  /**
   * @param bpId Business Party id for which we seek the agreements
   * @param agreements collection of agreements associated to a BusinessPartyId
   * @returns a collection of agreements associated to the given bpId
   */
  getBpAgreements(bpId: string, agreements: AgreementsPerBpViewModel[]): SelectItem<string>[] {
    const filteredAgreementsPerYear = agreements.filter((ag) => ag.businessPartyId === bpId);

    return filteredAgreementsPerYear[0]?.agreements ?? [];
  }

  /**
   * @param bpId Business Party id for which we seek the agreement
   * @param agreements collection of agreements associated to a BusinessPartyId
   * @returns the default agreement we should display in the UI (for now the first of the collection)
   */
  getBpDefaultAgreement(bpId: string, agreements: AgreementsPerBpViewModel[]): SelectItem<string> | null {
    const targetAgreementIds = this.getBpAgreements(bpId, agreements);

    return targetAgreementIds[0];
  }

  /**
   *
   * @param svcId
   * @returns
   */
  getRemainingSlotsOfSubscribedService(svcId: string): number {
    if (this.filteredSubscribedServices.some((svc) => svc.subscribedServiceId === svcId)) {
      return this.filteredSubscribedServices.filter((svc) => svc.subscribedServiceId === svcId)[0]!.availableSlots;
    }
    return 0;
  }
}

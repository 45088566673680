import { Component, Input } from '@angular/core';
import { SlotAssignmentDetailsViewModel } from 'src/app/assignments/models/assignment-request-details/slot-assignment-details.viewmodel';

@Component({
  selector: 'trkmgr-assignment-request-details-slot',
  templateUrl: './assignment-request-details-slot.component.html',
})
export class AssignmentRequestDetailsSlotComponent {
  @Input() vm: SlotAssignmentDetailsViewModel | undefined;
}

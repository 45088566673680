import { Action, combineReducers, createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';
import { UnitCode } from 'unit-codes';

import { concatReducers } from '../../core/store/utils';
import { VehiclePartType } from '../../shared/models';
import { VehiclePartFormData } from '../models';
import { VehiclePartStatusViewModel } from '../models/vehicle-part-status.viewmodel';

import { ACTIONS } from './vehicles.actions';
import { INITIAL_STATE, VehicleFormMode, VehiclePartsState } from './vehicles.state';

const DATE_FORMAT = 'yyyyMMdd';

const FORM_DATA_REDUCER = createReducer<VehiclePartFormData | undefined>(
  undefined,
  on(ACTIONS.vehicleParts.load, () => undefined),
  on(ACTIONS.vehicleParts.loaded, (_, action) => {
    const vehicle = action.vehicle;
    const dateAdded = DateTime.fromISO(vehicle.dateAdded).toFormat(DATE_FORMAT);
    const dateExpired = DateTime.fromISO(vehicle.dateExpired).toFormat(DATE_FORMAT);

    // the form data must be completely filled in for ng forms to connect
    // without errors
    // that's the reason why we map all vehicle part types
    // even though they may not be relevant

    const result: VehiclePartFormData = {
      vehiclePartId: vehicle.vehiclePartId,
      vehiclePartType: vehicle.vehiclePartType,
      tcn: 0, // TODO add tcn
      vehicleInformation: {
        owner: vehicle.transporterId,
        dateAdded,
        dateExpired,
        weight: vehicle.weight,
        comment: vehicle.comment,
        statusId: vehicle.status.id,
        rfidTag: vehicle.rfidTag,
        isDefect: vehicle.isDefect,
      },
      chassis: { countryId: vehicle.countryId, vehicleIdentification: vehicle.vehicleIdentification },
      lngcontainer: {
        containerCode: vehicle.containerCode,
        maxFillingPercentage: vehicle.maxFillingPercentage,
        totalLoadingVolume: vehicle.totalLoadingVolume,
        maxOperatingPressure: vehicle.maxOperatingPressure,
        loadingSide: vehicle.loadingSide,
      },
      tractor: {
        countryId: vehicle.countryId,
        vehicleIdentification: vehicle.vehicleIdentification,
        lngFueled: vehicle.lngFueled ?? false,
      },
      roadtanker: {
        countryId: vehicle.countryId,
        vehicleIdentification: vehicle.vehicleIdentification,
        containerCode: vehicle.containerCode,
        maxFillingPercentage: vehicle.maxFillingPercentage,
        totalLoadingVolume: vehicle.totalLoadingVolume,
        maxOperatingPressure: vehicle.maxOperatingPressure,
        loadingSide: vehicle.loadingSide,
      },
    };
    return result;
  }),
  on(ACTIONS.vehicleParts.overview.create, (_, action) => {
    const dateAdded = DateTime.fromISO(new Date().toISOString()).toFormat(DATE_FORMAT);

    const result: VehiclePartFormData = {
      vehiclePartId: undefined,
      vehiclePartType: action.vehiclePartType,
      vehicleInformation: {
        dateAdded,
        rfidTag: null,
        isDefect: false,
      },
    };
    switch (action.vehiclePartType) {
      case VehiclePartType.Chassis:
        result.chassis = {};
        break;
      case VehiclePartType.LNGContainer:
        result.lngcontainer = {};
        break;
      case VehiclePartType.Tractor:
        result.tractor = { lngFueled: false };
        break;
      case VehiclePartType.RoadTanker:
        result.roadtanker = {};
        break;
    }
    return result;
  })
);

const VEHICLE_PARTS_REDUCER_MAP = combineReducers<VehiclePartsState>({
  overview: createReducer(
    INITIAL_STATE.vehicleParts.overview,
    on(ACTIONS.vehicleParts.overview.load, () => undefined),
    on(ACTIONS.vehicleParts.overview.loaded, (_, action) => action.vehicles)
  ),
  selected: createReducer(
    INITIAL_STATE.vehicleParts.selected,
    on(ACTIONS.vehicleParts.load, () => undefined),
    on(ACTIONS.vehicleParts.loaded, (_, action) => action.vehicle)
  ),
  formData: FORM_DATA_REDUCER,
  formMode: createReducer(
    INITIAL_STATE.vehicleParts.formMode,
    on(ACTIONS.vehicleParts.overview.create, (): VehicleFormMode => 'create'),
    on(ACTIONS.vehicleParts.overview.edit, (): VehicleFormMode => 'edit'),
    on(ACTIONS.vehicleParts.overview.editDocuments, (): VehicleFormMode => 'editDocuments'),
    on(ACTIONS.vehicleParts.overview.view, (): VehicleFormMode => 'view')
  ),
  formVehiclePartType: createReducer(
    INITIAL_STATE.vehicleParts.formVehiclePartType,
    on(ACTIONS.vehicleParts.overview.create, (_, action) => action.vehiclePartType),
    on(ACTIONS.vehicleParts.overview.view, ACTIONS.vehicleParts.overview.edit, () => undefined)
  ),
  vehiclePartsStatuses: createReducer<VehiclePartStatusViewModel[]>(
    INITIAL_STATE.vehicleParts.vehiclePartsStatuses,
    on(ACTIONS.initializedModule, (_, action) => action.vehiclePartsStatuses)
  ),
  vehicleDocuments: createReducer(
    INITIAL_STATE.vehicleParts.vehicleDocuments,
    on(ACTIONS.vehicleParts.loadVehicleDocuments, () => undefined),
    on(ACTIONS.vehicleParts.loadedVehicleDocuments, (_, action) => action.vehicleDocuments)
  ),
});

const VEHICLE_PARTS_REDUCER = createReducer<VehiclePartsState>(
  INITIAL_STATE.vehicleParts,
  on(ACTIONS.vehicleParts.overview.create, (state) => ({
    ...state,
    formData: {
      ...state.formData,
      vehicleInformation: {
        rfidTag: null,
        ...state.formData?.vehicleInformation,
        statusId: state.vehiclePartsStatuses.find((s) => s.isDefault)?.id,
        dateExpired: calculateExpiryDate(
          new Date(),
          state.vehiclePartsStatuses.find((s) => s.isDefault)
        ),
      },
    },
    formState: {
      displayExpirationDate: shouldDisplayExpiryDate(state.vehiclePartsStatuses.find((s) => s.isDefault)),
    },
  })),
  on(ACTIONS.vehicleParts.statusChanged, (state, action) => {
    const currentStatus = state.vehiclePartsStatuses.find((s) => s.id === action.newStatusId);
    return {
      ...state,
      formData: {
        ...state.formData,
        vehicleInformation: {
          rfidTag: null,
          ...state.formData?.vehicleInformation,
          statusId: action.newStatusId,
          dateExpired: calculateExpiryDate(new Date(), currentStatus),
        },
      },
      formState: {
        displayExpirationDate: shouldDisplayExpiryDate(currentStatus),
      },
    };
  }),
  on(ACTIONS.vehicleParts.loaded, (state, action) => {
    const currentStatus = state.vehiclePartsStatuses.find((s) => s.id === action.vehicle.status.id);

    return {
      ...state,
      formState: {
        ...state.formState,
        displayExpirationDate: shouldDisplayExpiryDate(currentStatus),
      },
    };
  })
);

const VEHICLE_PARTS_REDUCER_IMPL = concatReducers(VEHICLE_PARTS_REDUCER_MAP, VEHICLE_PARTS_REDUCER);

export function vehiclePartsReducers(state: VehiclePartsState | undefined, action: Action): VehiclePartsState {
  return VEHICLE_PARTS_REDUCER_IMPL(state, action);
}

function calculateExpiryDate(fromDate: Date, status: VehiclePartStatusViewModel | undefined): string | undefined {
  const formatter = (date: Date): string => DateTime.fromJSDate(date).toFormat(DATE_FORMAT);

  if (!status?.validityValue || !status.validityUnit) return formatter(new Date(9999, 11, 31));

  const resultDate = fromDate;
  switch (status.validityUnit) {
    case UnitCode.year:
      resultDate.setFullYear(resultDate.getFullYear() + status.validityValue);
      break;
    default:
      return undefined;
  }
  return formatter(resultDate);
}

function shouldDisplayExpiryDate(status: VehiclePartStatusViewModel | undefined): boolean {
  return status?.nextStatusId !== null;
}

import { ActionReducerMap, createReducer, MetaReducer, on } from '@ngrx/store';
import { SelectItem } from 'primeng/api';

import { BusinessPartyViewModel } from '../models';

import { vehiclePartsReducers } from './vehicle-parts.reducers';
import { ACTIONS } from './vehicles.actions';
import { INITIAL_STATE, VehiclesModuleState } from './vehicles.state';

export const REDUCERS: ActionReducerMap<VehiclesModuleState> = {
  initialized: createReducer(
    INITIAL_STATE.initialized,
    on(ACTIONS.initializedModule, () => true)
  ),
  vehicleParts: vehiclePartsReducers,
  transporters: createReducer<BusinessPartyViewModel[] | undefined>(
    undefined,
    on(ACTIONS.businessParties.load, () => undefined),
    on(ACTIONS.businessParties.loaded, (_, action) => action.businessParties)
  ),
  countries: createReducer<SelectItem[] | undefined>(
    undefined,
    on(ACTIONS.countries.load, () => undefined),
    on(ACTIONS.countries.loaded, (_, action) => action.countries)
  ),
};

export const META_REDUCERS: MetaReducer<VehiclesModuleState>[] = [];

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlxValidatedResult } from '@fluxys/gsmart/rule-validation';
import { withLoadingSpinner } from '@fluxys/primeng';
import { HttpParamsFactory } from '@shared/utils';
import { Observable } from 'rxjs';

import { RegisterSlotsAssignmentRequestDataViewModel } from '../models/register-slots-assignment-request/register-slots-assignment-request-data.viewmodel';
import { RegisterSlotsAssignmentRequestCommand } from '../models/register-slots-assignment-request/register-slots-assignment-request.command';
import { SubscribedServiceTableViewModel } from '../models/register-slots-assignment-request/subscribed-service-table-viewmodel';

@Injectable({
  providedIn: 'root',
})
export class RegisterSlotsAssignmentRequestService {
  private readonly _basePath = '/api/assignment/subscribedservice/form';

  constructor(private readonly _http: HttpClient) {}

  getRegisterDataModel(spinnerContext: string): Observable<RegisterSlotsAssignmentRequestDataViewModel> {
    return this._http.get<RegisterSlotsAssignmentRequestDataViewModel>(`${this._basePath}`, {
      context: withLoadingSpinner(spinnerContext),
    });
  }

  getSubscribedServicesPerYear(year: number, agreementId: string, spinnerContext: string): Observable<SubscribedServiceTableViewModel[]> {
    let params = HttpParamsFactory.create();
    params = params.set('year', year);
    params = params.set('agreementId', agreementId);

    return this._http.get<SubscribedServiceTableViewModel[]>(`${this._basePath}/find-subscribedservices`, {
      params,
      context: withLoadingSpinner(spinnerContext),
    });
  }

  registerAssignmentRequest(command: RegisterSlotsAssignmentRequestCommand, spinnerContext: string): Observable<FlxValidatedResult> {
    const url = `${this._basePath}/register` as const;
    const context = withLoadingSpinner(spinnerContext);

    return this._http.post(url, command, { context });
  }
}

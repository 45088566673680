import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';

import {
  AffiliationViewModel,
  ApprovalViewModel,
  DocumentViewModel,
  DriverConfigureFormViewModel,
  DriverViewModel,
  TrainingViewModel,
} from '../models/view-models';

const INITIAL_STATE: DriverConfigureFormViewModel = {
  acceptedLanguages: [],
  driver: {
    driverId: -1,
    tcn: -1,
    firstName: '',
    lastName: '',
    fullName: '',
    phoneNumber: '',
    spokenLanguages: new Array<string>(),
    approvals: [],
    affiliations: [],
    documents: [],
    trainings: [],
  },
  canEditAffiliations: false,
  canEditApprovals: false,
  canEditDocuments: false,
  canEditDriver: false,
  canEditTrainings: false,
  canAuthorizeApproval: false,
  transporters: [],
  trainingTypes: [],
};

@Injectable()
export class DriverDetailsStoreService extends ComponentStore<DriverConfigureFormViewModel> {
  readonly acceptedLanguages$: Observable<SelectItem[]>;
  readonly driver$: Observable<DriverViewModel>;
  readonly approvals$: Observable<readonly ApprovalViewModel[]>;
  readonly affiliations$: Observable<readonly AffiliationViewModel[]>;
  readonly documents$: Observable<readonly DocumentViewModel[]>;
  readonly trainings$: Observable<readonly TrainingViewModel[]>;
  readonly canEditAffiliations$: Observable<boolean>;
  readonly canEditApprovals$: Observable<boolean>;
  readonly canEditDocuments$: Observable<boolean>;
  readonly canEditDriver$: Observable<boolean>;
  readonly canEditTrainings$: Observable<boolean>;
  readonly canAuthorizeApproval$: Observable<boolean>;
  readonly transporters$: Observable<SelectItem<number>[]>;
  readonly trainingTypes$: Observable<readonly SelectItem<number>[]>;

  constructor() {
    super(INITIAL_STATE);
    this.acceptedLanguages$ = this.select((state) => state.acceptedLanguages.map((value) => ({ label: value, value })));
    this.driver$ = this.select((state) => state.driver);
    this.approvals$ = this.select((state) => state.driver.approvals);
    this.affiliations$ = this.select((state) => state.driver.affiliations);
    this.documents$ = this.select((state) => state.driver.documents);
    this.trainings$ = this.select((state) => state.driver.trainings);
    this.canEditAffiliations$ = this.select((state) => state.canEditAffiliations);
    this.canEditApprovals$ = this.select((state) => state.canEditApprovals);
    this.canEditDocuments$ = this.select((state) => state.canEditDocuments);
    this.canEditDriver$ = this.select((state) => state.canEditDriver);
    this.canEditTrainings$ = this.select((state) => state.canEditTrainings);
    this.canAuthorizeApproval$ = this.select((state) => state.canAuthorizeApproval);
    this.transporters$ = this.select((state) => state.transporters);
    this.trainingTypes$ = this.select((state) => state.trainingTypes);
  }
}

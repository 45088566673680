<form [formGroup]="form" novalidate autocomplete="off">
  <div>
    <flx-form-field-wrapper
      label="app.modules.autoconfirmation.components.dialog.grantedbyshipper"
      inputId="grantedbyshipper"
      [required]="true"
    >
      <p-dropdown
        inputId="grantedbyshipper"
        appendTo="body"
        styleClass="w-100"
        [options]="(shippers$ | async | orderByLabel)!"
        [autoDisplayFirst]="false"
        [showClear]="true"
        [placeholder]="'app.modules.autoconfirmation.components.dialog.choose-shipper' | transloco"
        formControlName="grantedbyshipper"
      ></p-dropdown>

      <flx-validation-messages for="grantedbyshipper"></flx-validation-messages>
    </flx-form-field-wrapper>
  </div>

  <div class="mt-3">
    <flx-form-field-wrapper
      label="app.modules.autoconfirmation.components.dialog.fortruckloadingcustomer"
      inputId="fortruckloadingcustomer"
      [required]="true"
    >
      <p-dropdown
        inputId="fortruckloadingcustomer"
        appendTo="body"
        styleClass="w-100"
        [options]="(customers$ | async | orderByLabel)!"
        [autoDisplayFirst]="false"
        [showClear]="true"
        [placeholder]="'app.modules.autoconfirmation.components.dialog.choose-customer' | transloco"
        formControlName="fortruckloadingcustomer"
      ></p-dropdown>

      <flx-validation-messages for="fortruckloadingcustomer"></flx-validation-messages>
    </flx-form-field-wrapper>
  </div>

  <ng-container formGroupName="validityPeriod">
    <div class="mt-3">
      <flx-form-field-wrapper label="app.modules.autoconfirmation.components.dialog.from" inputId="from" [required]="true">
        <flx-date-picker
          formControlName="from"
          inputId="from"
          [yearRange]="currentYear + ':' + (currentYear + 100)"
          [showEot]="true"
          [fluidWidth]="true"
        ></flx-date-picker>

        <flx-validation-messages for="from"></flx-validation-messages>
      </flx-form-field-wrapper>
    </div>

    <div class="mt-3">
      <p-checkbox
        id="untilendofcurrentyear"
        formControlName="untilendofcurrentyear"
        [label]="'app.modules.autoconfirmation.components.dialog.untilendofcurrentyear' | transloco"
        [binary]="true"
      ></p-checkbox>
    </div>

    <div class="mt-3" *ngIf="!form.get('validityPeriod')!.get('untilendofcurrentyear')!.value">
      <flx-form-field-wrapper label="app.modules.autoconfirmation.components.dialog.until" inputId="until" [required]="true">
        <flx-date-picker formControlName="until" inputId="until" [showEot]="true" [fluidWidth]="true"></flx-date-picker>

        <flx-validation-messages for="until"></flx-validation-messages>
      </flx-form-field-wrapper>
    </div>

    <flx-validation-messages></flx-validation-messages>
  </ng-container>
</form>

<div class="section-footer section-footer-in-content">
  <div class="footer-content d-flex align-items-center justify-content-end">
    <span class="infoicon mr-3"><i class="fa fa-info-circle fa-2x" aria-hidden="true"></i></span>

    <span class="notice" transloco="app.modules.autoconfirmation.components.dialog.notice"></span>

    <button
      pButton
      type="button"
      class="p-button-tertiary"
      [disabled]="!enabled"
      (click)="close()"
      transloco="app.modules.autoconfirmation.components.dialog.cancel"
    ></button>

    <button
      pButton
      type="submit"
      [disabled]="form.invalid || !enabled"
      transloco="app.modules.autoconfirmation.components.dialog.save"
      (click)="register()"
    ></button>
  </div>
</div>

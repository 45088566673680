import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { AppState } from '@core/store';
import { handleRequestError } from '@core/store/error/error.actions';
import { FlxLoggerService } from '@fluxys/core';
import { FlxMessageService, FlxTranslatableMessage } from '@fluxys/primeng';
import { Action, Store } from '@ngrx/store';
import { catchError, EMPTY, mergeMap, MonoTypeOperatorFunction, Observable, of, throwError } from 'rxjs';

import { HttpError, mapToPayload } from '../models/http-error';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  private readonly messageService: FlxMessageService = inject(FlxMessageService);
  private readonly loggerService: FlxLoggerService = inject(FlxLoggerService);
  private readonly router: Router = inject(Router);

  constructor(private readonly store: Store<AppState>) {}

  handleHttpErrorResponse(error: HttpError, titleKey?: string, message?: FlxTranslatableMessage): void {
    switch (error.status) {
      case 0:
        {
          this.messageService.showErrorToast(
            {
              key: 'app.modules.core.errors.unknown-http-error',
              fallbackMessage: error.message,
              data: {
                url: error.url ?? '(url)',
                status: `${error.status}`,
                statusText: error.statusText,
              },
            },
            titleKey ?? 'app.modules.core.error.title'
          );
        }
        break;
      case 401:
        {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          // TODO remove FlxAuthorizationService dependency which is deprecated
          // const state = { redirect_url: this.router.url };
          // this.authService.startSigninMainWindow(state);
        }
        break;
      case 403:
        {
          const queryParams: Params = {
            code: 'access-denied',
          };
          void this.router.navigate(['/error'], { queryParams });
        }
        break;
      case 409:
        {
          if (!error.error) break;
          const translatableMessage = JSON.parse(error.error) as FlxTranslatableMessage;
          this.messageService.showErrorToast(translatableMessage, titleKey ?? 'app.modules.core.error.title');
        }
        break;
      case 412:
        {
          const queryParams: Params = {
            code: 'user-not-configured',
          };
          void this.router.navigate(['/error'], { queryParams, replaceUrl: true });
        }
        break;
      default:
        {
          this.loggerService.error('An unexpected error occurred:', error);
          this.messageService.showErrorToast(
            message ?? {
              key: 'app.modules.core.error.message',
              fallbackMessage: 'An unexpected error occurred. Please contact your IT support.',
            },
            titleKey ?? 'app.modules.core.error.title'
          );
        }
        break;
    }
  }

  createErrorHandler<T>(titleKey: string, rethrow = false): MonoTypeOperatorFunction<T> {
    return catchError((error: HttpErrorResponse) => {
      this.messageService.showErrorToast(
        {
          key: error.message,
          isTranslated: true,
        },
        titleKey
      );
      return rethrow ? throwError(() => error) : EMPTY;
    });
  }

  makeRequest<T>(
    request: Observable<T>,
    onResponse: (response: T) => Action | undefined,
    onError: (error: HttpErrorResponse) => void,
    errorTranslationKey: string
  ): void {
    request
      .pipe(
        mergeMap((response) => {
          const action = onResponse(response);
          if (action) return of(action);
          return EMPTY;
        }),
        catchError((error: HttpErrorResponse) => {
          onError(error);
          return of(
            handleRequestError({
              error: mapToPayload(error),
              errorTranslationKey,
            })
          );
        })
      )
      .subscribe((action) => this.store.dispatch(action));
  }
}

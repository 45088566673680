<flx-loading-spinner [context]="['vehicle-part-documents']"></flx-loading-spinner>

<div class="flx-header--title mb-3" transloco="app.modules.vehicle-documents.title"></div>

<trkmgr-overview-table [inlineFilter]="inlineFilter" [dropdownFilters]="dropdownFilters" (clickedExport)="export()">
  <p-table
    [columns]="tableColumns"
    [value]="documents!"
    [rows]="25"
    [paginator]="true"
    sortField="id"
    [globalFilterFields]="[ 'documentType', 'id', 'owner', 'validFrom', 'validTo' ]"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let column of columns" [pSortableColumn]="column.key" pReorderableColumn>
          <div class="d-flex align-items-center justify-content-start">
            {{ 'app.modules.vehicle-documents.overview.' + column.header | transloco }}
            <p-sortIcon [field]="column.key" *ngIf="column.key !== 'document'"></p-sortIcon>
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-document>
      <tr>
        <td>{{ ('app.modules.vehicle-documents.types.' + document.documentType) | transloco }}</td>
        <td>{{ document.id }}</td>
        <td>{{ document.vehiclePartIdentification }}</td>
        <td>{{ document.owner }}</td>
        <td>{{ document.validFrom | toDate: 'yyyyMMdd' | date: 'dd.MM.yyyy' }}</td>
        <td>{{ document.validTo | toDate: 'yyyyMMdd' | date: 'dd.MM.yyyy' }}</td>
        <td>
          <p-checkbox [ngModel]="document.validated" (ngModelChange)="validateDocument($event, document)" [binary]="true"></p-checkbox>
        </td>
        <td><p-button (click)="openDocument(document)" styleClass="p-button-tertiary" icon="fa-regular fa-file-pdf"></p-button></td>
      </tr>
    </ng-template>
  </p-table>
</trkmgr-overview-table>

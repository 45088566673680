import { Component, OnInit, ViewChild } from '@angular/core';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { AppState } from '@core/store';
import { hasErrors } from '@fluxys/gsmart/rule-validation';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AcrTableComponent } from '../../components/acr-table/acr-table-component';
import { AcrViewModel } from '../../models/acr.models';
import { ConfigureAcrEvent } from '../../models/configure-acr.event';
import { AcrService } from '../../services/acr.service';
import { actions } from '../../store/acr.actions';
import { selectors } from '../../store/acr.selectors';

@Component({
  selector: 'trkmgr-acr-overview',
  templateUrl: './acr-overview.component.html',
})
export class AcrOverviewComponent implements OnInit {
  @ViewChild(AcrTableComponent) table?: AcrTableComponent;

  readonly rules$: Observable<AcrViewModel[]>;
  readonly canCreate$: Observable<boolean>;
  readonly canEdit$: Observable<boolean>;
  readonly areShippersVisible$: Observable<boolean>;

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _acrService: AcrService,
    private readonly _errorHandlerService: ErrorHandlerService
  ) {
    this.rules$ = this._store.select(selectors.list);
    this.areShippersVisible$ = this._store.pipe(select(selectors.areShippersVisible));
    this.canCreate$ = this._store.pipe(select(selectors.canCreate));
    this.canEdit$ = this._store.pipe(select(selectors.canEdit));
  }

  ngOnInit(): void {
    this._store.dispatch(actions.acr.overview.dataload());
  }

  createNew(): void {
    this._store.dispatch(actions.acr.overview.openCreateDialog());
  }

  configure(event: ConfigureAcrEvent): void {
    this._errorHandlerService.makeRequest(
      this._acrService.configure(
        {
          id: event.id,
          validityPeriodEnd: event.endDate,
        },
        'configure-acr'
      ),
      (response) => {
        if (!hasErrors(response)) {
          return actions.acr.overview.configured();
        } else {
          this.table?.refreshRules();
        }
      },
      () => void 0,
      'app.modules.autoconfirmation.http-errors.configure-acr-error'
    );
  }
}

import { Action, createReducer, on } from '@ngrx/store';

import * as userActions from './user.actions';
import { INITIAL_STATE, UserState } from './user.state';

const REDUCER = createReducer(
  INITIAL_STATE,
  on(userActions.userPermissionsLoaded, (state, { type, ...data }) => ({
    ...state,
    ...data,
    businessPartyId: data.businessPartyId,
    businessPartyName: data.businessPartyName,
    lastRefresh: Date.now(),
    loaded: true,
    success: true,
  })),
  on(userActions.userPermissionsFailed, (state) => ({
    ...state,
    name: 'Anonymous',
    roles: [],
    permissions: [],
    effectivePermissions: [],
    businessPartyId: null,
    lastRefresh: state.lastRefresh + 1,
    loaded: true,
    success: false,
  }))
);

export function reducer(state: UserState | undefined, action: Action): UserState {
  return REDUCER(state, action);
}

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { UntypedFormGroup } from '@angular/forms';
import { SortEvent } from 'primeng/api';

export function customSort(event: SortEvent): number {
  event.data?.sort((data1: UntypedFormGroup, data2: UntypedFormGroup) => {
    const value1 = data1.value[event.field ?? ''];
    const value2 = data2.value[event.field ?? ''];
    let result = null;

    if (value1 === null && value2 !== null) result = -1;
    else if (value1 !== null && value2 === null) result = 1;
    else if (value1 === null && value2 === null) result = 0;
    else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
    else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

    return (event.order ?? 1) * result;
  });
  return 0;
}

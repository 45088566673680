<div class="flx-header--title mb-3" transloco="app.modules.autoconfirmation.title"></div>

<flx-loading-spinner [context]="['register-acr', 'configure-acr', 'load-dialog-data', 'acr-table']"></flx-loading-spinner>

<flx-rule-validation context="register-acr" displayMode="dialog"></flx-rule-validation>

<trkmgr-acr-table
  [canCreate]="(canCreate$ | async) ?? false"
  [rules]="rules$ | async"
  [canEdit]="(canEdit$ | async) ?? false"
  [areShippersVisible]="(areShippersVisible$ | async) ?? false"
  (create)="createNew()"
  (configure)="configure($event)"
></trkmgr-acr-table>

<div class="flx-header--title mb-3" transloco="app.modules.vehicles.title"></div>

<trkmgr-overview-table
  [inlineFilter]="inlineFilter$ | async"
  [dropdownFilters]="dropdownFilters$ | async"
  [commands]="canCreate ? (commands | translateItems: 'app.modules.vehicles.pages.overview.buttons') : []"
  (clickedExport)="export.emit()"
>
  <p-table
    [columns]="tableColumns"
    [value]="vehicles!"
    [rows]="25"
    [paginator]="true"
    sortField="identification"
    [globalFilterFields]="[
      'identification',
      'containerCode',
      'vehiclePartType',
      'owner',
      'rfidTag',
      'status.statusClass',
      'status.description',
      'dateExpired'
    ]"
    [reorderableColumns]="true"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th pSortableColumn="identification">
          <div class="d-flex align-items-center justify-content-start">
            {{ 'app.modules.vehicles.pages.overview.fields.identification' | transloco }}
            <p-sortIcon field="identification"></p-sortIcon>
          </div>
        </th>
        <th *ngFor="let column of columns" [pSortableColumn]="column.key" pReorderableColumn>
          <div class="d-flex align-items-center justify-content-start">
            {{ 'app.modules.vehicles.pages.overview.fields.' + column.header | transloco }}
            <p-sortIcon [field]="column.key"></p-sortIcon>
          </div>
        </th>
        <th></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-vehicle let-columns="columns">
      <tr>
        <td (click)="onTableLineClicked(vehicle)">{{ getVehicleIdentification(vehicle) }}</td>
        <td *ngFor="let column of columns" (click)="onTableLineClicked(vehicle)">
          <ng-container [ngSwitch]="column.key">
            <ng-container *ngSwitchCase="'vehiclePartType'"
              >{{ 'enums.vehicle-part-type.' + vehicle.vehiclePartType | transloco }}</ng-container
            >
            <ng-container *ngSwitchCase="'owner'">{{ vehicle.owner }}</ng-container>
            <ng-container *ngSwitchCase="'rfidTag'">{{ vehicle.rfidTag }}</ng-container>
            <ng-container *ngSwitchCase="'status.statusClass'">{{ vehicle.status?.statusClass }}</ng-container>
            <ng-container *ngSwitchCase="'status.description'">{{ vehicle.status?.description }}</ng-container>
            <ng-container *ngSwitchCase="'dateExpired'">{{ vehicle.dateExpired | date: 'dd.MM.yyyy' }}</ng-container>
          </ng-container>
        </td>
        <td>
          <trkmgr-table-row-menu
            [menuItems]="menuItems | translateActions"
            [data]="vehicle"
            *ngIf="vehicle.canEdit || vehicle.canDelete"
          ></trkmgr-table-row-menu>
        </td>
      </tr>
    </ng-template>
  </p-table>
</trkmgr-overview-table>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'lengthOrZero' })
export class LengthOrZeroPipe implements PipeTransform {
  transform<T>(value: T[] | null | undefined): number {
    if (value === undefined || value === null) {
      return 0;
    }

    return value.length;
  }
}

<div class="section-content ml-2" [formGroup]="form">
  <div class="p-2">
    <label for="containerCode" psm5FormLabel [transloco]="dialogLink + 'container-code'"></label>
    <input type="text" id="containerCode" formControlName="containerCode" />
    <div *ngIf="form.hasError('required', 'containerCode') && form.get('containerCode')?.touched" class="u-validation-message mt-1">
      <span [transloco]="dialogLink + 'validation.required-container-code'"></span>
    </div>
    <div
      *ngIf="form.hasError('maxReferenceCodeCharactersLength', 'containerCode') && form.get('containerCode')?.touched"
      class="u-validation-message mt-1"
    >
      <span [transloco]="dialogLink + 'validation.container-code-too-long'"></span>
    </div>
  </div>
  <div class="p-2">
    <label for="totalLoadingVolume" psm5FormLabel [transloco]="dialogLink + 'total-loading-volume'"></label>
    <input type="text" id="totalLoadingVolume" formControlName="totalLoadingVolume" />
    <span class="ml-2 u-grey-label">{{ units.m3LNG }}</span>
    <div
      *ngIf="form.hasError('required', 'totalLoadingVolume') && form.get('totalLoadingVolume')?.touched"
      class="u-validation-message mt-1"
    >
      <span [transloco]="dialogLink + 'validation.required-total-lng-volume'"></span>
    </div>
    <div *ngIf="form.hasError('invalidQuantity', 'totalLoadingVolume')" class="u-validation-message mt-1">
      <span [transloco]="dialogLink + 'validation.invalid-quantity'"></span>
    </div>
    <div *ngIf="form.hasError('quantityTooSmall', 'totalLoadingVolume')" class="u-validation-message mt-1">
      <span
        [transloco]="dialogLink + 'validation.volume-too-small'"
        [translocoParams]="{ minTotalLngVolume: vehiclePartParams?.minTotalLngVolume, lngVolumeUnit: units.m3LNG }"
      >
      </span>
    </div>
    <div *ngIf="form.hasError('quantityTooLarge', 'totalLoadingVolume')" class="u-validation-message mt-1">
      <span
        [transloco]="dialogLink + 'validation.volume-too-large'"
        [translocoParams]="{ maxTotalLngVolume: vehiclePartParams?.maxTotalLngVolume, lngVolumeUnit: units.m3LNG }"
      >
      </span>
    </div>
  </div>
  <div class="d-flex">
    <div class="p-2">
      <label for="maxFillingPercentage" psm5FormLabel [transloco]="dialogLink + 'max-filling-percentage'"></label>
      <input type="text" id="maxFillingPercentage" formControlName="maxFillingPercentage" />
      <span class="ml-2 u-grey-label" transloco="generic.units.percentage"></span>
      <div
        *ngIf="form.hasError('required', 'maxFillingPercentage') && form.get('maxFillingPercentage')?.touched"
        class="u-validation-message mt-1"
      >
        <span [transloco]="dialogLink + 'validation.required-max-filling-percentage'"></span>
      </div>
      <div *ngIf="form.hasError('invalidQuantity', 'maxFillingPercentage')" class="u-validation-message mt-1">
        <span [transloco]="dialogLink + 'validation.invalid-quantity'"></span>
      </div>
      <div *ngIf="form.hasError('quantityTooSmall', 'maxFillingPercentage')" class="u-validation-message mt-1">
        <span
          [transloco]="dialogLink + 'validation.percentage-too-small'"
          [translocoParams]="{ minFillingPercentage: vehiclePartParams?.minFillingPercentage }"
        >
        </span>
      </div>
      <div *ngIf="form.hasError('quantityTooLarge', 'maxFillingPercentage')" class="u-validation-message mt-1">
        <span
          [transloco]="dialogLink + 'validation.percentage-too-large'"
          [translocoParams]="{ maxFillingPercentage: vehiclePartParams?.maxFillingPercentage }"
        >
        </span>
      </div>
    </div>
    <div class="p-2">
      <label for="maxLoadingVolume" [transloco]="dialogLink + 'max-loading-volume'"></label>
      <input type="text" id="maxLoadingVolume" [value]="maxLoadingVolumePct" readonly />
      <span class="ml-2 u-grey-label">{{ units.m3LNG }}</span>
    </div>
  </div>
  <div class="p-2">
    <label for="maxOperatingPressure" psm5FormLabel [transloco]="dialogLink + 'max-operating-pressure'"></label>
    <input type="text" id="maxOperatingPressure" formControlName="maxOperatingPressure" />
    <span class="ml-2 u-grey-label">{{ units.bar }}</span>
    <div *ngIf="form.hasError('invalidQuantity', 'maxOperatingPressure')" class="u-validation-message mt-1">
      <span [transloco]="dialogLink + 'validation.invalid-quantity'"></span>
    </div>
    <div *ngIf="form.hasError('quantityTooSmall', 'maxOperatingPressure')" class="u-validation-message mt-1">
      <span
        [transloco]="dialogLink + 'validation.operating-pressure-too-small'"
        [translocoParams]="{ minOperatingPressure: vehiclePartParams?.minOperatingPressure, barUnit: units.bar }"
      >
      </span>
    </div>
    <div *ngIf="form.hasError('quantityTooLarge', 'maxOperatingPressure')" class="u-validation-message mt-1">
      <span
        [transloco]="dialogLink + 'validation.operating-pressure-too-large'"
        [translocoParams]="{ maxOperatingPressure: vehiclePartParams?.maxOperatingPressure, barUnit: units.bar }"
      >
      </span>
    </div>
  </div>
  <div class="p-2">
    <label for="loadingSide" psm5FormLabel [transloco]="dialogLink + 'loading-side'"></label>
    <select id="loadingSide" formControlName="loadingSide">
      <option value=""></option>
      <option *ngFor="let side of loadingSideOptions" [ngValue]="side.value">{{ side.label }}</option>
    </select>
  </div>
</div>

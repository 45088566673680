import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlxValidatedResult } from '@fluxys/gsmart/rule-validation';
import { withLoadingSpinner } from '@fluxys/primeng';
import { HttpParamsFactory } from '@shared/utils';
import { Observable } from 'rxjs';

import { RegisterTruckloadingAppointmentAssignmentRequestDataViewModel } from '../models/register-truckloading-appointment-assignment-request/register-truckloading-appointment-assignment-request-data.viewmodel';
import { RegisterTruckloadingAppointmentAssignmentRequestCommand } from '../models/register-truckloading-appointment-assignment-request/register-truckloading-appointment-assignment-request.command';
import { TruckloadingAppointmentTableViewModel } from '../models/register-truckloading-appointment-assignment-request/truckloading-appointment-table-viewmodel';

@Injectable({
  providedIn: 'root',
})
export class RegisterTruckloadingAppointmentAssignmentRequestService {
  private readonly _basePath = '/api/assignment/appointment/form';

  constructor(private readonly _http: HttpClient) {}

  getRegisterDataModel(spinnerContext: string): Observable<RegisterTruckloadingAppointmentAssignmentRequestDataViewModel> {
    return this._http.get<RegisterTruckloadingAppointmentAssignmentRequestDataViewModel>(`${this._basePath}`, {
      context: withLoadingSpinner(spinnerContext),
    });
  }

  getAppointmentsPerDate(date: string, spinnerContext: string): Observable<TruckloadingAppointmentTableViewModel[]> {
    let params = HttpParamsFactory.create();
    params = params.set('date', date);

    return this._http.get<TruckloadingAppointmentTableViewModel[]>(`${this._basePath}/find-appointments`, {
      params,
      context: withLoadingSpinner(spinnerContext),
    });
  }

  registerAssignmentRequest(
    command: RegisterTruckloadingAppointmentAssignmentRequestCommand,
    spinnerContext: string
  ): Observable<FlxValidatedResult> {
    const url = `${this._basePath}/register` as const;
    const context = withLoadingSpinner(spinnerContext);

    return this._http.post(url, command, { context });
  }
}

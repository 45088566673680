import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withLoadingSpinner } from '@fluxys/primeng';
import { Observable } from 'rxjs';

import { BusinessPartyViewModel } from '../models';
import { VehiclesServicesModule } from '../vehicles-services.module';

@Injectable({ providedIn: VehiclesServicesModule })
export class BusinessPartyService {
  constructor(private readonly _http: HttpClient) {}

  getBusinessParties(): Observable<BusinessPartyViewModel[]> {
    return this._http.get<BusinessPartyViewModel[]>('/api/vehicles/businessparties', {
      context: withLoadingSpinner('vehicle-part-dialog'),
    });
  }
}

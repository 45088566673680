import { FlxValidatedResult } from '@fluxys/gsmart/rule-validation';
import { createAction, props } from '@ngrx/store';
import { SelectItem } from 'primeng/api';

import { HttpError } from '../../core/models/http-error';
import { ShowValidationInContext } from '../../core/store/action-support';
import { VehiclePartType } from '../../shared/models';
import { VehicleDocument } from '../../shared/models/vehicle-document';
import { BusinessPartyViewModel, VehiclePartListViewModel, VehiclePartOverviewViewModel } from '../models';
import { VehicleModuleInitializedData } from '../models/vehicle-module-initialized-data.viewmodel';

export const ACTIONS = {
  initializeModule: createAction('[VEHICLES] Initialize vehicle module'),
  initializedModule: createAction('[VEHICLES] Initialized vehicle module', props<VehicleModuleInitializedData>()),
  initializeError: createAction('[VEHICLES] Initialize vehicle module failed', props<HttpError>()),

  vehicleParts: {
    overview: {
      create: createAction('[VEHICLES] Create vehicle part', props<{ vehiclePartType: VehiclePartType }>()),
      edit: createAction('[VEHICLES] Edit vehicle part', props<OverviewEditAction>()),
      editDocuments: createAction('[VEHICLES] Edit vehicle part documents', props<OverviewEditDocumentsAction>()),
      view: createAction('[VEHICLES] View vehicle part', props<OverviewViewAction>()),
      recalculateLoadingPct: createAction('[VEHICLES] Recalculate max. loading %', props<{ totalVolume: number; maxFillingPct: number }>()),

      load: createAction('[VEHICLES] Load vehicle parts overview'),
      loaded: createAction('[VEHICLES] Loaded vehicle parts overview', props<{ vehicles: VehiclePartListViewModel }>()),
      loadError: createAction('[VEHICLES] Load vehicle parts overview failed', props<HttpError>()),

      export: createAction('[VEHICLES] Export vehicle parts'),
      exportSuccess: createAction('[VEHICLES] Export vehicle parts succeeded'),
      exportError: createAction('[VEHICLES] Export vehicle parts failed', props<HttpError>()),
    },

    load: createAction('[VEHICLES] Load vehicle part', props<{ id: number }>()),
    loaded: createAction('[VEHICLES] Loaded vehicle part', props<{ vehicle: VehiclePartOverviewViewModel }>()),
    loadError: createAction('[VEHICLES] Load vehicle part failed', props<HttpError>()),

    loadVehicleDocuments: createAction('[VEHICLES] load vehicle documents', props<{ id: number }>()),
    loadedVehicleDocuments: createAction('[VEHICLES] Loaded vehicle documents', props<{ vehicleDocuments: VehicleDocument[] }>()),
    loadVehicleDocumentsError: createAction('[VEHICLES] Load vehicle documents failed', props<HttpError>()),

    statusChanged: createAction('[VEHICLES] Status changed', props<{ newStatusId: number }>()),

    delete: createAction('[VEHICLES] Delete vehicle part', props<ShowValidationInContext & { id: number }>()),
    deleted: createAction('[VEHICLES] Deleted vehicle part', props<{ result: FlxValidatedResult }>()),
    deleteError: createAction('[VEHICLES] Delete vehicle part failed', props<HttpError>()),
  },
  businessParties: {
    load: createAction('[VEHICLES] Load business parties'),
    loaded: createAction('[VEHICLES] Loaded business parties', props<{ businessParties: BusinessPartyViewModel[] }>()),
    loadError: createAction('[VEHICLES] Load business parties failed', props<HttpError>()),
  },
  countries: {
    load: createAction('[VEHICLES] Load countries'),
    loaded: createAction('[VEHICLES] Loaded countries', props<{ countries: SelectItem[] }>()),
    loadError: createAction('[VEHICLES] Load countries failed', props<HttpError>()),
  },
};

export interface OverviewViewAction {
  id: number;
  vehiclePartType: VehiclePartType;
  identification: string;
}

export interface OverviewEditAction {
  id: number;
  vehiclePartType: VehiclePartType;
  identification: string;
}

export interface OverviewEditDocumentsAction {
  id: number;
}

import { HttpErrorResponse } from '@angular/common/http';

export interface HttpError {
  status: number;
  statusText: string;
  url: string | null;
  message: string;
  error: string | null;
}

export function mapToPayload(error: HttpErrorResponse): HttpError {
  return {
    status: error.status,
    statusText: error.statusText,
    url: error.url,
    message: error.message,
    error: JSON.stringify(error.error),
  };
}

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AcrModuleState } from './acr.state';

const FEATURE_SELECTOR = createFeatureSelector<AcrModuleState>('acr');

const SELECTORS = {
  list: createSelector(FEATURE_SELECTOR, (state) => state.rules),
  canCreate: createSelector(FEATURE_SELECTOR, (state) => state.canCreate),
  canEdit: createSelector(FEATURE_SELECTOR, (state) => state.canEdit),
  areShippersVisible: createSelector(FEATURE_SELECTOR, (state) => state.areShippersVisible),
};

export { SELECTORS as selectors };

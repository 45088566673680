import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TM_HAS_PERMISSION_CAN_ACTIVATE_FN } from 'src/app/guards/truckmanager-authorization.guard';

import { DriversOverviewComponent } from './pages/drivers-overview/drivers-overview.component';

const DRIVER_ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'overview' },
  {
    path: 'overview',
    component: DriversOverviewComponent,
    data: {
      permissions: ['driver-consult'],
    },
    canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN],
  },
];

@NgModule({
  imports: [RouterModule.forChild(DRIVER_ROUTES)],
  exports: [RouterModule],
})
export class DriverRoutingModule {}

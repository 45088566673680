import { Component, Input } from '@angular/core';
import { AssignmentDetailsViewModel } from 'src/app/assignments/models/assignment-request-details/assignment-details.viewmodel';

@Component({
  selector: 'trkmgr-assignment-request-details-general',
  templateUrl: './assignment-request-details-general.component.html',
})
export class AssignmentRequestDetailsGeneralComponent {
  @Input() vm: AssignmentDetailsViewModel | undefined;
}

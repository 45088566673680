import { Pipe, PipeTransform } from '@angular/core';
import { HashMap, TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'translateArray',
})
export class TranslateArrayPipe implements PipeTransform {
  constructor(private readonly translate: TranslocoService) {}

  transform(items: string[], traductionPath: string): string[];
  transform(items: string[] | null | undefined, traductionPath: string): string[] | null;
  transform(items: string[] | null | undefined, traductionPath: string): string[] | null {
    const translations = this.translate.translateObject<HashMap<string>>(traductionPath);

    return items?.map((item) => translations[item]) ?? null;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { HashMap, TranslocoService } from '@ngneat/transloco';
import { MenuItem, SelectItem } from 'primeng/api';

@Pipe({
  name: 'translateItems',
})
export class TranslateItemsPipe implements PipeTransform {
  constructor(private readonly translate: TranslocoService) {}
  transform<T extends MenuItem | SelectItem<string>>(items: T[] | null, traductionPath: string): T[] {
    const translations = this.translate.translateObject<HashMap<string>>(traductionPath);

    return (
      items?.map((item) => {
        const result = { ...item };
        if (item.label) {
          result.label = translations[item.label];
        }
        return result;
      }) ?? []
    );
  }
}

import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FlxCommonModule } from '@fluxys/common';
import { FlxCoreModule, isNeitherNullNorUndefined } from '@fluxys/core';
import { FlxGSmartModule } from '@fluxys/gsmart';
import { FlxBreadcrumbModule } from '@fluxys/gsmart/breadcrumb';
import { FlxLayoutModule } from '@fluxys/gsmart/layout';
import { FlxRuleValidationOverrideInterceptor } from '@fluxys/gsmart/rule-validation';
import { FlxPrimengModule } from '@fluxys/primeng';
import { FlxSecurityModule } from '@fluxys/security';
import { TranslocoModule, TRANSLOCO_MISSING_HANDLER } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastModule } from 'primeng/toast';
import { ImpersonationHandler, ImpersonationModule } from 'psm5-web';

import { environment } from '../../environments/environment';

import { CustomImpersonationHandler } from './services/custom-impersonation-handler.service';
import { MissingTranslationHandlerService } from './services/missing-translation-handler.service';
import { HASH_TOKEN, VersionCheckService, VERSION_TOKEN } from './services/version-check.service';
import { CustomRouterStateSerializer, metaReducers, reducers, RouterEffects, runtimeChecks, UserEffects } from './store';
import { ErrorEffects } from './store/error/error.effects';

@NgModule({
  imports: [
    EffectsModule.forRoot([ErrorEffects, RouterEffects, UserEffects]),
    FlxCommonModule.forRoot({ availableLanguages: ['en'], defaultLanguage: 'en' }),
    FlxCoreModule.forRoot({ gitHash: environment.version.sha }),
    FlxSecurityModule.forRoot(),
    FlxPrimengModule.forRoot(),
    FlxGSmartModule.forRoot(),
    FlxBreadcrumbModule,
    FlxLayoutModule.forRoot(),
    HttpClientModule,
    OverlayModule,
    ImpersonationModule.forRoot({ applicationName: 'TruckManager' }),
    ToastModule,
    StoreModule.forRoot(reducers, { metaReducers, runtimeChecks }),
    StoreDevtoolsModule.instrument({ maxAge: 50, logOnly: environment.production }), // must be instantiated after the StoreModule
    StoreRouterConnectingModule.forRoot({ routerState: RouterState.Minimal }),
    TranslocoModule,
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: TRANSLOCO_MISSING_HANDLER, useClass: MissingTranslationHandlerService },
    { provide: VERSION_TOKEN, useValue: environment.version.semVer },
    { provide: HASH_TOKEN, useValue: environment.version.sha },
    VersionCheckService,
    { provide: ImpersonationHandler, useClass: CustomImpersonationHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FlxRuleValidationOverrideInterceptor,
      multi: true,
    },
  ],
  exports: [FlxBreadcrumbModule, FlxLayoutModule, TranslocoModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() private readonly module: CoreModule | null) {
    if (isNeitherNullNorUndefined(this.module)) {
      throw new Error(`The core module can only be used in the AppModule class.`);
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlxDatePickerModule } from '@fluxys/gsmart/date-picker';
import { FlxFormFieldWrapperModule } from '@fluxys/gsmart/form-field-wrapper';
import { FlxRuleValidationModule } from '@fluxys/gsmart/rule-validation';
import { FlxValidationModule } from '@fluxys/gsmart/validation';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';
import { Psm5CommonModule } from 'psm5-web';

import { AppState } from '../core/store';
import { SharedModule } from '../shared/shared.module';

import { AcrRoutingModule } from './autoconfirmation-routing.module';
import { AutoConfirmationServicesModule } from './autoconfirmation.service.module';
import { AcrDialogComponent, AcrTableComponent } from './components';
import { AcrOverviewComponent } from './pages';
import { AcrEffects, actions, metaReducers, reducers } from './store';

const PRIME_NG_MODULES = [ButtonModule, CheckboxModule, DropdownModule, TableModule];

@NgModule({
  declarations: [AcrDialogComponent, AcrOverviewComponent, AcrTableComponent],
  imports: [
    AcrRoutingModule,
    AutoConfirmationServicesModule,
    CommonModule,
    FlxDatePickerModule,
    FlxFormFieldWrapperModule,
    FlxRuleValidationModule,
    FlxValidationModule,
    FormsModule,
    PRIME_NG_MODULES,
    Psm5CommonModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature('acr', reducers, { metaReducers }),
    EffectsModule.forFeature([AcrEffects]),
  ],
  providers: [DialogService],
})
export class AutoConfirmationModule {
  constructor(private readonly store: Store<AppState>) {
    this.store.dispatch(actions.initializeModule());
  }
}

<form [formGroup]="form" (ngSubmit)="save()" novalidate autocomplete="off">
  <section>
    <div
      class="section-title align-self-start p-2"
      transloco="app.modules.drivers.components.driver-details-form.section-titles.driver-details"
    ></div>
    <div class="section-content">
      <div class="d-flex">
        <div class="flx-input-container p-2">
          <label
            for="firstName"
            class="flx-input-container__label"
            psm5FormLabel
            transloco="app.modules.drivers.components.driver-details-form.labels.first-name"
          ></label>
          <input type="text" name="firstName" formControlName="firstName" />
        </div>
        <div class="flx-input-container p-2">
          <label
            for="lastName"
            class="flx-input-container__label"
            psm5FormLabel
            transloco="app.modules.drivers.components.driver-details-form.labels.last-name"
          ></label>
          <input type="text" name="lastName" formControlName="lastName" />
        </div>
      </div>
      <div *ngIf="readonly">
        <div class="flx-input-container p-2">
          <label
            for="fullName"
            class="flx-input-container__label"
            transloco="app.modules.drivers.components.driver-details-form.labels.full-name"
          ></label>
          <input type="text" name="fullName" formControlName="fullName" />
        </div>
      </div>
      <div class="d-flex">
        <div class="flx-input-container u-flex-equal-width p-2">
          <label
            for="spokenLanguages"
            class="flx-input-container__label"
            psm5FormLabel
            transloco="app.modules.drivers.components.driver-details-form.labels.spoken-languages"
          ></label>
          <p-multiSelect
            *ngIf="!readonly; else languagesList"
            [options]="acceptedLanguages | translateItems: translateLanguagesPath"
            dropdownIcon="pi pi-caret-down"
            formControlName="spokenLanguages"
            defaultLabel="None"
            appendTo="body"
          ></p-multiSelect>
          <ng-template #languagesList>
            <span *ngIf="driver?.spokenLanguages?.length ?? 0 > 0; else emptyLanguageList" class="text-muted">
              {{ driver?.spokenLanguages ?? [] | translateArray: translateLanguagesPath | joinStrings: ', ' }}
            </span>
            <ng-template #emptyLanguageList>
              <span class="text-muted" transloco="app.modules.drivers.components.driver-details-form.languages.none"> </span>
            </ng-template>
          </ng-template>
        </div>
        <div class="flx-input-container u-flex-equal-width p-2">
          <label
            for="phoneNumber"
            class="flx-input-container__label"
            psm5FormLabel
            transloco="app.modules.drivers.components.driver-details-form.labels.phone-number"
          ></label>
          <input type="text" name="phoneNumber" formControlName="phoneNumber" />
        </div>
      </div>
    </div>
  </section>
  <div *ngIf="mode === 'configure' && this.canEdit === true" class="d-flex justify-content-end px-1">
    <ng-container *ngIf="!readonly; then editButtons; else readonlyButtons"></ng-container>
    <ng-template #editButtons>
      <button
        pButton
        type="button"
        class="p-button-tertiary"
        [label]="'app.shared.buttons.cancel' | transloco"
        (click)="toggleReadonly()"
      ></button>
      <button pButton type="submit" [label]="'app.shared.buttons.save' | transloco" [disabled]="form.valid !== true"></button>
    </ng-template>
    <ng-template #readonlyButtons>
      <button pButton type="button" [label]="'app.shared.buttons.edit' | transloco" (click)="toggleReadonly()"></button>
    </ng-template>
  </div>
</form>

import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MenuItem } from 'primeng/api';

@Pipe({
  name: 'translateActions',
})
export class TranslateActionsPipe implements PipeTransform {
  constructor(private readonly translate: TranslocoService) {}

  transform(value: MenuItem[] | null | undefined): MenuItem[] {
    return (value ?? []).map((item) => ({
      ...item,
      label: item.label ? this.translate.translate(item.label) : undefined,
      title: item.title ? this.translate.translate(item.title) : undefined,
    }));
  }
}

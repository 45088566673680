import { Action, ActionReducer } from '@ngrx/store';

/**
 * @description Creates a kind of chain of responsibilities, each passed reducer overrides the result of his preceding reducer.
 * Priority is from right to left, execution from left to right.
 * @since 30/08/2020
 * @param reducers Chain of ActionReducers
 */
export function concatReducers<S extends object, A extends Action>(...reducers: ActionReducer<S, A>[]): ActionReducer<S, A> {
  if (reducers.length === 1) {
    return reducers[0];
  }

  return reducers.reduceRight(
    (previous, next) =>
      (state: S | undefined, action: A): S =>
        previous(next(state, action), action)
  );
}

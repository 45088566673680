import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withLoadingSpinner } from '@fluxys/primeng';
import { Observable } from 'rxjs';

import { VehicleDocument } from '@shared/models/vehicle-document';
import { FlxValidatedResult } from '@fluxys/gsmart/rule-validation';
import { DateTime } from 'luxon';
import { toDate } from '@fluxys/common';

@Injectable({ providedIn: 'root' })
export class DocumentsService {
  private readonly _basePath = '/api/vehiclepartdocuments';
  constructor(private readonly _http: HttpClient) {}

  getDocuments(): Observable<VehicleDocument[]> {
    return this._http.get<VehicleDocument[]>(this._basePath, { context: withLoadingSpinner('vehicle-part-documents') });
  }

  openVehicleDocument(id: number): Observable<Blob> {
    return this._http.get(`${this._basePath}/open-document/${id}`, { responseType: 'blob' });
  }

  validateVehicleDocuments(document: VehicleDocument, validationContext?: string): Observable<FlxValidatedResult> {
    const formData = new FormData();
    if (document.document) {
      formData.append(`model[0].document`, document.document, document.document.name);
    }
    formData.append(`model[0].id`, document.id.toString());
    formData.append(`model[0].vehiclePartId`, document.vehiclePartId ? document.vehiclePartId.toString() : '');
    formData.append(`model[0].documentType`, document.documentType.toString());
    formData.append(`model[0].validFrom`, document.validFrom ? DateTime.fromJSDate(toDate(document.validFrom)).toFormat('yyyyMMdd') : '');
    formData.append(`model[0].validTo`, document.validTo ? DateTime.fromJSDate(toDate(document.validTo)).toFormat('yyyyMMdd') : '');
    formData.append(`model[0].validated`, (document.validated ?? false).toString());

    return this._http.post(`/api/vehiclepartdocuments/savedocuments`, formData, { context: withLoadingSpinner(validationContext) });
  }

  exportVehicleDocuments(): Observable<HttpResponse<Blob>> {
    return this._http.get(`${this._basePath}/export`, { responseType: 'blob', observe: 'response' });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { skipDefaultErrorHandling } from '@fluxys/common';
import { FlxValidatedResult } from '@fluxys/gsmart/rule-validation';
import { withLoadingSpinner } from '@fluxys/primeng';
import { Observable } from 'rxjs';

import { AutoConfirmationServicesModule } from '../autoconfirmation.service.module';
import {
  AcrDialogViewModel,
  AcrOverviewModel,
  AutoConfirmationRuleConfigureCommandViewModel,
  AutoConfirmationRuleRegisterCommandViewModel,
} from '../models/acr.models';

@Injectable({ providedIn: AutoConfirmationServicesModule })
export class AcrService {
  private readonly basePath = '/api/autoconfirmation-overview';

  constructor(private readonly _http: HttpClient) {}

  find(): Observable<AcrOverviewModel> {
    const context = withLoadingSpinner('acr-table');
    return this._http.get<AcrOverviewModel>(`${this.basePath}/acr-data`, { context });
  }

  dialog(): Observable<AcrDialogViewModel> {
    const context = withLoadingSpinner('load-dialog-data');
    return this._http.get<AcrDialogViewModel>(`${this.basePath}/dialog`, { context });
  }

  register(command: AutoConfirmationRuleRegisterCommandViewModel, validationContext: string): Observable<FlxValidatedResult> {
    const context = withLoadingSpinner(validationContext, skipDefaultErrorHandling());
    return this._http.post<FlxValidatedResult>(`${this.basePath}/register`, command, { context });
  }

  configure(command: AutoConfirmationRuleConfigureCommandViewModel, validationContext: string): Observable<FlxValidatedResult> {
    const context = withLoadingSpinner(validationContext, skipDefaultErrorHandling());
    return this._http.post<FlxValidatedResult>(`${this.basePath}/configure`, command, { context });
  }
}

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AssignmentsRoutes } from '../../assignments-routes';
import { AssignmentViewModel } from '../../models/assignment-request-overview/assignment.viewmodel';

@Component({
  selector: 'trkmgr-assignments-table',
  templateUrl: './assignments-table.component.html',
  styleUrls: ['./assignments-table.component.scss'],
})
export class AssignmentsTableComponent {
  @Input() assignments: AssignmentViewModel[] | null = [];
  columns = [
    { translation: 'headers.reference', field: 'reference', filter: true },
    { translation: 'headers.type', field: 'type', filter: true },
    { translation: 'headers.assignment-type', field: 'assignmentType', filter: true },
    { translation: 'headers.assignor', field: 'assignorName', filter: true },
    { translation: 'headers.assignee', field: 'assigneeName', filter: true },
    { translation: 'headers.operational-period', field: 'operationalPeriod', filter: false },
    { translation: 'headers.date-time', field: 'appointmentDatetime', filter: true },
    { translation: 'headers.quantity', field: 'quantity', filter: true },
    { translation: 'headers.status', field: 'status', filter: true },
    { translation: 'headers.signature-date', field: 'signatureDate', filter: true },
  ];

  constructor(private readonly router: Router) {}

  onViewDetailsLinkClicked(assignmentRequestId: string): void {
    void this.router.navigate([`${AssignmentsRoutes.assignmentDetailsRoute}/${assignmentRequestId}`]);
  }
}

<flx-rule-validation [context]="context" displayMode="dialog"></flx-rule-validation>

<section>
  <trkmgr-driver-details-form
    [form]="form"
    mode="register"
    [context]="context"
    [acceptedLanguages]="acceptedLanguages$ | async"
  ></trkmgr-driver-details-form>
</section>
<div class="section-footer section-footer-in-content">
  <div class="section-footer__commands">
    <button
      pButton
      type="button"
      class="p-button-secondary"
      (click)="closeDialog()"
      [label]="'app.shared.buttons.cancel' | transloco"
    ></button>
    <button
      pButton
      type="button"
      class="p-button-primary"
      (click)="onCreateButtonClicked()"
      [disabled]="!form.valid"
      [label]="'app.shared.buttons.save' | transloco"
    ></button>
  </div>
</div>

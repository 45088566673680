<div class="card card-no-padding">
  <div class="d-flex flex-wrap align-items-center justify-content-between flex-grow-1 m-3">
    <div id="filters" class="d-flex">
      <ng-container *ngIf="inlineFilter">
        <div class="d-flex align-items-center mr-5">
          <label [for]="inlineFilter.field" class="pr-1">{{ inlineFilter.label | transloco }}</label>

          <div
            *ngFor="let option of inlineFilter.options"
            class="inline-filter"
            [class.inline-filter--selected]="inlineFiltersIsSelected(option)"
            (click)="dataTable!.filter(option.value, inlineFilter.field, 'contains')"
          >
            {{ option.label | transloco }}
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="dropdownFilters">
        <div *ngFor="let dropdownFilter of dropdownFilters; let i = index" class="mr-5">
          <label class="pr-1" [for]="'dd_filter_' + i">{{ dropdownFilter!.label | transloco }}</label>

          <p-multiSelect
            #pms
            [inputId]="'dd_filter_' + i"
            [options]="dropdownFilter!.options!"
            [ngModel]="getDropdownModel(dropdownFilter!.field)"
            [defaultLabel]="dropdownFilter!.defaultLabel || pms.defaultLabel | transloco"
            [selectedItemsLabel]="
              dropdownFilter!.selectedItemsLabel ||
                'app.shared.components.overview-table.multiple-items-selected' | transloco
            "
            (onChange)="dataTable!.filter($event.value, dropdownFilter!.field, 'in')"
          ></p-multiSelect>
        </div>
      </ng-container>
    </div>

    <div class="d-flex">
      <div id="global_search" class="d-flex">
        <button
          pButton
          *ngIf="canResetFilters"
          class="mr-2 p-button-secondary"
          (click)="clearFilters()"
          transloco="app.modules.vehicles.pages.overview.filter.clear"
        ></button>

        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>

          <input
            pInputText
            type="text"
            [placeholder]="'app.modules.vehicles.pages.overview.filter.title' | transloco"
            [title]="'app.modules.vehicles.pages.overview.filter.tooltip' | transloco"
            [(ngModel)]="searchTextFilter"
            (input)="dataTable!.filterGlobal(searchTextFilter, 'contains')"
          />
        </span>
      </div>

      <div id="commands" class="d-flex">
        <div class="ml-3" *ngIf="primaryCommand">
          <flx-commands-button [primaryCommand]="primaryCommand" [commands]="secondaryCommands!"></flx-commands-button>
        </div>

        <div class="ml-3" *ngIf="exportButton">
          <flx-commands-button [primaryCommand]="defaultExportCommand"></flx-commands-button>
        </div>
      </div>
    </div>
  </div>

  <ng-content></ng-content>
</div>

import { createAction, props } from '@ngrx/store';

import { HttpError } from '../../models/http-error';
import { UserInfoViewModel } from '../../models/user-info.viewmodel';

const LOAD_USER_PERMISSIONS = createAction('[Core - User] Load Permissions');
const USER_PERMISSIONS_LOADED = createAction('[Core - User] Permissions Loaded', props<UserInfoViewModel>());
const USER_PERMISSIONS_FAILED = createAction('[Core - User] Permissions Failed', props<Partial<HttpError>>());

export {
  LOAD_USER_PERMISSIONS as loadUserPermissions,
  USER_PERMISSIONS_LOADED as userPermissionsLoaded,
  USER_PERMISSIONS_FAILED as userPermissionsFailed,
};

import { NgModule, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import {
  TM_HAS_PERMISSION_CAN_ACTIVATE_CHILD_FN,
  TM_HAS_PERMISSION_CAN_ACTIVATE_FN,
} from 'src/app/guards/truckmanager-authorization.guard';

import { AppStartupRouteGuard } from './guards/app-startup-route-guard';
import { ImpersonationComponent } from './impersonation/impersonation.component';

const ROUTES: Routes = [
  { path: '', redirectTo: 'startup', pathMatch: 'full' },
  { path: 'forbidden', redirectTo: 'startup' },
  { path: 'auth-callback', redirectTo: '' },
  { path: 'startup', canActivate: [AutoLoginPartialRoutesGuard, AppStartupRouteGuard], children: [] },

  /*  { path: '**', redirectTo: 'calendar' }, */

  { path: 'impersonate', component: ImpersonationComponent, canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN, AutoLoginPartialRoutesGuard] },
  {
    path: 'appointments',
    loadChildren: () => import('./appointments/appointments.module').then((m) => m.AppointmentsModule),
    canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN, AutoLoginPartialRoutesGuard],
    canActivateChild: [TM_HAS_PERMISSION_CAN_ACTIVATE_CHILD_FN, AutoLoginPartialRoutesGuard],
  },
  {
    path: 'calendar',
    loadChildren: () => import('./calendar/calendar.module').then((m) => m.CalendarModule),
    canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN, AutoLoginPartialRoutesGuard],
    canActivateChild: [TM_HAS_PERMISSION_CAN_ACTIVATE_CHILD_FN, AutoLoginPartialRoutesGuard],
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
    canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN, AutoLoginPartialRoutesGuard],
    canActivateChild: [TM_HAS_PERMISSION_CAN_ACTIVATE_CHILD_FN, AutoLoginPartialRoutesGuard],
  },
  {
    path: 'vehicles',
    loadChildren: () => import('./vehicles/vehicles.module').then((m) => m.VehiclesModule),
    canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN, AutoLoginPartialRoutesGuard],
    canActivateChild: [TM_HAS_PERMISSION_CAN_ACTIVATE_CHILD_FN, AutoLoginPartialRoutesGuard],
  },
  {
    path: 'documents',
    loadChildren: () => import('./vehicle-documents/documents.module').then((m) => m.DocumentsModule),
    canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN, AutoLoginPartialRoutesGuard],
    canActivateChild: [TM_HAS_PERMISSION_CAN_ACTIVATE_CHILD_FN, AutoLoginPartialRoutesGuard],
  },
  {
    path: 'drivers',
    loadChildren: () => import('./drivers/drivers.module').then((m) => m.DriversModule),
    canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN, AutoLoginPartialRoutesGuard],
    canActivateChild: [TM_HAS_PERMISSION_CAN_ACTIVATE_CHILD_FN, AutoLoginPartialRoutesGuard],
  },
  {
    path: 'autoconfirmation',
    loadChildren: () => import('./autoconfirmation/autoconfirmation.module').then((m) => m.AutoConfirmationModule),
    canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN, AutoLoginPartialRoutesGuard],
    canActivateChild: [TM_HAS_PERMISSION_CAN_ACTIVATE_CHILD_FN, AutoLoginPartialRoutesGuard],
  },
  {
    path: 'assignments',
    loadChildren: () => import('./assignments/assignments.module').then((m) => m.AssignmentsModule),
    canActivate: [TM_HAS_PERMISSION_CAN_ACTIVATE_FN, AutoLoginPartialRoutesGuard],
    canActivateChild: [TM_HAS_PERMISSION_CAN_ACTIVATE_CHILD_FN, AutoLoginPartialRoutesGuard],
  },

  { path: 'error', loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {
  router: Router = inject(Router);
}

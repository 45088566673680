import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlxCommandsButtonModule } from '@fluxys/gsmart/commands-button';
import { FlxFormFieldWrapperModule } from '@fluxys/gsmart/form-field-wrapper';
import { FlxValidationModule } from '@fluxys/gsmart/validation';
import { FlxDateTimePickerComponent, FlxLoadingSpinnerComponent, FlxPrimengModule } from '@fluxys/primeng';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';

import { TableRowMenuComponent } from './components';
import { OverviewTableComponent } from './components/overview-table';
import { RealtimeValidationMessagesComponent } from './components/realtime-validation-messages/realtime-validation-messages.component';
import { LengthOrZeroPipe } from './pipes/array-length-or-zero.pipe';
import { EnumToSelectItemsPipe } from './pipes/enum-to-select-items.pipe';
import { EnumValueToTranslatedItemPipe } from './pipes/enum-value-to-translated-item.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { OrderByLabelPipe } from './pipes/order-by-label.pipe';
import { RuleValidationTranslateExtendsPipe } from './pipes/rule-validation-translate-extends.pipe';
import { ToDatePipe } from './pipes/to-date.pipe';
import { ToDatesPipe } from './pipes/to-dates.pipe';
import { ToLabelPipe } from './pipes/to-label.pipe';
import { TranslateActionsPipe } from './pipes/translate-actions.pipe';
import { TranslateArrayPipe } from './pipes/translate-array.pipe';
import { TranslateItemsPipe } from './pipes/translate-items.pipe';
import { WithEmptyItemPipe } from './pipes/with-empty-item.pipe';

const NG_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, OverlayModule, PortalModule, ObserversModule, TranslocoModule];
const PRIME_NG_MODULES = [
  ButtonModule,
  DialogModule,
  DropdownModule,
  InputTextModule,
  MenuModule,
  MultiSelectModule,
  PanelModule,
  TableModule,
];
const FLX_MODULES = [FlxValidationModule, FlxCommandsButtonModule, FlxPrimengModule, FlxFormFieldWrapperModule];

const FLX_COMPONENTS = [FlxDateTimePickerComponent, FlxLoadingSpinnerComponent];

@NgModule({
  declarations: [
    EnumToSelectItemsPipe,
    EnumValueToTranslatedItemPipe,
    FormatDatePipe,
    LengthOrZeroPipe,
    OrderByLabelPipe,
    OverviewTableComponent,
    RealtimeValidationMessagesComponent,
    RuleValidationTranslateExtendsPipe,
    TableRowMenuComponent,
    ToDatePipe,
    ToDatesPipe,
    ToLabelPipe,
    TranslateActionsPipe,
    TranslateArrayPipe,
    TranslateItemsPipe,
    WithEmptyItemPipe,
  ],
  imports: [FLX_COMPONENTS, FLX_MODULES, NG_MODULES, PRIME_NG_MODULES],
  exports: [
    DropdownModule,
    EnumToSelectItemsPipe,
    EnumValueToTranslatedItemPipe,
    FlxCommandsButtonModule,
    FlxDateTimePickerComponent,
    FlxFormFieldWrapperModule,
    FlxLoadingSpinnerComponent,
    FlxValidationModule,
    FormatDatePipe,
    LengthOrZeroPipe,
    OrderByLabelPipe,
    OverviewTableComponent,
    RealtimeValidationMessagesComponent,
    RuleValidationTranslateExtendsPipe,
    TableRowMenuComponent,
    ToDatePipe,
    ToDatesPipe,
    ToLabelPipe,
    TranslateActionsPipe,
    TranslateArrayPipe,
    TranslateItemsPipe,
    TranslocoModule,
    WithEmptyItemPipe,
  ],
})
export class SharedModule {}

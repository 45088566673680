import { inject, Pipe, PipeTransform } from '@angular/core';
import { toDate } from '@fluxys/common';
import { FlxLoggerService } from '@fluxys/core';
import { DateTimeOptions } from 'luxon';

@Pipe({ name: 'toDate' })
export class ToDatePipe implements PipeTransform {
  private readonly logger: FlxLoggerService = inject(FlxLoggerService);

  transform(value?: number | string | null, format?: string | null, options?: DateTimeOptions): Date | undefined {
    if (value === null || value === undefined) {
      return undefined;
    }

    return toDate(value);
  }
}

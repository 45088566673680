import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import { valueChanges } from 'psm5-web';
import { distinctUntilChanged, switchMap } from 'rxjs';

import {
  AgreementsPerBpViewModel,
  RegisterTruckloadingAppointmentAssignmentRequestDataViewModel,
} from '../../models/register-truckloading-appointment-assignment-request/register-truckloading-appointment-assignment-request-data.viewmodel';
import { TruckloadingAppointmentTableViewModel } from '../../models/register-truckloading-appointment-assignment-request/truckloading-appointment-table-viewmodel';
import { RegisterTruckloadingAppointmentAssignmentRequestService } from '../../services/register-truckloading-appointment-assignment-request.service';

@UntilDestroy()
@Component({
  selector: 'trkmgr-register-truckloading-appointment-assignment-request',
  templateUrl: './register-truckloading-appointment-assignment-request.component.html',
})
export class RegisterTruckloadingAppointmentAssignmentRequestComponent implements OnInit {
  @Input() model: RegisterTruckloadingAppointmentAssignmentRequestDataViewModel | null = null;
  @Input() context = '';
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});

  assigneeTargetAgreements: SelectItem<string>[] = [];
  appointments: TruckloadingAppointmentTableViewModel[] = [];
  assignmentTypes: string[] = [];

  readonly baseTranslationPath = 'app.modules.assignments.components.register-appointment-assignmentrequest';

  constructor(private readonly formService: RegisterTruckloadingAppointmentAssignmentRequestService) {}

  ngOnInit(): void {
    valueChanges<string>(this.form, 'assigneeBusinessPartyId')
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value) => {
        if (this.model !== null) {
          this.assigneeTargetAgreements = this.getBpAgreements(value, this.model.sourceAgreements);
        }
      });

    valueChanges<string>(this.form, 'slotDate')
      .pipe(
        untilDestroyed(this),
        switchMap((value) => this.formService.getAppointmentsPerDate(value, this.context))
      )
      .subscribe((response) => (this.appointments = response));
  }

  /**
   * @param bpId Business Party id for which we seek the agreements
   * @param agreements collection of agreements associated to a BusinessPartyId
   * @returns a collection of agreements associated to the given bpId
   */
  getBpAgreements(bpId: string, agreements: AgreementsPerBpViewModel[]): SelectItem<string>[] {
    const filteredAgreementsPerYear = agreements.filter((ag) => ag.businessPartyId === bpId);

    return filteredAgreementsPerYear[0]?.agreements ?? [];
  }

  /**
   * @param bpId Business Party id for which we seek the agreement
   * @param agreements collection of agreements associated to a BusinessPartyId
   * @returns the default agreement we should display in the UI (for now the first of the collection)
   */
  getBpDefaultAgreement(bpId: string, agreements: AgreementsPerBpViewModel[]): SelectItem<string> {
    const targetAgreementIds = this.getBpAgreements(bpId, agreements);

    return targetAgreementIds[0];
  }
}

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { PlatformLocation } from '@angular/common';
import { Component, inject, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FlxConfigurationService } from '@fluxys/core';
import { FlxRootConfiguration } from '@fluxys/gsmart';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { combineLatest, filter, map, mergeMap, Observable, startWith, switchMap, take, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'trkmgr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly tenantLabel$: Observable<string>;
  readonly noBreadcrumb$: Observable<boolean>;

  // document: Document = inject(Document);
  private readonly translateService: TranslocoService = inject(TranslocoService);
  private readonly title: Title = inject(Title);
  private readonly router: Router = inject(Router);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly configService: FlxConfigurationService<FlxRootConfiguration> = inject(FlxConfigurationService);
  private readonly platformLocation: PlatformLocation = inject(PlatformLocation);

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(renderer: Renderer2, private readonly service: OidcSecurityService) {
    this.tenantLabel$ = this.configService.config$.pipe(
      map((config: FlxRootConfiguration) => `app.modules.core.tenant-badge-labels.${config.gsmart.tenant}`)
    );
    this.noBreadcrumb$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      mergeMap(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route.data;
      }),
      map((data) => 'noBreadcrumb' in data && data.noBreadcrumb === true),
      untilDestroyed(this)
    );
    if (this.isLocalhost()) {
      renderer.addClass(document.body, 'env-loc');
    }
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    this.getTitle$()
      .pipe(untilDestroyed(this))
      .subscribe((title) => this.title.setTitle(title));

    // eslint-disable-next-line no-console
    this.service.isAuthenticated$.pipe(
      tap((x) => {
        // eslint-disable-next-line no-debugger
        debugger;
      })
    );
  }

  private getTitle$(): Observable<string> {
    const pageTitle$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((activatedRoute) => {
        let route = activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
      map((data) => data.title as string | null | undefined),
      filter((data): data is string => data !== null && data !== undefined),
      mergeMap((pageTitle) => this.translateService.selectTranslate<string>(pageTitle).pipe(take(1))),
      startWith(null)
    );

    const combination$ = combineLatest([
      this.translateService.langChanges$,
      pageTitle$,
      this.translateService.selectTranslate<string>('app.title').pipe(take(1)),
    ]);

    return combination$.pipe(map(([_, pageTitle, appTitle]) => (pageTitle === null ? appTitle : `${appTitle} - ${pageTitle}`)));
  }

  // ref: https://stackoverflow.com/a/57949518
  private isLocalhost(): boolean {
    const hostName = this.platformLocation.hostname;

    return (
      hostName === '127.0.0.1' ||
      hostName === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      hostName === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      !!hostName.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    );
  }
}

/* eslint-disable @angular-eslint/sort-ngmodule-metadata-arrays */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlxDatePickerModule } from '@fluxys/gsmart/date-picker';
import { FlxFormFieldWrapperModule } from '@fluxys/gsmart/form-field-wrapper';
import { FlxRuleValidationModule } from '@fluxys/gsmart/rule-validation';
import { FlxOverviewModule, FlxSimpleDetailsModule } from '@fluxys/gsmart/templates';
import { FlxValidationModule } from '@fluxys/gsmart/validation';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@shared/shared.module';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';

import { AssignmentsRoutingModule } from './assignments-routing.module';
import { AssignmentRequestDetailsAppointmentComponent } from './components/assignment-details/assignment-request-details-appointment/assignment-request-details-appointment.component';
import { AssignmentRequestDetailsGeneralComponent } from './components/assignment-details/assignment-request-details-general/assignment-request-details-general.component';
import { AssignmentRequestDetailsSlotComponent } from './components/assignment-details/assignment-request-details-slot/assignment-request-details-slot.component';
import { AssignmentRequestsTableComponent } from './components/assignment-requests-table/assignment-requests-table.component';
import { AssignmentsTableComponent } from './components/assignments-table/assignments-table.component';
import { RegisterSlotsAssignmentRequestComponent } from './components/register-slots-assignment-request/register-slots-assignment-request.component';
import { RegisterTruckloadingAppointmentAssignmentRequestComponent } from './components/register-truckloading-appointment-assignment-request/register-truckloading-appointment-assignment-request.component';
import { AssignmentDetailsComponent } from './pages/assignment-request-details/assignment-details.component';
import { AssignmentRequestTypeSelectionComponent } from './pages/assignment-request-type-selection/assignment-request-type-selection.component';
import { AssignmentsOverviewComponent } from './pages/assignments-overview/assignments-overview.component';
import { AssignmentsRegisterSlotsComponent } from './pages/assignments-register-slots/assignments-register-slots.component';
import { AssignmentsRegisterTruckloadingAppointmentComponent } from './pages/assignments-register-truckloading-appointment/assignments-register-truckloading-appointment.component';
import { ToStandardPricePipe } from './pipes/to-standard-price.pipe';

@NgModule({
  declarations: [
    AssignmentRequestsTableComponent,
    AssignmentsOverviewComponent,
    AssignmentsRegisterSlotsComponent,
    AssignmentsRegisterTruckloadingAppointmentComponent,
    AssignmentRequestTypeSelectionComponent,
    AssignmentsTableComponent,
    AssignmentDetailsComponent,
    AssignmentRequestDetailsGeneralComponent,
    AssignmentRequestDetailsAppointmentComponent,
    AssignmentRequestDetailsSlotComponent,
    RegisterSlotsAssignmentRequestComponent,
    RegisterTruckloadingAppointmentAssignmentRequestComponent,
    ToStandardPricePipe,
  ],
  imports: [
    AccordionModule,
    AssignmentsRoutingModule,
    ButtonModule,
    CardModule,
    CommonModule,
    ConfirmDialogModule,
    DropdownModule,
    FlxDatePickerModule,
    FlxOverviewModule,
    FlxSimpleDetailsModule,
    FlxFormFieldWrapperModule,
    FlxRuleValidationModule,
    FlxValidationModule,
    FormsModule,
    InputNumberModule,
    RadioButtonModule,
    ReactiveFormsModule,
    SharedModule,
    TableModule,
    TagModule,
    TranslocoModule,
  ],
  providers: [ConfirmationService],
})
export class AssignmentsModule {}

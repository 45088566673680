import { AcrViewModel } from '../models/acr.models';

export interface AcrModuleState {
  rules: AcrViewModel[];
  canCreate: boolean;
  canEdit: boolean;
  areShippersVisible: boolean;
}

export const INITIAL_ACR_MODULE_STATE: AcrModuleState = {
  rules: [],
  canCreate: false,
  canEdit: false,
  areShippersVisible: false,
};

import { Injectable } from '@angular/core';
import { TranslocoConfig, TranslocoMissingHandler } from '@ngneat/transloco';

@Injectable()
export class MissingTranslationHandlerService implements TranslocoMissingHandler {
  handle(key: string, _config: TranslocoConfig): string {
    if (key.startsWith('app.modules.calendar.shared.services.')) {
      const length = key.substr(key.lastIndexOf('.') + 1);
      switch (length) {
        case 'medium':
          return 'Unknown';
        case 'long':
          return 'Unknown or new Service (???)';
        case 'short':
          return '???';
        default:
          return key;
      }
    } else if (key.startsWith('app.modules.calendar.shared.slot-status.')) {
      return 'Unknown';
    } else if (key.startsWith('app.modules.error.messages.')) {
      const type = key.substr(key.lastIndexOf('.') + 1);
      switch (type) {
        case 'title':
          return 'Unexpected error';
        case 'message':
          return 'An unexpected error occurred. Please contact your IT support.';
        default:
          return key;
      }
    } else {
      return key;
    }
  }
}

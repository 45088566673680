/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { mapToPayload } from '@core/models/http-error';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { Observable } from 'rxjs';

import { AssignmentsRoutes } from '../../assignments-routes';
import { RegisterTruckloadingAppointmentAssignmentRequestDataViewModel } from '../../models/register-truckloading-appointment-assignment-request/register-truckloading-appointment-assignment-request-data.viewmodel';
import { RegisterTruckloadingAppointmentAssignmentRequestCommand } from '../../models/register-truckloading-appointment-assignment-request/register-truckloading-appointment-assignment-request.command';
import { RegisterTruckloadingAppointmentAssignmentRequestService } from '../../services/register-truckloading-appointment-assignment-request.service';

@Component({
  selector: 'trkmgr-assignments-register-truckloading-appointment',
  templateUrl: './assignments-register-truckloading-appointment.component.html',
})
export class AssignmentsRegisterTruckloadingAppointmentComponent {
  model$: Observable<RegisterTruckloadingAppointmentAssignmentRequestDataViewModel>;
  readonly form: UntypedFormGroup;

  readonly context = 'assignments-register-truckloading-appointment';

  constructor(
    private readonly _formService: RegisterTruckloadingAppointmentAssignmentRequestService,
    private readonly _errorHandlerService: ErrorHandlerService,
    private readonly _fb: UntypedFormBuilder,
    private readonly _router: Router
  ) {
    this.model$ = this._formService.getRegisterDataModel(this.context);

    this.form = this._fb.group({
      assignorBusinessPartyId: this._fb.control(undefined),
      assignorSourceAgreementId: this._fb.control(undefined),
      assigneeBusinessPartyId: this._fb.control(Validators.required),
      assigneeTargetAgreementId: this._fb.control(undefined),
      slotDate: this._fb.control(undefined),
      assignmentType: this._fb.control(Validators.required),
      appointmentId: this._fb.control(null, Validators.required),
    });
  }

  onCancelButtonClicked(): void {
    void this._router.navigate(['/assignments/overview']);
  }

  onAssignButtonClicked(): void {
    if (this.form.valid) {
      const { assigneeBusinessPartyId, appointmentId, assignmentType } = this.form.getRawValue();

      const command: RegisterTruckloadingAppointmentAssignmentRequestCommand = {
        assigneeBusinessPartyId,
        assignmentType,
        appointmentId,
      };

      this._formService.registerAssignmentRequest(command, this.context).subscribe({
        next: (response) => {
          if (
            ((response.errors === undefined || response.errors.length === 0) &&
              (response.warnings === undefined || response.warnings.length === 0)) ||
            response.errors?.every((err) => err.overridden === true)
          ) {
            void this._router.navigate([AssignmentsRoutes.overviewRoute]);
          }
        },
        error: (error: HttpErrorResponse) => this.handleError(error),
      });
    }
  }

  private handleError(error: HttpErrorResponse): void {
    this._errorHandlerService.handleHttpErrorResponse(
      mapToPayload(error),
      'app.modules.assignments.shared.http-errors.register-appointment-assignment'
    );
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FlxValidators } from '@fluxys/gsmart/validation';
import { DateTime } from 'luxon';
import { SelectItem, SortEvent } from 'primeng/api';
import { SimpleChanges } from 'psm5-web/types';

import { customSort } from '../../../shared/utils/custom-sort';
import { AccreditationStatus, ApprovalViewModel } from '../../models/view-models';

interface FormApproval {
  id: number;
  status: string;
  validityPeriodStartDate: string;
  validityPeriodEndDate: string;
}

@Component({
  selector: 'trkmgr-approvals-section',
  templateUrl: './approvals-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApprovalsSectionComponent implements OnChanges {
  @Input() currentApprovals: readonly ApprovalViewModel[] | null = null;
  @Input() canEdit = false;
  @Input() canAuthorizeApproval = false;
  @Input() accreditationStatuses: SelectItem<AccreditationStatus>[] = [];

  @Output() readonly changed = new EventEmitter<ApprovalViewModel>();

  readonly columns: { field: string; translation: string }[] = [
    { field: 'status', translation: 'app.modules.drivers.components.approvals-section.columns.status' },
    { field: 'validityPeriodStartDate', translation: 'app.modules.drivers.components.approvals-section.columns.valid-from' },
    { field: 'validityPeriodEndDate', translation: 'app.modules.drivers.components.approvals-section.columns.valid-to' },
  ];
  rows: UntypedFormGroup[] = [];
  approvals: ApprovalViewModel[] = [];
  editableRow = -1;

  constructor(private readonly fb: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges<ApprovalsSectionComponent>): void {
    this.createSelectItems();
    this.approvals = this.currentApprovals ? this.currentApprovals.map((entity) => ({ ...entity })) : [];
    this.createRows();
  }

  customSort(event: SortEvent): number {
    return customSort(event);
  }

  editClicked(id: number): void {
    if (this.editableRow !== -1) {
      this.cancelClicked(this.editableRow);
    }
    this.editableRow = id;
  }

  validateClicked(row: FormApproval): void {
    const rowForm = this.rows.find((r) => (r.value as FormApproval).id === row.id);
    if (rowForm?.valid) {
      this.editableRow = -1;

      const approval = this.approvals.find((a) => a.id === row.id);
      if (approval) {
        approval.status = row.status;
        approval.validityPeriodStartDate = row.validityPeriodStartDate;
        approval.validityPeriodEndDate = row.validityPeriodEndDate;

        this.changed.emit(approval);
      }
    }
  }

  cancelClicked(id: number): void {
    this.editableRow = -1;
    this.createRows();
  }

  addEntity(): void {
    const newAppoval: ApprovalViewModel = {
      id: 0,
      tcn: 0,
      status: this.accreditationStatuses[0].label ?? 'NONE',
      validityPeriodStartDate: DateTime.fromJSDate(new Date()).toFormat('yyyyMMdd'),
      validityPeriodEndDate: DateTime.fromJSDate(new Date()).toFormat('yyyyMMdd'),
    };
    this.approvals.unshift(newAppoval);
    this.createRows();
    this.editableRow = 0;
  }

  private createRows(): void {
    this.rows = [];
    this.approvals.forEach((af) => {
      const row = this.fb.group(
        {
          id: this.fb.control(af.id),
          status: this.fb.control(af.status),
          validityPeriodStartDate: this.fb.control(af.validityPeriodStartDate, Validators.required),
          validityPeriodEndDate: this.fb.control(af.validityPeriodEndDate, Validators.required),
        },
        { validators: FlxValidators.startDateBeforeEndDate('validityPeriodStartDate', 'validityPeriodEndDate') }
      );
      this.rows.push(row);
    });
  }

  private createSelectItems(): void {
    this.accreditationStatuses
      .filter(({ value }) => this.canAuthorizeApproval || value !== 'Authorized')
      .map(({ value }) => ({ value, label: value }));
  }
}

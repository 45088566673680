import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FlxPrimengModule } from '@fluxys/primeng';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';

import { SharedModule } from '../shared/shared.module';
import { DocumentsRoutingModule } from './documents-routing.module';
import { DocumentsOverviewComponent } from './components/documents-overview.component';

const PRIME_NG_MODULES = [ButtonModule, CheckboxModule, DialogModule, FileUploadModule, SplitButtonModule, TableModule];
@NgModule({
  declarations: [DocumentsOverviewComponent],
  imports: [CommonModule, FlxPrimengModule, FormsModule, PRIME_NG_MODULES, SharedModule, DocumentsRoutingModule],
})
export class DocumentsModule {}

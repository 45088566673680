import { HttpClient } from '@angular/common/http';
import { ApplicationRef, Inject, Injectable, InjectionToken, NgZone, OnDestroy } from '@angular/core';
import { FlxLoggerService } from '@fluxys/core';
import { TranslocoService } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';
import { interval, Subject, first, switchMap, take, takeUntil } from 'rxjs';
export const VERSION_TOKEN = new InjectionToken<string>('APP_VERSION');
export const HASH_TOKEN = new InjectionToken<string>('GIT_HASH');
const VERSION_URL = './assets/version.json';

interface VersionDto {
  semVer: string;
  sha: string;
}

@Injectable()
export class VersionCheckService implements OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    private readonly http: HttpClient,
    private readonly logger: FlxLoggerService,
    private readonly messageService: MessageService,
    private readonly translateService: TranslocoService,
    private readonly appRef: ApplicationRef,
    private readonly zone: NgZone,
    @Inject(VERSION_TOKEN) private readonly version: string,
    @Inject(HASH_TOKEN) private readonly hash: string
  ) {}

  initVersionCheck(frequency: number = 1000 * 60 * 30): void {
    this.appRef.isStable
      .pipe(
        first((stable) => stable),
        switchMap(() => interval(frequency)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.checkVersion());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkVersion(): void {
    this.logger.info('Start checking for new application version...');
    this.logger.debug('Current version %s and current hash %s.', this.version, this.hash);
    // include a timestamp to avoid the cache
    this.http
      .get<VersionDto>(`${VERSION_URL}?_=${Date.now()}`)
      .pipe(take(1))
      .subscribe(
        (dto) => {
          if (this.version !== dto.semVer || this.hash !== dto.sha) {
            this.logger.info('A new version of the application is available.', dto.semVer, dto.sha);
            this.zone.run(() => {
              this.messageService.clear('nv');
              this.messageService.add({
                severity: 'info',
                summary: this.translateService.translate('app.modules.core.new-version.title'),
                detail: this.translateService.translate('app.modules.core.new-version.message', dto),
                id: 'new-version-available',
                key: 'nv',
                sticky: true,
                closable: false,
              });
            });
          } else {
            this.logger.info('No new version of the application is available.');
          }
        },
        (err) => this.logger.error('Unable to fetch the version.json file.', err)
      );
  }
}

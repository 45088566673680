import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { UserInfoViewModel } from '@core/models/user-info.viewmodel';
import { UserService } from '@core/services/user.service';
import { FlxLoggerService } from '@fluxys/core';
import { FlxMessageService } from '@fluxys/primeng';
import { HAS_PERMISSION_CAN_ACTIVATE_CHILD_FN, HAS_PERMISSION_CAN_ACTIVATE_FN } from '@fluxys/security';
import { TranslocoService } from '@ngneat/transloco';
import { ImpersonationService } from 'psm5-web';
import { combineLatest, iif, map, Observable, switchMap, take, tap } from 'rxjs';

function translateTitle(title: string, translateService: TranslocoService): Observable<string> {
  return translateService.selectTranslate<string>(title || 'app.modules.core.errors.access-denied.default-page-name').pipe(take(1));
}

function isAuthorized(
  title: string,
  permissions: string[] | null | undefined,
  translateService: TranslocoService,
  logger: FlxLoggerService,
  messageService: FlxMessageService,
  userInfo$: Observable<UserInfoViewModel>
): Observable<boolean> {
  return iif(
    () => permissions === null || permissions === undefined || permissions.length === 0,
    translateTitle(title, translateService).pipe(
      tap((pageTitle) => logger.info(`AuthorizeGuardService: access granted to ${pageTitle} page, no permission on route`)),
      map(() => true)
    ),
    userInfo$.pipe(
      switchMap(() => combineLatest([userInfo$, translateTitle(title, translateService)])),
      map(([userInfo, pageTitle]) => {
        if (userInfo.permissions.length === 0) {
          logger.error(`AuthorizeGuardService: access denied to ${pageTitle} page, no effective permissions available to user`);
          messageService.showErrorToast(
            {
              key: 'app.modules.core.errors.access-denied.no-effective-permission',
              fallbackMessage: `Access denied to ${pageTitle} page, no effective permissions available to user`,
              data: { pageTitle },
            },
            'app.modules.core.errors.access-denied.title'
          );
          return false;
        }

        const result = permissions?.map((op) => userInfo.permissions.includes(op)).includes(true) ?? false;
        if (result) {
          logger.info(`AuthorizeGuardService: access granted to ${pageTitle} page`);
        } else {
          logger.error(`AuthorizeGuardService: access denied to ${pageTitle} page`);
          messageService.showErrorToast(
            {
              key: 'app.modules.core.errors.access-denied.message',
              fallbackMessage: `Access denied to ${pageTitle} page`,
              isTranslated: false,
              data: {
                pageTitle,
              },
            },
            'app.modules.core.errors.access-denied.title'
          );
        }

        return result;
      })
    )
  );
}

export const TM_HAS_PERMISSION_CAN_ACTIVATE_FN: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const impersonationService = inject(ImpersonationService);
  const logger = inject(FlxLoggerService);
  const messageService = inject(FlxMessageService);
  const translateService = inject(TranslocoService);
  const userService = inject(UserService);

  if (impersonationService.impersonating) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return isAuthorized(
      route.data.title as string,
      route.data.permissions as string[] | null | undefined,
      translateService,
      logger,
      messageService,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      userService.loadUserInfo()
    );
  }

  return HAS_PERMISSION_CAN_ACTIVATE_FN(route, state);
};

export const TM_HAS_PERMISSION_CAN_ACTIVATE_CHILD_FN: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const impersonationService = inject(ImpersonationService);
  const logger = inject(FlxLoggerService);
  const messageService = inject(FlxMessageService);
  const translateService = inject(TranslocoService);
  const userService = inject(UserService);

  if (impersonationService.impersonating) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return isAuthorized(
      route.data.title as string,
      route.data.permissions as string[] | null | undefined,
      translateService,
      logger,
      messageService,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      userService.loadUserInfo()
    );
  }

  return HAS_PERMISSION_CAN_ACTIVATE_CHILD_FN(route, state);
};

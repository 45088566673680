import { createAction, props } from '@ngrx/store';

import { HttpError } from '../../core/models/http-error';
import { AcrOverviewModel } from '../models/acr.models';

const ACTIONS = {
  initializeModule: createAction('[ACR OVERVIEW] Initialize acr module'),
  acr: {
    overview: {
      dataload: createAction('[ACR OVERVIEW] Load acr overview'),
      loaded: createAction('[ACR OVERVIEW] data loaded overview', props<AcrOverviewModel>()),
      loadError: createAction('[ACR OVERVIEW] Load acr data overview failed', props<HttpError>()),
      openCreateDialog: createAction('[ACR OVERVIEW] Open create dialog'),
      registered: createAction('[ACR OVERVIEW] new acr registered'),
      configured: createAction('[ACR OVERVIEW]  acr configured'),
    },
  },
};

export { ACTIONS as actions };

import { TranslocoService } from '@ngneat/transloco';
import { SelectItem } from 'primeng/api';

/**
 * Converts any collection of strings to a collection of select items.
 *
 * @param items source strings.
 * @returns collection of unique select items.
 */
export function toUniqueSelectItems<T extends string>(items: T[]): SelectItem<T>[];
/**
 * Converts any collection of items to a collection of select items using a selector function to select the items.
 *
 * @param items source collection.
 * @param selector label & value selector.
 * @returns collection of unique select items.
 */
export function toUniqueSelectItems<T extends object, S extends string>(items: T[], selector: (item: T) => S): SelectItem<S>[];

export function toUniqueSelectItems<T extends S | object, S extends string>(items: T[], selector?: (item: T) => S): SelectItem<S>[] {
  const _selector = selector ?? ((item) => item as unknown as S);
  return [...new Set(items.map(_selector))].map((label) => ({ label, value: label }));
}

export function addNoValueOption(selectItems: SelectItem[], transloco: TranslocoService, novalueValue: any): SelectItem[] {
  const label = transloco.translate('generic.novalue');
  const novalueItem: SelectItem = {
    label,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    value: novalueValue,
  };
  selectItems.unshift(novalueItem);
  return selectItems;
}

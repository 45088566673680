<flx-rule-validation context="save-acr" displayMode="dialog"></flx-rule-validation>
<flx-rule-validation context="configure-acr" displayMode="dialog"></flx-rule-validation>

<trkmgr-overview-table [commands]="commands" [exportButton]="false">
  <p-table
    #dt
    [columns]="tableColumns"
    [value]="rules!"
    [rows]="25"
    [paginator]="true"
    sortMode="multiple"
    [multiSortMeta]="sortMeta"
    [globalFilterFields]="['searchPattern']"
    [reorderableColumns]="false"
    dataKey="id"
    editMode="row"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th pSortableColumn="confirmationGrantingShipperName" *ngIf="areShippersVisible">
          {{ 'app.modules.autoconfirmation.components.table.grantingshippername' | transloco }}
          <p-sortIcon field="confirmationGrantingShipperName"></p-sortIcon>
        </th>

        <th pSortableColumn="confirmationReceivingShipperName">
          {{ 'app.modules.autoconfirmation.components.table.receivingshippername' | transloco }}
          <p-sortIcon field="confirmationReceivingShipperName"></p-sortIcon>
        </th>

        <th pSortableColumn="validityPeriodBegin">
          {{ 'app.modules.autoconfirmation.components.table.startdate' | transloco }}
          <p-sortIcon field="validityPeriodBegin"></p-sortIcon>
        </th>

        <th pSortableColumn="validityPeriodEnd">
          {{ 'app.modules.autoconfirmation.components.table.enddate' | transloco }}
          <p-sortIcon field="validityPeriodEnd"></p-sortIcon>
        </th>

        <th *ngIf="canEdit" class="last-col"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rule let-editing="editing">
      <tr [pEditableRow]="rule">
        <ng-container *ngFor="let column of tableColumns">
          <ng-container [ngSwitch]="column.header">
            <ng-container *ngSwitchCase="'confirmationGrantingShipperName'">
              <td *ngIf="areShippersVisible">{{ rule.confirmationGrantingShipperName }}</td>
            </ng-container>
            <td *ngSwitchCase="'confirmationReceivingShipperName'">{{ rule.confirmationReceivingShipperName }}</td>
            <td *ngSwitchCase="'validityPeriodBegin'">{{ rule.validityPeriodBegin | date: 'dd.MM.yyyy' }}</td>
            <td *ngSwitchCase="'validityPeriodEnd'">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <flx-date-picker
                    [(ngModel)]="rule.validityPeriodEnd"
                    (ngModelChange)="updatedEndDate = true"
                    [minDate]="rule.validityPeriodBegin | toDate"
                    [showEot]="true"
                    [useEotCheckBox]="true"
                  ></flx-date-picker>
                </ng-template>
                <ng-template pTemplate="output">{{ rule.validityPeriodEnd | date: 'dd.MM.yyyy' }}</ng-template>
              </p-cellEditor>
            </td>
          </ng-container>
        </ng-container>

        <td *ngIf="canEdit" class="last-col text-center">
          <ng-container *ngIf="editing; else editBtn">
            <button pButton (click)="onConfirmEdit(rule)" pSaveEditableRow class="p-button-tertiary" [disabled]="!updatedEndDate">
              <i class="fas fa-check text-success"></i>
            </button>
            <button pButton (click)="onCancelEdit(rule)" pCancelEditableRow class="p-button-tertiary">
              <i class="fas fa-times text-danger"></i>
            </button>
          </ng-container>
          <ng-template #editBtn>
            <button *ngIf="!editing" pButton pInitEditableRow class="p-button-tertiary">
              <i class="fas fa-pencil-alt"></i>
            </button>
          </ng-template>
        </td>
      </tr>
    </ng-template>
  </p-table>
</trkmgr-overview-table>

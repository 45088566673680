<flx-rule-validation [context]="context" displayMode="dialog"></flx-rule-validation>

<div
  class="container"
  *transloco="let t; read: 'app.modules.assignments.components.register-appointment-assignmentrequest'"
  style="z-index: 1; position: relative"
>
  <div class="row justify-content-between">
    <div class="col">
      <h1 class="flx-header--title">{{ t('title') }}</h1>
    </div>
    <div class="col-auto">
      <button pButton [label]="t('buttons.cancel')" (click)="onCancelButtonClicked()" class="p-button-tertiary"></button>
      <button pButton [label]="t('buttons.assign')" icon="pi pi-plus" (click)="onAssignButtonClicked()" class="ml-3"></button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <trkmgr-register-truckloading-appointment-assignment-request [model]="model$ | async" [form]="form" [context]="context">
      </trkmgr-register-truckloading-appointment-assignment-request>
    </div>
  </div>
</div>

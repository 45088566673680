import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RouterStateUrl } from './router/router-state-url';
import { UserState } from './user/user.state';

const ROUTER_SELECTOR = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

const SELECTORS = {
  router: {
    navigationId: createSelector(ROUTER_SELECTOR, (state) => state.navigationId),
    state: createSelector(ROUTER_SELECTOR, (state) => state.state),
  },
  user: createFeatureSelector<UserState>('user'),
};
export { SELECTORS as selectors };
